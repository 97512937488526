// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { AnchorRow } from '../typings';

const data = [
  {
    "id": "350771-frontChest-frontLeft",
    "productId": "350771",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350771-frontChest-frontRight",
    "productId": "350771",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350771-upperFrontLeftChest-frontLeft",
    "productId": "350771",
    "placementId": "upperFrontLeftChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.sewMin.x + in2px([0, 6.39, 6.42, 6.516, 6.66, 6.865, 6.933, 7.013][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350771-frontMiddleLeft-frontLeft",
    "productId": "350771",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 6.58, 6.66, 6.85, 7, 7.25, 7.5, 7.72][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350771-frontMiddleRight-frontRight",
    "productId": "350771",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 6.58, 6.66, 6.85, 7, 7.25, 7.5, 7.72][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350771-jerseyRunbird-frontRight",
    "productId": "350771",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 5.5, 5.5, 5.5, 5.6, 5.8, 5.86, 5.96][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.5)",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350771-upperBack-back",
    "productId": "350771",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.08, -5.43, -5.73, -6.08, -6.48, -6.78, -7.03][sizeIndex]-0.586)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350771-middleBack-back",
    "productId": "350771",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.42, 1.07, 0.77, 0.43, 0.02, -0.28, -0.53][sizeIndex]-0.586)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350771-lowerBack-back",
    "productId": "350771",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex]-0.31)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350771-leftSleeve-sleeveLeft",
    "productId": "350771",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350771-rightSleeve-sleeveRight",
    "productId": "350771",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350771-sizeTag-frontLeft",
    "productId": "350771",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350772-frontChest-frontLeft",
    "productId": "350772",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350772-frontChest-frontRight",
    "productId": "350772",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350772-upperFrontLeftChest-frontLeft",
    "productId": "350772",
    "placementId": "upperFrontLeftChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 4.87, 5.11, 5.45, 5.69, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350772-frontMiddleLeft-frontLeft",
    "productId": "350772",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 5.54, 5.54, 5.67, 5.81, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350772-frontMiddleRight-frontRight",
    "productId": "350772",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 5.54, 5.54, 5.67, 5.81, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350772-jerseyRunbird-frontRight",
    "productId": "350772",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 4.7, 5.18, 5.3, 5.5, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.5)",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350772-upperBack-back",
    "productId": "350772",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.15, 3.7-8.14, 3.36-8.16, 2.95-8.18, 0, 0][sizeIndex]-0.674+0.86-0.25)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.9, 9.44, 10.21, 10.94, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350772-middleBack-back",
    "productId": "350772",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.85, 3.7-1.84, 3.36-1.86, 2.95-1.88, 0, 0][sizeIndex]-1.814+0.86-0.25)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350772-lowerBack-back",
    "productId": "350772",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.245, 3.7+3.27, 3.36+3.235, 2.95+3.215, 0, 0][sizeIndex]-1.745+0.86-0.25)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350772-leftSleeve-sleeveLeft",
    "productId": "350772",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350772-rightSleeve-sleeveRight",
    "productId": "350772",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350772-sizeTag-frontLeft",
    "productId": "350772",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350773-frontChest-front",
    "productId": "350773",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.79, 1.476, 1.26, 1.027, 0.84, 0.534, 0.226][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350773-upperFrontLeftChest-front",
    "productId": "350773",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 5.04, 5.2, 5.25, 5.32, 5.44, 5.53, 5.74][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.569, -2.883, -3.099, -3.323, -3.51, -3.816, -4.133][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350773-frontMiddleLeft-front",
    "productId": "350773",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 5.46, 5.46, 5.63, 5.84, 6.4, 6.4, 6.51][sizeIndex])",
      "offsetY": "in2px([0, 9.29, 8.976, 8.76, 8.527, 8.34, 8.034, 7.726][sizeIndex]-1)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350773-frontMiddleRight-front",
    "productId": "350773",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -5.46, -5.46, -5.63, -5.84, -6.4, -6.4, -6.51][sizeIndex])",
      "offsetY": "in2px([0, 9.29, 8.976, 8.76, 8.527, 8.34, 8.034, 7.726][sizeIndex]-1)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350773-jerseyRunbird-front",
    "productId": "350773",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -5.2, -5.2, -5.31, -5.39, -5.5, -5.71, -6.07][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(1.92)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350773-upperBack-back",
    "productId": "350773",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12, -5.43, -5.623, -5.866, -6.034, -6.448, -6.609][sizeIndex]-1.846)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350773-middleBack-back",
    "productId": "350773",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12, -5.43, -5.623, -5.866, -6.034, -6.448, -6.609][sizeIndex]-1.846) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350773-lowerBack-back",
    "productId": "350773",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12, -5.43, -5.623, -5.866, -6.034, -6.448, -6.609][sizeIndex]-1.846) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350773-leftSleeve-sleeveLeft",
    "productId": "350773",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350773-rightSleeve-sleeveRight",
    "productId": "350773",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350773-sizeTag-front",
    "productId": "350773",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350774-frontChest-front",
    "productId": "350774",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 1.785, 1.72, 1.587, 1.423, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350774-upperFrontLeftChest-front",
    "productId": "350774",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.89, 4.03, 4.16, 4.44, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -1.822, -1.88, -2.02, -2.18][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350774-frontMiddleLeft-front",
    "productId": "350774",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.5, 4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 8.01, 7.945, 7.812, 7.648, 0, 0][sizeIndex]-0.725)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350774-frontMiddleRight-front",
    "productId": "350774",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.5, -4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 8.01, 7.945, 7.812, 7.648, 0, 0][sizeIndex]-0.725)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350774-jerseyRunbird-front",
    "productId": "350774",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.72, -3.87, -4.05, -4.27, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(1.965)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350774-upperBack-back",
    "productId": "350774",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.727+0.31, -4.731, -4.736, -4.733, 0, 0][sizeIndex]-0.309)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.9, 9.44, 10.21, 10.94, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350774-middleBack-back",
    "productId": "350774",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.727+0.31, -4.731, -4.736, -4.733, 0, 0][sizeIndex]-0.309) + in2px(5)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350774-lowerBack-back",
    "productId": "350774",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.727+0.31, -4.731, -4.736, -4.733, 0, 0][sizeIndex]-0.309) + in2px(10)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350774-leftSleeve-sleeveLeft",
    "productId": "350774",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350774-rightSleeve-sleeveRight",
    "productId": "350774",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350774-sizeTag-front",
    "productId": "350774",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350775-frontChest-front",
    "productId": "350775",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350775-upperFrontLeftChest-front",
    "productId": "350775",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 5.08, 5.08, 5.08, 5.12, 5.26, 5.4, 5.48][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350775-frontMiddleLeft-front",
    "productId": "350775",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 5.19, 5.32, 5.6, 6.02, 6.3, 6.85, 7][sizeIndex])",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350775-frontMiddleRight-front",
    "productId": "350775",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -5.19, -5.32, -5.6, -6.02, -6.3, -6.85, -7][sizeIndex])",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350775-jerseyRunbird-front",
    "productId": "350775",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -4.9, -5.01, -5.14, -5.32, -5.48, -5.62, -5.79][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350775-upperBack-back",
    "productId": "350775",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.08, -5.43, -5.73, -6.08, -6.48, -6.78, -7.03][sizeIndex]-0.586) + in2px(-2.268)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350775-middleBack-back",
    "productId": "350775",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.42, 1.07, 0.77, 0.43, 0.02, -0.28, -0.53][sizeIndex]-0.586) + in2px(-2.268)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350775-lowerBack-back",
    "productId": "350775",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex]-0.31) + in2px(-2.268)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350775-leftSleeve-sleeveLeft",
    "productId": "350775",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350775-rightSleeve-sleeveRight",
    "productId": "350775",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350775-sizeTag-front",
    "productId": "350775",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350776-frontChest-front",
    "productId": "350776",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350776-upperFrontLeftChest-front",
    "productId": "350776",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 4.26, 4.4, 4.54, 4.76, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350776-frontMiddleLeft-front",
    "productId": "350776",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.36, 4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281-0.6)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350776-frontMiddleRight-front",
    "productId": "350776",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.36, -4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281-0.6)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350776-jerseyRunbird-front",
    "productId": "350776",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -0, -3.45, -3.64, -3.82, -4.02, 0, 0][sizeIndex]) - in2px(0.5)",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350776-upperBack-back",
    "productId": "350776",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.15, 3.7-8.14, 3.36-8.16, 2.95-8.18, 0, 0][sizeIndex]-0.674+0.86-0.25) + in2px(-1.558+1)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.9, 9.44, 10.21, 10.94, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350776-middleBack-back",
    "productId": "350776",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.85, 3.7-1.84, 3.36-1.86, 2.95-1.88, 0, 0][sizeIndex]-1.814+0.86-0.25) + in2px(-1.808+1)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350776-lowerBack-back",
    "productId": "350776",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.245, 3.7+3.27, 3.36+3.235, 2.95+3.215, 0, 0][sizeIndex]-1.745+0.86-0.25) + in2px(-1.808+1)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350776-leftSleeve-sleeveLeft",
    "productId": "350776",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350776-rightSleeve-sleeveRight",
    "productId": "350776",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350776-sizeTag-front",
    "productId": "350776",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350777-frontChest-front",
    "productId": "350777",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(-2.25)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350777-upperFrontLeftChest-front",
    "productId": "350777",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 5.08, 5.08, 5.08, 5.12, 5.26, 5.4, 5.48][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(-2.25)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350777-frontMiddleLeft-front",
    "productId": "350777",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 5.19, 5.32, 5.6, 6.02, 6.3, 6.85, 7][sizeIndex])",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-2.25)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350777-frontMiddleRight-front",
    "productId": "350777",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -5.19, -5.32, -5.6, -6.02, -6.3, -6.85, -7][sizeIndex])",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-2.25)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350777-jerseyRunbird-front",
    "productId": "350777",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -4.9, -5.01, -5.14, -5.32, -5.48, -5.62, -5.79][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(-2.25)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350777-upperBack-back",
    "productId": "350777",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.08, -5.43, -5.73, -6.08, -6.48, -6.78, -7.03][sizeIndex]-0.586) + in2px(-1.035-0.86)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350777-middleBack-back",
    "productId": "350777",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.42, 1.07, 0.77, 0.43, 0.02, -0.28, -0.53][sizeIndex]-0.586) + in2px(-1.035-0.86)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350777-lowerBack-back",
    "productId": "350777",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex]-0.31) + in2px(-1.035-0.86)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350777-leftSleeve-sleeveLeft",
    "productId": "350777",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350777-rightSleeve-sleeveRight",
    "productId": "350777",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350777-sizeTag-front",
    "productId": "350777",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350778-frontChest-front",
    "productId": "350778",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.5-1.85)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350778-upperFrontLeftChest-front",
    "productId": "350778",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 4.26, 4.4, 4.54, 4.76, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(-0.5-1.85)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350778-frontMiddleLeft-front",
    "productId": "350778",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.36, 4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.5-0.6-1.85)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350778-frontMiddleRight-front",
    "productId": "350778",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.36, -4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.5-0.6-1.85)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350778-jerseyRunbird-front",
    "productId": "350778",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -0, -3.45, -3.64, -3.82, -4.02, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350778-upperBack-back",
    "productId": "350778",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.15, 3.7-8.14, 3.36-8.16, 2.95-8.18, 0, 0][sizeIndex]-0.674+0.86-0.25) + in2px(-0.698-0.86)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.9, 9.44, 10.21, 10.94, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350778-middleBack-back",
    "productId": "350778",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.85, 3.7-1.84, 3.36-1.86, 2.95-1.88, 0, 0][sizeIndex]-1.814+0.86-0.25) + in2px(-0.948-0.86)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350778-lowerBack-back",
    "productId": "350778",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.245, 3.7+3.27, 3.36+3.235, 2.95+3.215, 0, 0][sizeIndex]-1.745+0.86-0.25) + in2px(-0.948-0.86)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350778-leftSleeve-sleeveLeft",
    "productId": "350778",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350778-rightSleeve-sleeveRight",
    "productId": "350778",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350778-sizeTag-front",
    "productId": "350778",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350785-frontChest-frontLeft",
    "productId": "350785",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(0.9+0.225)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350785-frontChest-frontRight",
    "productId": "350785",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(0.9+0.225)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350785-upperFrontLeftChest-frontLeft",
    "productId": "350785",
    "placementId": "upperFrontLeftChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.sewMin.x + in2px([0, 5.7, 5.75, 5.79, 5.94, 6.12, 6.26, 6.4][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(0.85+0.225)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350785-frontMiddleLeft-frontLeft",
    "productId": "350785",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 6.62, 6.62, 7.04, 7.18, 7.59, 7.73, 7.73][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(0.9+0.225)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350785-frontMiddleRight-frontRight",
    "productId": "350785",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 6.62, 6.62, 7.04, 7.18, 7.59, 7.73, 7.73][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(0.9+0.225)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350785-jerseyRunbird-frontRight",
    "productId": "350785",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 5.47, 5.47, 5.5, 5.66, 5.85, 6.02, 6.14][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.25)",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(0.85)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350785-upperBack-back",
    "productId": "350785",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.08, -5.43, -5.73, -6.08, -6.48, -6.78, -7.03][sizeIndex]-0.586) + in2px(0.584)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(10)"
    }
  },
  {
    "id": "350785-middleBack-back",
    "productId": "350785",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.42, 1.07, 0.77, 0.43, 0.02, -0.28, -0.53][sizeIndex]-0.586) + in2px(0.584)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350785-lowerBack-back",
    "productId": "350785",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex]-0.31) + in2px(0.584)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350785-leftSleeve-sleeveLeft",
    "productId": "350785",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350785-rightSleeve-sleeveRight",
    "productId": "350785",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350785-sizeTag-frontLeft",
    "productId": "350785",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350793-frontChest-frontLeft",
    "productId": "350793",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(1.286-1.338)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350793-frontChest-frontRight",
    "productId": "350793",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(1.286-1.338)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350793-upperFrontLeftChest-frontLeft",
    "productId": "350793",
    "placementId": "upperFrontLeftChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 4.53, 4.587, 4.897, 5.12, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(1.286-1.338)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350793-frontMiddleLeft-frontLeft",
    "productId": "350793",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 5.47, 5.61, 5.61, 5.89, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(1.286-1.338)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350793-frontMiddleRight-frontRight",
    "productId": "350793",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 5.47, 5.61, 5.61, 5.89, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(1.286-1.338)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350793-jerseyRunbird-frontRight",
    "productId": "350793",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 4.17, 4.38, 4.46, 4.77, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.25)",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(0.564)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350793-upperBack-back",
    "productId": "350793",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.15, 3.7-8.14, 3.36-8.16, 2.95-8.18, 0, 0][sizeIndex]-0.674+0.86-0.25) + in2px(0.4-0.125)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.46, 8.9, 9.65, 10.38, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350793-middleBack-back",
    "productId": "350793",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.85, 3.7-1.84, 3.36-1.86, 2.95-1.88, 0, 0][sizeIndex]-1.814+0.86-0.25) + in2px(0.4-0.125)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350793-lowerBack-back",
    "productId": "350793",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.245, 3.7+3.27, 3.36+3.235, 2.95+3.215, 0, 0][sizeIndex]-1.745+0.86-0.25) + in2px(0.4-0.125)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350793-leftSleeve-sleeveLeft",
    "productId": "350793",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350793-rightSleeve-sleeveRight",
    "productId": "350793",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350793-sizeTag-frontLeft",
    "productId": "350793",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350786-frontChest-frontLeft",
    "productId": "350786",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(1.27)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "350786-frontChest-frontRight",
    "productId": "350786",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(1.27)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "350786-upperFrontLeftChest-frontLeft",
    "productId": "350786",
    "placementId": "upperFrontLeftChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.sewMin.x + in2px([0, 5.47, 5.81, 5.95, 6.2, 6.4, 6.3, 6.42][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(1.27)"
    },
    "boundingBox": {
      "height": "in2px([0, 3.25, 3.25, 3.5, 3.5, 3.5, 3.5, 3.5][sizeIndex])",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350786-frontMiddleLeft-frontLeft",
    "productId": "350786",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 6.77, 6.84, 6.98, 7.12, 7.39, 7.53, 7.67][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(1.27)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350786-frontMiddleRight-frontRight",
    "productId": "350786",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 6.77, 6.84, 6.98, 7.12, 7.39, 7.53, 7.67][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(1.27)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350786-jerseyRunbird-frontRight",
    "productId": "350786",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 5.76, 5.81, 5.91, 6.01, 6.16, 6.34, 6.54][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.25)",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(1.27)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350786-upperBack-back",
    "productId": "350786",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.08, -5.43, -5.73, -6.08, -6.48, -6.78, -7.03][sizeIndex]-0.586) + in2px(0.5-0.871)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350786-middleBack-back",
    "productId": "350786",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.42, 1.07, 0.77, 0.43, 0.02, -0.28, -0.53][sizeIndex]-0.586) + in2px(0.5-0.871)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350786-lowerBack-back",
    "productId": "350786",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex]-0.31) + in2px(0.5-0.871)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350786-sizeTag-frontLeft",
    "productId": "350786",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350791-frontChest-frontLeft",
    "productId": "350791",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(0.392)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11)"
    }
  },
  {
    "id": "350791-frontChest-frontRight",
    "productId": "350791",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(0.392)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11)"
    }
  },
  {
    "id": "350791-upperFrontLeftChest-frontLeft",
    "productId": "350791",
    "placementId": "upperFrontLeftChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 4.938, 5.175, 5.561, 5.935, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(0.392)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350791-frontMiddleLeft-frontLeft",
    "productId": "350791",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 5.48, 5.63, 5.63, 5.77, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(0.392)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350791-frontMiddleRight-frontRight",
    "productId": "350791",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 5.48, 5.63, 5.63, 5.77, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(0.392)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350791-jerseyRunbird-frontRight",
    "productId": "350791",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 4.85, 5.0, 5.25, 5.5, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.5)",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(0.66)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350791-upperBack-back",
    "productId": "350791",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.15, 3.7-8.14, 3.36-8.16, 2.95-8.18, 0, 0][sizeIndex]-0.674+0.86-0.25) + in2px(0.5+0.192)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 9.16, 9.68, 10.41, 11.14, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350791-middleBack-back",
    "productId": "350791",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.85, 3.7-1.84, 3.36-1.86, 2.95-1.88, 0, 0][sizeIndex]-1.814+0.86-0.25) + in2px(0.5+0.192)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350791-lowerBack-back",
    "productId": "350791",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.245, 3.7+3.27, 3.36+3.235, 2.95+3.215, 0, 0][sizeIndex]-1.745+0.86-0.25) + in2px(0.5+0.192)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350791-sizeTag-frontLeft",
    "productId": "350791",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350787-frontChest-front",
    "productId": "350787",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350787-upperFrontLeftChest-front",
    "productId": "350787",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 4.93, 5, 5.14, 5.28, 5.42, 5.42, 5.7][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.588, -2.924, -3.237, -3.459, -3.671, -3.953, -4.254][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px([0, 3.25, 3.25, 3.5, 3.5, 3.5, 3.5, 3.5][sizeIndex])",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350787-frontMiddle-front",
    "productId": "350787",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350787-frontMiddleLeft-front",
    "productId": "350787",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 5.37, 5.37, 5.37, 5.51, 5.65, 5.79, 5.93][sizeIndex])",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350787-frontMiddleRight-front",
    "productId": "350787",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, -5.37, -5.37,- 5.37, -5.51, -5.65, -5.79, -5.93][sizeIndex])",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350787-jerseyRunbird-front",
    "productId": "350787",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -5.19, -5.3, -5.4, -5.47, -5.61, -5.8, -5.99][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(0.972)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350787-upperBack-back",
    "productId": "350787",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12, -5.43, -5.623, -5.866, -6.034, -6.448, -6.609][sizeIndex]-1.846) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350787-middleBack-back",
    "productId": "350787",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12, -5.43, -5.623, -5.866, -6.034, -6.448, -6.609][sizeIndex]-1.846) + in2px(6.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350787-lowerBack-back",
    "productId": "350787",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12, -5.43, -5.623, -5.866, -6.034, -6.448, -6.609][sizeIndex]-1.846) + in2px(12.72) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350787-sizeTag-front",
    "productId": "350787",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350792-frontChest-front",
    "productId": "350792",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350792-upperFrontLeftChest-front",
    "productId": "350792",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.68, 3.96, 4.19, 4.47, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -1.211, -1.255, -1.386, -1.518][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350792-frontMiddle-front",
    "productId": "350792",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(7)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350792-frontMiddleLeft-front",
    "productId": "350792",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 0, 4.3, 4.3, 4.44, 4.72, 0, 0][sizeIndex])",
      "offsetY": "in2px(7)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350792-frontMiddleRight-front",
    "productId": "350792",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 0, -4.3, -4.3, -4.44, -4.72, 0, 0][sizeIndex])",
      "offsetY": "in2px(7)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350792-jerseyRunbird-front",
    "productId": "350792",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.73, -3.88, -4.06, -4.25, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(2.45)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350792-upperBack-back",
    "productId": "350792",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -3.441, -3.445, -3.538, -3.624, 0, 0][sizeIndex]-0.416)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 9.16, 9.68, 10.41, 11.14, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350792-middleBack-back",
    "productId": "350792",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -3.441, -3.445, -3.538, -3.624, 0, 0][sizeIndex]-0.416) + in2px(5)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350792-lowerBack-back",
    "productId": "350792",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -3.441, -3.445, -3.538, -3.624, 0, 0][sizeIndex]-0.416) + in2px(10)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350792-sizeTag-front",
    "productId": "350792",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350788-frontChest-front",
    "productId": "350788",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "350788-upperFrontLeftChest-front",
    "productId": "350788",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 5.14, 5.25, 5.43, 5.43, 5.57, 5.71, 5.85][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.652, -3.118, -3.36, -3.642, -3.953, -4.435, -4.89][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350788-frontMiddleLeft-front",
    "productId": "350788",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 5.26, 5.26, 5.37, 5.44, 5.58, 5.86, 6.28][sizeIndex])",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350788-frontMiddleRight-front",
    "productId": "350788",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, -5.26, -5.26, -5.37, -5.44, -5.58, -5.86, -6.28][sizeIndex])",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350788-jerseyRunbird-front",
    "productId": "350788",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -5.23, -5.23, -5.32, -5.49, -5.74, -5.87, -6.07][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(0.903)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350788-upperBack-back",
    "productId": "350788",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12+0.14, -5.43, -5.623-0.027, -5.866, -6.034-0.2, -6.448-0.31, -6.609-0.588][sizeIndex]-0.043-0.28)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350788-middleBack-back",
    "productId": "350788",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12+0.14, -5.43, -5.623-0.027, -5.866, -6.034-0.2, -6.448-0.31, -6.609-0.588][sizeIndex]-0.043-0.28) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350788-lowerBack-back",
    "productId": "350788",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.12+0.14, -5.43, -5.623-0.027, -5.866, -6.034-0.2, -6.448-0.31, -6.609-0.588][sizeIndex]-0.043-0.28) + in2px(12.72+0.28)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350788-leftSleeve-sleeveLeft",
    "productId": "350788",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350788-rightSleeve-sleeveRight",
    "productId": "350788",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350788-sizeTag-front",
    "productId": "350788",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350794-frontChest-front",
    "productId": "350794",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350794-upperFrontLeftChest-front",
    "productId": "350794",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.81, 3.95, 4.08, 4.36, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -1.211, -1.255, -1.461, -1.673][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350794-frontMiddleLeft-front",
    "productId": "350794",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 0, 4.32, 4.46, 4.6, 4.74, 0, 0][sizeIndex])",
      "offsetY": "in2px(7)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350794-frontMiddleRight-front",
    "productId": "350794",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 0, -4.32, -4.46, -4.6, -4.74, 0, 0][sizeIndex])",
      "offsetY": "in2px(7)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350794-jerseyRunbird-front",
    "productId": "350794",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.66, -3.81, -3.98, -4.2, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(1.582)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350794-upperBack-back",
    "productId": "350794",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -3.441+0.085, -3.445+0.04, -3.538-0.062, -3.624-0.194, 0, 0][sizeIndex]-0.416-0.64)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.5, 8.84, 9.59, 10.28, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350794-middleBack-back",
    "productId": "350794",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -3.441+0.085, -3.445+0.04, -3.538-0.062, -3.624-0.194, 0, 0][sizeIndex]-0.416-0.64) + in2px(5.5)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350794-lowerBack-back",
    "productId": "350794",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -3.441+0.085, -3.445+0.04, -3.538-0.062, -3.624-0.194, 0, 0][sizeIndex]-0.416-0.64) + in2px(11)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350794-leftSleeve-sleeveLeft",
    "productId": "350794",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350794-rightSleeve-sleeveRight",
    "productId": "350794",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350794-sizeTag-front",
    "productId": "350794",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350789-frontChest-front",
    "productId": "350789",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "350789-upperFrontLeftChest-front",
    "productId": "350789",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 3.8, 3.85, 3.95, 4.02, 4.16, 4.44, 4.58][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350789-frontMiddleLeft-front",
    "productId": "350789",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 5.28, 5.28, 5.43, 5.57, 5.57, 5.89, 5.89][sizeIndex])",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350789-frontMiddleRight-front",
    "productId": "350789",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -5.28, -5.28, -5.43, -5.57, -5.57, -5.89, -5.89][sizeIndex])",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350789-jerseyRunbird-front",
    "productId": "350789",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -4.51, -4.59, -4.71, -4.9, -4.99, -5.15, -5.35][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350789-upperBack-back",
    "productId": "350789",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.08, -5.43, -5.73, -6.08, -6.48, -6.78, -7.03][sizeIndex]-0.586) + in2px(-0.86)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350789-middleBack-back",
    "productId": "350789",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.42, 1.07, 0.77, 0.43, 0.02, -0.28, -0.53][sizeIndex]-0.586) + in2px(-0.86)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350789-lowerBack-back",
    "productId": "350789",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex]-0.31) + in2px(-0.86)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350789-leftSleeve-sleeveLeft",
    "productId": "350789",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350789-rightSleeve-sleeveRight",
    "productId": "350789",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350789-sizeTag-front",
    "productId": "350789",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350795-frontChest-front",
    "productId": "350795",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.4)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350795-upperFrontLeftChest-front",
    "productId": "350795",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.74, 3.88, 4.16, 4.28, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(-0.476-0.4)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350795-frontMiddleLeft-front",
    "productId": "350795",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.5, 4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.6-0.4)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350795-frontMiddleRight-front",
    "productId": "350795",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.5, -4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.6-0.4)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350795-jerseyRunbird-front",
    "productId": "350795",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.42, -3.62, -3.83, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(-0.476)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350795-upperBack-back",
    "productId": "350795",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.15, 3.7-8.14, 3.36-8.16, 2.95-8.18, 0, 0][sizeIndex]-0.674+0.86-0.25) + in2px(-0.86+0.24)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.5, 8.84, 9.59, 10.28, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350795-middleBack-back",
    "productId": "350795",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.85, 3.7-1.84, 3.36-1.86, 2.95-1.88, 0, 0][sizeIndex]-1.814+0.86-0.25) + in2px(-0.15-0.86+0.24)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350795-lowerBack-back",
    "productId": "350795",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.245, 3.7+3.27, 3.36+3.235, 2.95+3.215, 0, 0][sizeIndex]-1.745+0.86-0.25) + in2px(-0.3-0.86+0.24)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350795-leftSleeve-sleeveLeft",
    "productId": "350795",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350795-rightSleeve-sleeveRight",
    "productId": "350795",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350795-sizeTag-front",
    "productId": "350795",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350790-frontChest-front",
    "productId": "350790",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(-0.42)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "350790-upperFrontLeftChest-front",
    "productId": "350790",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 3.74, 3.74, 3.82, 4.03, 4.25, 4.31, 4.45][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(-0.42)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350790-frontMiddleLeft-front",
    "productId": "350790",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 5.1, 5.1, 5.24, 5.37, 5.51, 5.65, 5.79][sizeIndex])",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.42)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350790-frontMiddleRight-front",
    "productId": "350790",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -5.1, -5.1, -5.24, -5.37, -5.51, -5.65, -5.79][sizeIndex])",
      "offsetY": "in2px([0, 8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.42)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350790-jerseyRunbird-front",
    "productId": "350790",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -4.5, -4.59, -4.7, -4.89, -4.99, -5.14, -5.35][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(-1.22)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350790-upperBack-back",
    "productId": "350790",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, -5.08, -5.43, -5.73, -6.08, -6.48, -6.78, -7.03][sizeIndex]-0.586) + in2px(-0.86)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350790-middleBack-back",
    "productId": "350790",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 1.42, 1.07, 0.77, 0.43, 0.02, -0.28, -0.53][sizeIndex]-0.586) + in2px(-0.86)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350790-lowerBack-back",
    "productId": "350790",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex]-0.31) + in2px(-0.86)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350790-leftSleeve-sleeveLeft",
    "productId": "350790",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350790-rightSleeve-sleeveRight",
    "productId": "350790",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350790-sizeTag-front",
    "productId": "350790",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350796-frontChest-front",
    "productId": "350796",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350796-upperFrontLeftChest-front",
    "productId": "350796",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.56, 3.7, 3.84, 3.97, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(-0.476-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350796-frontMiddleLeft-front",
    "productId": "350796",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.5, 4.65, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.6-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350796-frontMiddleRight-front",
    "productId": "350796",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.5, -4.65, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.6-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350796-jerseyRunbird-front",
    "productId": "350796",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.38, -3.58, -3.74, -3.88, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(-0.476)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350796-upperBack-back",
    "productId": "350796",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.15, 3.7-8.14, 3.36-8.16, 2.95-8.18, 0, 0][sizeIndex]-0.674+0.86-0.25) + in2px(-0.86)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.5, 8.84, 9.59, 10.28, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350796-middleBack-back",
    "productId": "350796",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.85, 3.7-1.84, 3.36-1.86, 2.95-1.88, 0, 0][sizeIndex]-1.814+0.86-0.25) + in2px(-0.12-0.86)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350796-lowerBack-back",
    "productId": "350796",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.245, 3.7+3.27, 3.36+3.235, 2.95+3.215, 0, 0][sizeIndex]-1.745+0.86-0.25) + in2px(-0.24-0.86)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350796-leftSleeve-sleeveLeft",
    "productId": "350796",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350796-rightSleeve-sleeveRight",
    "productId": "350796",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350796-sizeTag-front",
    "productId": "350796",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350843-pantsRunbird-pLeftFront",
    "productId": "350843",
    "placementId": "pantsRunbird",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.99)",
      "offsetY": "in2px(4.25)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350843-leftLeg-pLeftFront",
    "productId": "350843",
    "placementId": "leftLeg",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.99)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "350843-rightLeg-pRightFront",
    "productId": "350843",
    "placementId": "rightLeg",
    "pieceName": "pRightFront",
    "anchor": {
      "from": "center-center",
      "to": "rightTop",
      "offsetX": "in2px(2.99)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "350843-backBeltLoop-pBackLoop",
    "productId": "350843",
    "placementId": "backBeltLoop",
    "pieceName": "pBackLoop",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "(anchors.middleBottom.y - anchors.middleTop.y) / 2"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(11)"
    }
  },
  {
    "id": "350844-pantsRunbird-pLeftFront",
    "productId": "350844",
    "placementId": "pantsRunbird",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.69)",
      "offsetY": "in2px(4.62)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350844-leftLeg-pLeftFront",
    "productId": "350844",
    "placementId": "leftLeg",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.69)",
      "offsetY": "in2px(7.14)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(5)"
    }
  },
  {
    "id": "350844-rightLeg-pRightFront",
    "productId": "350844",
    "placementId": "rightLeg",
    "pieceName": "pRightFront",
    "anchor": {
      "from": "center-center",
      "to": "rightTop",
      "offsetX": "in2px(2.69)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(5)"
    }
  },
  {
    "id": "350844-backBeltLoop-pBackLoop",
    "productId": "350844",
    "placementId": "backBeltLoop",
    "pieceName": "pBackLoop",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "(anchors.middleBottom.y - anchors.middleTop.y) / 2"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(5.5)"
    }
  },
  {
    "id": "350845-pantsRunbird-pLeftFront",
    "productId": "350845",
    "placementId": "pantsRunbird",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.99)",
      "offsetY": "in2px(4.25)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350845-leftLeg-pLeftFront",
    "productId": "350845",
    "placementId": "leftLeg",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.99)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "350845-rightLeg-pRightFront",
    "productId": "350845",
    "placementId": "rightLeg",
    "pieceName": "pRightFront",
    "anchor": {
      "from": "center-center",
      "to": "rightTop",
      "offsetX": "in2px(2.99)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "350845-backBeltLoop-pBackLoop",
    "productId": "350845",
    "placementId": "backBeltLoop",
    "pieceName": "pBackLoop",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "(anchors.middleBottom.y - anchors.middleTop.y) / 2"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(11)"
    }
  },
  {
    "id": "350846-pantsRunbird-pLeftFront",
    "productId": "350846",
    "placementId": "pantsRunbird",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.69)",
      "offsetY": "in2px(4.62)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350846-leftLeg-pLeftFront",
    "productId": "350846",
    "placementId": "leftLeg",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.69)",
      "offsetY": "in2px(7.14)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(5)"
    }
  },
  {
    "id": "350846-rightLeg-pRightFront",
    "productId": "350846",
    "placementId": "rightLeg",
    "pieceName": "pRightFront",
    "anchor": {
      "from": "center-center",
      "to": "rightTop",
      "offsetX": "in2px(2.69)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(5)"
    }
  },
  {
    "id": "350846-backBeltLoop-pBackLoop",
    "productId": "350846",
    "placementId": "backBeltLoop",
    "pieceName": "pBackLoop",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "(anchors.middleBottom.y - anchors.middleTop.y) / 2"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(5.5)"
    }
  },
  {
    "id": "350847-pantsRunbird-pLeftFront",
    "productId": "350847",
    "placementId": "pantsRunbird",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.042)",
      "offsetY": "in2px(5)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350847-leftLeg-pLeftFront",
    "productId": "350847",
    "placementId": "leftLeg",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.042)",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "350847-rightLeg-pRightFront",
    "productId": "350847",
    "placementId": "rightLeg",
    "pieceName": "pRightFront",
    "anchor": {
      "from": "center-center",
      "to": "rightTop",
      "offsetX": "in2px(2.042)",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "350847-backBeltLoop-pBackLoop",
    "productId": "350847",
    "placementId": "backBeltLoop",
    "pieceName": "pBackLoop",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "(anchors.middleBottom.y - anchors.middleTop.y) / 2"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(11)"
    }
  },
  {
    "id": "350848-pantsRunbird-pLeftFront",
    "productId": "350848",
    "placementId": "pantsRunbird",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.042-0.8)",
      "offsetY": "in2px(5)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350848-leftLeg-pLeftFront",
    "productId": "350848",
    "placementId": "leftLeg",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.042-0.8)",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(5)"
    }
  },
  {
    "id": "350848-rightLeg-pRightFront",
    "productId": "350848",
    "placementId": "rightLeg",
    "pieceName": "pRightFront",
    "anchor": {
      "from": "center-center",
      "to": "rightTop",
      "offsetX": "in2px(2.042+0.8)",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(5)"
    }
  },
  {
    "id": "350848-backBeltLoop-pBackLoop",
    "productId": "350848",
    "placementId": "backBeltLoop",
    "pieceName": "pBackLoop",
    "anchor": {
      "from": "center-center",
      "to": "document-center",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(0)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(5.5)"
    }
  },
  {
    "id": "351079-pantsRunbird-pLeftFront",
    "productId": "351079",
    "placementId": "pantsRunbird",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.99)",
      "offsetY": "in2px(4.75)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "351079-leftLeg-pLeftFront",
    "productId": "351079",
    "placementId": "leftLeg",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.99)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "351079-rightLeg-pRightFront",
    "productId": "351079",
    "placementId": "rightLeg",
    "pieceName": "pRightFront",
    "anchor": {
      "from": "center-center",
      "to": "rightTop",
      "offsetX": "in2px(2.99)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "351079-backBeltLoop-pBackLoop",
    "productId": "351079",
    "placementId": "backBeltLoop",
    "pieceName": "pBackLoop",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "(anchors.middleBottom.y - anchors.middleTop.y) / 2"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(11)"
    }
  },
  {
    "id": "351083-pantsRunbird-pLeftFront",
    "productId": "351083",
    "placementId": "pantsRunbird",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.69)",
      "offsetY": "in2px(4.62)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "351083-leftLeg-pLeftFront",
    "productId": "351083",
    "placementId": "leftLeg",
    "pieceName": "pLeftFront",
    "anchor": {
      "from": "center-center",
      "to": "leftTop",
      "offsetX": "in2px(-2.69)",
      "offsetY": "in2px(7.14)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(5)"
    }
  },
  {
    "id": "351083-rightLeg-pRightFront",
    "productId": "351083",
    "placementId": "rightLeg",
    "pieceName": "pRightFront",
    "anchor": {
      "from": "center-center",
      "to": "rightTop",
      "offsetX": "in2px(2.69)",
      "offsetY": "in2px(7.17)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(5)"
    }
  },
  {
    "id": "351083-backBeltLoop-pBackLoop",
    "productId": "351083",
    "placementId": "backBeltLoop",
    "pieceName": "pBackLoop",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "(anchors.middleBottom.y - anchors.middleTop.y) / 2"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(5.5)"
    }
  },
  {
    "id": "350797-frontChest-frontLeft",
    "productId": "350797",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-2.5, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 2.65-2.5, 2.4-2.5][sizeIndex]+0.5)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "350797-frontChest-frontRight",
    "productId": "350797",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-2.5, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 2.65-2.5, 2.4-2.5][sizeIndex]+0.5)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "350797-upperFrontLeftChest-frontLeft",
    "productId": "350797",
    "placementId": "upperFrontLeftChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.sewMin.x + in2px([0, 5.37, 5.394, 5.42, 5.65, 5.763, 5.794, 5.835][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 4.35-6.85, 4-6.85, 3.7-6.85, 3.36-6.85, 2.95-6.85, 2.65-6.85, 2.4-6.85][sizeIndex]) - anchors.armLeftHPS.y + in2px(0.715)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350797-frontMiddleLeft-frontLeft",
    "productId": "350797",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 5.61, 5.66, 5.58, 6.14, 6.41, 6.67, 6.95][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(7)"
    }
  },
  {
    "id": "350797-frontMiddleRight-frontRight",
    "productId": "350797",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 5.61, 5.66, 5.58, 6.14, 6.41, 6.67, 6.95][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(7)"
    }
  },
  {
    "id": "350797-jerseyRunbird-frontRight",
    "productId": "350797",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 4.43, 4.47, 4.57, 4.65, 4.81, 4.89, 5.02][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.6)",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350797-upperBack-back",
    "productId": "350797",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-9.43, 4-9.43, 3.7-9.43, 3.36-9.43, 2.95-9.43, 2.65-9.43, 2.4-9.43][sizeIndex]-0.478+0.2)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12-0.8)"
    }
  },
  {
    "id": "350797-middleBack-back",
    "productId": "350797",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-2, 4-2, 3.7-2, 3.36-2, 2.95-2, 2.65-2, 2.4-2][sizeIndex]-1.408+0.2)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350797-lowerBack-back",
    "productId": "350797",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35+4.22, 4+4.22, 3.7+4.22, 3.36+4.22, 2.95+4.22, 2.65+4.22, 2.4+4.22][sizeIndex]-1.128+0.2)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350797-leftSleeve-sleeveLeft",
    "productId": "350797",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350797-rightSleeve-sleeveRight",
    "productId": "350797",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350797-sizeTag-frontLeft",
    "productId": "350797",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350803-frontChest-frontLeft",
    "productId": "350803",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350803-frontChest-frontRight",
    "productId": "350803",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350803-upperFrontLeftChest-frontLeft",
    "productId": "350803",
    "placementId": "upperFrontLeftChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 4.647, 4.912, 5.118, 5.395, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, 4-6.1, 3.7-6.1, 3.36-6.1, 2.95-6.1, 0, 0][sizeIndex]+0.31) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350803-frontMiddleLeft-frontLeft",
    "productId": "350803",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 5.28, 5.28, 5.42, 5.42, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350803-frontMiddleRight-frontRight",
    "productId": "350803",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 5.28, 5.28, 5.42, 5.42, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350803-jerseyRunbird-frontRight",
    "productId": "350803",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 3.89, 4.21, 4.32, 4.7, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.75)",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex] - 0.337)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350803-upperBack-back",
    "productId": "350803",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.13, 3.7-8.13, 3.36-8.13, 2.95-8.13, 0, 0][sizeIndex]+0.148+0.077)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 9.01, 9.51, 10.27, 11.01, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350803-middleBack-back",
    "productId": "350803",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.83, 3.7-1.83, 3.36-1.83, 2.95-1.83, 0, 0][sizeIndex]-0.777-0.232+0.077)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350803-lowerBack-back",
    "productId": "350803",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.265, 3.7+3.265, 3.36+3.265, 2.95+3.265, 0, 0][sizeIndex]-0.497-0.464+0.077)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350803-leftSleeve-sleeveLeft",
    "productId": "350803",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350803-rightSleeve-sleeveRight",
    "productId": "350803",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350803-sizeTag-frontLeft",
    "productId": "350803",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350798-frontChest-front",
    "productId": "350798",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "350798-upperFrontLeftChest-front",
    "productId": "350798",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 4.27, 4.27, 4.41, 4.54, 4.68, 4.82, 5.1][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -1.582, -1.9, -2.109, -2.295, -2.589, -2.889, -3.154][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350798-frontMiddle-front",
    "productId": "350798",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350798-frontMiddleLeft-front",
    "productId": "350798",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 3.83, 3.83, 3.83, 3.97, 4.38, 4.52, 4.66][sizeIndex])",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350798-frontMiddleRight-front",
    "productId": "350798",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, -3.83, -3.83, -3.83, -3.97, -4.38, -4.52, -4.66][sizeIndex])",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350798-jerseyRunbird-front",
    "productId": "350798",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -3.66, -3.66, -3.78, -3.91, -4.03, -4.17, -4.31][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]) + in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350798-upperBack-back",
    "productId": "350798",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0,  -5.772, -6.08, -6.323, -6.477, -6.675, -7.024, -7.323][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350798-middleBack-back",
    "productId": "350798",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0,  -5.772, -6.08, -6.323, -6.477, -6.675, -7.024, -7.323][sizeIndex]) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350798-lowerBack-back",
    "productId": "350798",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0,  -5.772, -6.08, -6.323, -6.477, -6.675, -7.024, -7.323][sizeIndex]) + in2px(13)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350798-leftSleeve-sleeveLeft",
    "productId": "350798",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350798-rightSleeve-sleeveRight",
    "productId": "350798",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350798-sizeTag-front",
    "productId": "350798",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350804-frontChest-front",
    "productId": "350804",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350804-upperFrontLeftChest-front",
    "productId": "350804",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 4.1, 4.2, 4.54, 4.72, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -1.443, -1.511, -1.676, -1.838, 0, 0][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350804-frontMiddle-front",
    "productId": "350804",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(7)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350804-frontMiddleLeft-front",
    "productId": "350804",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 0, 3.92, 3.92, 4.19, 4.38, 0, 0][sizeIndex])",
      "offsetY": "in2px(7)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350804-frontMiddleRight-front",
    "productId": "350804",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 0, -3.92, -3.92, -4.19, -4.38, 0, 0][sizeIndex])",
      "offsetY": "in2px(7)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350804-jerseyRunbird-front",
    "productId": "350804",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.23, -3.7, -4, -4.2, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex] - 0.337) + in2px(0.854)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350804-upperBack-back",
    "productId": "350804",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.474, -4.55, -4.714, -4.867, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 9.01, 9.51, 10.27, 11.01, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350804-middleBack-back",
    "productId": "350804",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.474, -4.55, -4.714, -4.867, 0, 0][sizeIndex]) + in2px(5.143)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350804-lowerBack-back",
    "productId": "350804",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.474, -4.55, -4.714, -4.867, 0, 0][sizeIndex]) + in2px(10.286)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350804-leftSleeve-sleeveLeft",
    "productId": "350804",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350804-rightSleeve-sleeveRight",
    "productId": "350804",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350804-sizeTag-front",
    "productId": "350804",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350799-frontChest-front",
    "productId": "350799",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-2.5, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 2.65-2.5, 2.4-2.5][sizeIndex]+0.5) + in2px(-1.652)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350799-upperFrontLeftChest-front",
    "productId": "350799",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 4.28, 4.28, 4.28, 4.28, 4.55, 4.69, 4.83][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 4.35-6.85, 4-6.85, 3.7-6.85, 3.36-6.85, 2.95-6.85, 2.65-6.85, 2.4-6.85][sizeIndex]) - anchors.armLeftHPS.y + in2px(0.715) + in2px(-1.652)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350799-frontMiddle-front",
    "productId": "350799",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5) + in2px(-1.652)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350799-frontMiddleLeft-front",
    "productId": "350799",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 3.9, 4.18, 4.18, 4.18, 4.32, 4.46, 4.75][sizeIndex])",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5) + in2px(-1.652)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350799-frontMiddleRight-front",
    "productId": "350799",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -3.9, -4.18, -4.18, -4.18, -4.32, -4.46, -4.75][sizeIndex])",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5) + in2px(-1.652)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350799-jerseyRunbird-front",
    "productId": "350799",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -3.83, -3.83, -3.97, -4.07, -4.19, -4.32, -4.44][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]) + in2px(-1.652)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350799-upperBack-back",
    "productId": "350799",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-9.43, 4-9.43, 3.7-9.43, 3.36-9.43, 2.95-9.43, 2.65-9.43, 2.4-9.43][sizeIndex]-0.478+0.2)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350799-middleBack-back",
    "productId": "350799",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-2, 4-2, 3.7-2, 3.36-2, 2.95-2, 2.65-2, 2.4-2][sizeIndex]-1.408+0.2)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350799-lowerBack-back",
    "productId": "350799",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35+4.22, 4+4.22, 3.7+4.22, 3.36+4.22, 2.95+4.22, 2.65+4.22, 2.4+4.22][sizeIndex]-1.128+0.2)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350799-leftSleeve-sleeveLeft",
    "productId": "350799",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350799-rightSleeve-sleeveRight",
    "productId": "350799",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350799-sizeTag-front",
    "productId": "350799",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350805-frontChest-front",
    "productId": "350805",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]) + in2px(-1.19)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(10.5)"
    }
  },
  {
    "id": "350805-upperFrontLeftChest-front",
    "productId": "350805",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.71, 3.84, 3.98, 4.11, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, 4-6.1, 3.7-6.1, 3.36-6.1, 2.95-6.1, 0, 0][sizeIndex]+0.31) - anchors.armLeftHPS.y + in2px(-1.19)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350805-frontMiddle-front",
    "productId": "350805",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]) + in2px(-1.19-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350805-frontMiddleLeft-front",
    "productId": "350805",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 3.62, 3.62, 3.76, 4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]) + in2px(-1.19-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350805-frontMiddleRight-front",
    "productId": "350805",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.62, -3.62, -3.76, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]) + in2px(-1.19-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350805-jerseyRunbird-front",
    "productId": "350805",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.17, -3.34, -3.5, -3.67, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex] - 0.337) + in2px(-1.61)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350805-upperBack-back",
    "productId": "350805",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.13, 3.7-8.13, 3.36-8.13, 2.95-8.13, 0, 0][sizeIndex]+0.148+0.077) + in2px(-0.3)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.69, 9.19, 9.95, 10.69, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350805-middleBack-back",
    "productId": "350805",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.83, 3.7-1.83, 3.36-1.83, 2.95-1.83, 0, 0][sizeIndex]-0.777-0.232+0.077) + in2px(-0.3)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350805-lowerBack-back",
    "productId": "350805",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.265, 3.7+3.265, 3.36+3.265, 2.95+3.265, 0, 0][sizeIndex]-0.497-0.464+0.077) + in2px(-0.3)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350805-leftSleeve-sleeveLeft",
    "productId": "350805",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350805-rightSleeve-sleeveRight",
    "productId": "350805",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "350805-sizeTag-front",
    "productId": "350805",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350800-frontChest-frontLeft",
    "productId": "350800",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(-0.05)",
      "offsetY": "in2px([0, 4.35-2.5, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 2.65-2.5, 2.4-2.5][sizeIndex]+0.5)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350800-frontChest-frontRight",
    "productId": "350800",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0.05)",
      "offsetY": "in2px([0, 4.35-2.5, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 2.65-2.5, 2.4-2.5][sizeIndex]+0.5)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350800-frontMiddleLeft-frontLeft",
    "productId": "350800",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 5.6, 5.6, 5.54, 5.95, 6.37, 6.45, 6.52][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(6.5)"
    }
  },
  {
    "id": "350800-frontMiddleRight-frontRight",
    "productId": "350800",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 5.6, 5.6, 5.54, 5.95, 6.37, 6.45, 6.52][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(6.5)"
    }
  },
  {
    "id": "350800-jerseyRunbird-frontRight",
    "productId": "350800",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 4.44, 4.44, 4.64, 4.7, 4.82, 4.95, 5.07][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.25)",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]) + in2px(-0.11)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350800-upperBack-back",
    "productId": "350800",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-9.43, 4-9.43, 3.7-9.43, 3.36-9.43, 2.95-9.43, 2.65-9.43, 2.4-9.43][sizeIndex]-0.478+0.2) + in2px(0.3)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350800-middleBack-back",
    "productId": "350800",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-2, 4-2, 3.7-2, 3.36-2, 2.95-2, 2.65-2, 2.4-2][sizeIndex]-1.408+0.2) + in2px(0.3)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350800-lowerBack-back",
    "productId": "350800",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35+4.22, 4+4.22, 3.7+4.22, 3.36+4.22, 2.95+4.22, 2.65+4.22, 2.4+4.22][sizeIndex]-1.128+0.2) + in2px(0.3)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350800-sizeTag-frontLeft",
    "productId": "350800",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350806-frontChest-frontLeft",
    "productId": "350806",
    "placementId": "frontChest",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350806-frontChest-frontRight",
    "productId": "350806",
    "placementId": "frontChest",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350806-frontMiddleLeft-frontLeft",
    "productId": "350806",
    "placementId": "frontMiddleLeft",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMin.x + in2px([0, 0, 5.03, 5.17, 5.31, 5.44, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "350806-frontMiddleRight-frontRight",
    "productId": "350806",
    "placementId": "frontMiddleRight",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 5.03, 5.17, 5.31, 5.44, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "350806-jerseyRunbird-frontRight",
    "productId": "350806",
    "placementId": "jerseyRunbird",
    "pieceName": "frontRight",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.sewMax.x - in2px([0, 0, 4.2, 4.5, 4.75, 5.1, 0, 0][sizeIndex]) - anchors.armBottomMiddle.x - in2px(0.25)",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex] - 0.337) + in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350806-upperBack-back",
    "productId": "350806",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.13, 3.7-8.13, 3.36-8.13, 2.95-8.13, 0, 0][sizeIndex]+0.148+0.077) + in2px(0.7-0.05)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 9.01, 9.51, 10.27, 11.01, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350806-middleBack-back",
    "productId": "350806",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.83, 3.7-1.83, 3.36-1.83, 2.95-1.83, 0, 0][sizeIndex]-0.777-0.232+0.077) + in2px(0.6-0.15-0.05-0.05)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350806-lowerBack-back",
    "productId": "350806",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.265, 3.7+3.265, 3.36+3.265, 2.95+3.265, 0, 0][sizeIndex]-0.497-0.464+0.077) + in2px(-0.1+0.3-0.05)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350806-sizeTag-frontLeft",
    "productId": "350806",
    "placementId": "sizeTag",
    "pieceName": "frontLeft",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350801-frontChest-front",
    "productId": "350801",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "350801-upperFrontLeftChest-front",
    "productId": "350801",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 4.21, 4.29, 4.29, 4.42, 4.56, 4.84, 4.98][sizeIndex]+0.25) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, -1.699, -2.067, -2.265, -2.469, -2.745, -3.045, -3.31][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350801-frontMiddle-front",
    "productId": "350801",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350801-frontMiddleLeft-front",
    "productId": "350801",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 3.77, 4.18, 4.18, 4.46, 4.6, 4.88, 4.8][sizeIndex])",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350801-frontMiddleRight-front",
    "productId": "350801",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, -3.77, -4.18, -4.18, -4.46, -4.6, -4.88, -4.8][sizeIndex])",
      "offsetY": "in2px(8)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350801-jerseyRunbird-front",
    "productId": "350801",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -3.52, -3.57, -3.69, -3.82, -3.94, -4.07, -4.22][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]) + in2px(0.564)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350801-upperBack-back",
    "productId": "350801",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0,  -5.772, -6.08, -6.3, -6.495, -6.78, -7.08, -7.345][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350801-middleBack-back",
    "productId": "350801",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0,  -5.772, -6.08, -6.3, -6.495, -6.78, -7.08, -7.345][sizeIndex]) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350801-lowerBack-back",
    "productId": "350801",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0,  -5.772, -6.08, -6.3, -6.495, -6.78, -7.08, -7.345][sizeIndex]) + in2px(13)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350801-sizeTag-front",
    "productId": "350801",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350807-frontChest-front",
    "productId": "350807",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(1)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "350807-upperFrontLeftChest-front",
    "productId": "350807",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.7, 3.7, 3.84, 4.11, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -1.143, -1.211, -1.369, -1.538, 0, 0][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350807-frontMiddle-front",
    "productId": "350807",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(7) + in2px(0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350807-frontMiddleLeft-front",
    "productId": "350807",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 0, 3.62, 3.76, 3.9, 4.18, 0, 0][sizeIndex])",
      "offsetY": "in2px(7) + in2px(0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350807-frontMiddleRight-front",
    "productId": "350807",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "top-center",
      "to": "middleTop",
      "offsetX": "in2px([0, 0, -3.62, -3.76, -3.9, -4.18, 0, 0][sizeIndex])",
      "offsetY": "in2px(7) + in2px(0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350807-jerseyRunbird-front",
    "productId": "350807",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.28, -3.35, -3.58, -3.81, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex] - 0.337) + in2px(1.126)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350807-upperBack-back",
    "productId": "350807",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.109, -4.185, -4.349, -4.502, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 9.01, 9.51, 10.27, 11.01, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350807-middleBack-back",
    "productId": "350807",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.109, -4.185, -4.349, -4.502, 0, 0][sizeIndex]) + in2px(5.143)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350807-lowerBack-back",
    "productId": "350807",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, -4.109, -4.185, -4.349, -4.502, 0, 0][sizeIndex]) + in2px(10.286)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350807-sizeTag-front",
    "productId": "350807",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px(-1.75)",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350802-frontChest-front",
    "productId": "350802",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-2.5, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 2.65-2.5, 2.4-2.5][sizeIndex]+0.5) + in2px(-1.267)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350802-upperFrontLeftChest-front",
    "productId": "350802",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 4.12, 4.12, 4.26, 4.39, 4.52, 4.66, 4.8][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 4.35-6.85, 4-6.85, 3.7-6.85, 3.36-6.85, 2.95-6.85, 2.65-6.85, 2.4-6.85][sizeIndex]) - anchors.armLeftHPS.y + in2px(0.715) + in2px(-1.267)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "350802-frontMiddle-front",
    "productId": "350802",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5) + in2px(-1.267)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350802-frontMiddleLeft-front",
    "productId": "350802",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 3.82, 4.21, 4.21, 4.62, 4.62, 4.62, 4.76][sizeIndex])",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5) + in2px(-1.267)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350802-frontMiddleRight-front",
    "productId": "350802",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -3.82, -4.21, -4.21, -4.62, -4.62, -4.62, -4.76][sizeIndex])",
      "offsetY": "in2px([0, 4.35+4, 4+4, 3.7+4, 3.36+4, 2.95+4, 2.65+4, 2.4+4][sizeIndex] + 0.5) + in2px(-1.267)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "350802-jerseyRunbird-front",
    "productId": "350802",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -3.77, -3.91, -4, -4.14, -4.28, -4.28, -4.52][sizeIndex])",
      "offsetY": "in2px([0, -2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]) + in2px(-1.267)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "350802-upperBack-back",
    "productId": "350802",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-9.43, 4-9.43, 3.7-9.43, 3.36-9.43, 2.95-9.43, 2.65-9.43, 2.4-9.43][sizeIndex]-0.478+0.2)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350802-middleBack-back",
    "productId": "350802",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35-2, 4-2, 3.7-2, 3.36-2, 2.95-2, 2.65-2, 2.4-2][sizeIndex]-1.408+0.2)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "350802-lowerBack-back",
    "productId": "350802",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 4.35+4.22, 4+4.22, 3.7+4.22, 3.36+4.22, 2.95+4.22, 2.65+4.22, 2.4+4.22][sizeIndex]-1.128+0.2)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "350802-sizeTag-front",
    "productId": "350802",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, -1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "350808-frontChest-front",
    "productId": "350808",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]) + in2px(-0.794-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(10)"
    }
  },
  {
    "id": "350808-upperFrontLeftChest-front",
    "productId": "350808",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.54, 3.54, 3.7, 3.83, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, 4-6.1, 3.7-6.1, 3.36-6.1, 2.95-6.1, 0, 0][sizeIndex]+0.31-0.5) - anchors.armLeftHPS.y + in2px(-0.794)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "350808-frontMiddle-front",
    "productId": "350808",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]) + in2px(-0.794-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350808-frontMiddleLeft-front",
    "productId": "350808",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 3.62, 3.62, 3.76, 4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]) + in2px(-0.794-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350808-frontMiddleRight-front",
    "productId": "350808",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.62, -3.62, -3.76, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]) + in2px(-0.794-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "350808-jerseyRunbird-front",
    "productId": "350808",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.15, -3.34, -3.5, -3.67, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex] - 0.337) + in2px(-0.794)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "350808-upperBack-back",
    "productId": "350808",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-8.13, 3.7-8.13, 3.36-8.13, 2.95-8.13, 0, 0][sizeIndex]+0.148+0.077-1)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.69, 9.19, 9.95, 10.69, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "350808-middleBack-back",
    "productId": "350808",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-1.83, 3.7-1.83, 3.36-1.83, 2.95-1.83, 0, 0][sizeIndex]-0.777-0.232+0.077-1)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350808-lowerBack-back",
    "productId": "350808",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4+3.265, 3.7+3.265, 3.36+3.265, 2.95+3.265, 0, 0][sizeIndex]-0.497-0.464+0.077-1)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "350808-sizeTag-front",
    "productId": "350808",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px(-1.75)",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440773-frontMiddle-front",
    "productId": "440773",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([2.55, 2.55, 2.25, 1.91, 1.5, 1.2, 0.95][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(6)"
    }
  },
  {
    "id": "440773-upperFrontLeftChest-front",
    "productId": "440773",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.78, 3.78, 4.03, 4.28, 4.55, 4.69, 4.83][sizeIndex]+3.5) - anchors.armLeftHPS.x",
      "offsetY": "in2px([-1.02, -1.37, -1.67, -2.01, -2.42, -2.72, -2.97][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440773-jerseyRunbird-front",
    "productId": "440773",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.83, -3.83, -3.97, -4.07, -4.19, -4.32, -4.44][sizeIndex])",
      "offsetY": "in2px([-1.02, -1.37, -1.67, -2.01, -2.42, -2.72, -2.97][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440773-upperBack-back",
    "productId": "440773",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([-5.335, -5.36, -5.71, -6.01, -6.35, -6.76, -7.06, -7.31][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440773-middleBack-back",
    "productId": "440773",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.165, 1.14, 0.79, 0.49, 0.15, -0.26, -0.56, -0.81][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440773-lowerBack-back",
    "productId": "440773",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([7.665, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440773-leftSleeve-sleeveLeft",
    "productId": "440773",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440773-rightSleeve-sleeveRight",
    "productId": "440773",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440773-sizeTag-front",
    "productId": "440773",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440774-frontChest-front",
    "productId": "440774",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 0.7, 0.58, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(10.5)"
    }
  },
  {
    "id": "440774-upperFrontLeftChest-front",
    "productId": "440774",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 0, 3.39, 3.53, 0, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, 0, -2.8, -2.92, 0, 0, 0][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440774-frontMiddle-front",
    "productId": "440774",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440774-frontMiddleLeft-front",
    "productId": "440774",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 3.62, 3.62, 3.76, 4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440774-frontMiddleRight-front",
    "productId": "440774",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.62, -3.62, -3.76, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440774-jerseyRunbird-front",
    "productId": "440774",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 0, -3.34, -3.5, 0, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, -2.75, -3.22, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440774-upperBack-back",
    "productId": "440774",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -5.51, -5.6, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.69, 9.19, 9.95, 10.69, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440774-middleBack-back",
    "productId": "440774",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -0.36, -0.45, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440774-lowerBack-back",
    "productId": "440774",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 4.78, 4.69, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440774-leftSleeve-sleeveLeft",
    "productId": "440774",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.4)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440774-rightSleeve-sleeveRight",
    "productId": "440774",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.4)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440774-sizeTag-front",
    "productId": "440774",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440775-frontChest-front",
    "productId": "440775",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440775-upperFrontLeftChest-front",
    "productId": "440775",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.8, 3.85, 3.95, 4.02, 4.16, 4.44, 4.58][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440775-frontMiddleLeft-front",
    "productId": "440775",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([5.28, 5.28, 5.43, 5.57, 5.57, 5.89, 5.89][sizeIndex]-1)",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.5)"
    }
  },
  {
    "id": "440775-frontMiddleRight-front",
    "productId": "440775",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-5.28, -5.28, -5.43, -5.57, -5.57, -5.89, -5.89][sizeIndex]+1)",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.5)"
    }
  },
  {
    "id": "440775-jerseyRunbird-front",
    "productId": "440775",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-4.51, -4.59, -4.71, -4.9, -4.99, -5.15, -5.35][sizeIndex])",
      "offsetY": "in2px([-2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440775-upperBack-back",
    "productId": "440775",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440775-middleBack-back",
    "productId": "440775",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440775-lowerBack-back",
    "productId": "440775",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440775-leftSleeve-sleeveLeft",
    "productId": "440775",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440775-rightSleeve-sleeveRight",
    "productId": "440775",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440775-sizeTag-front",
    "productId": "440775",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440776-frontChest-front",
    "productId": "440776",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.4+1.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "440776-upperFrontLeftChest-front",
    "productId": "440776",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.74, 3.88, 4.16, 4.28, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(-0.476-0.4+1.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440776-frontMiddleLeft-front",
    "productId": "440776",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.5, 4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.6-0.4+1.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440776-frontMiddleRight-front",
    "productId": "440776",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.5, -4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.6-0.4+1.5)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440776-jerseyRunbird-front",
    "productId": "440776",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.42, -3.62, -3.83, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(-0.476+1.5)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440776-upperBack-back",
    "productId": "440776",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 9.16, 9.68, 10.41, 11.14, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440776-middleBack-back",
    "productId": "440776",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440776-lowerBack-back",
    "productId": "440776",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440776-leftSleeve-sleeveLeft",
    "productId": "440776",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440776-rightSleeve-sleeveRight",
    "productId": "440776",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440776-sizeTag-front",
    "productId": "440776",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440777-frontChest-front",
    "productId": "440777",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([2.55, 2.55, 2.25, 1.91, 1.5, 1.2, 0.95][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440777-upperFrontLeftChest-front",
    "productId": "440777",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.78, 3.78, 4.03, 4.28, 4.55, 4.69, 4.83][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-3.1+0.25, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]+1.15) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440777-frontMiddle-front",
    "productId": "440777",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440777-frontMiddleLeft-front",
    "productId": "440777",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([3.9, 4.18, 4.18, 4.18, 4.32, 4.46, 4.75][sizeIndex])",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440777-frontMiddleRight-front",
    "productId": "440777",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.9, -4.18, -4.18, -4.18, -4.32, -4.46, -4.75][sizeIndex])",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440777-jerseyRunbird-front",
    "productId": "440777",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.83, -3.83, -3.97, -4.07, -4.19, -4.32, -4.44][sizeIndex])",
      "offsetY": "in2px([-1.02, -1.37, -1.67, -2.01, -2.42, -2.72, -2.97][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440777-upperBack-back",
    "productId": "440777",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([-5.335, -5.36, -5.71, -6.01, -6.35, -6.76, -7.06, -7.31][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440777-middleBack-back",
    "productId": "440777",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.165, 1.14, 0.79, 0.49, 0.15, -0.26, -0.56, -0.81][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440777-lowerBack-back",
    "productId": "440777",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([7.665, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440777-leftSleeve-sleeveLeft",
    "productId": "440777",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440777-rightSleeve-sleeveRight",
    "productId": "440777",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440777-sizeTag-front",
    "productId": "440777",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440778-frontChest-front",
    "productId": "440778",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 0.7, 0.58, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(10.5)"
    }
  },
  {
    "id": "440778-upperFrontLeftChest-front",
    "productId": "440778",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 0, 3.39, 3.53, 0, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, 0, -2.8, -2.92, 0, 0, 0][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440778-frontMiddle-front",
    "productId": "440778",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440778-frontMiddleLeft-front",
    "productId": "440778",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 3.62, 3.62, 3.76, 4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440778-frontMiddleRight-front",
    "productId": "440778",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.62, -3.62, -3.76, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440778-jerseyRunbird-front",
    "productId": "440778",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 0, -3.34, -3.5, 0, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, -2.75, -3.22, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440778-upperBack-back",
    "productId": "440778",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -5.51, -5.6, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.69, 9.19, 9.95, 10.69, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440778-middleBack-back",
    "productId": "440778",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -0.36, -0.45, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440778-lowerBack-back",
    "productId": "440778",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 4.78, 4.69, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440778-leftSleeve-sleeveLeft",
    "productId": "440778",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440778-rightSleeve-sleeveRight",
    "productId": "440778",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440778-sizeTag-front",
    "productId": "440778",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440779-frontChest-front",
    "productId": "440779",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440779-upperFrontLeftChest-front",
    "productId": "440779",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.8, 3.85, 3.95, 4.02, 4.16, 4.44, 4.58][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440779-frontMiddleLeft-front",
    "productId": "440779",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([5.28, 5.28, 5.43, 5.57, 5.57, 5.89, 5.89][sizeIndex]-1)",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440779-frontMiddleRight-front",
    "productId": "440779",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-5.28, -5.28, -5.43, -5.57, -5.57, -5.89, -5.89][sizeIndex]+1)",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440779-jerseyRunbird-front",
    "productId": "440779",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-4.51, -4.59, -4.71, -4.9, -4.99, -5.15, -5.35][sizeIndex])",
      "offsetY": "in2px([-2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440779-upperBack-back",
    "productId": "440779",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440779-middleBack-back",
    "productId": "440779",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440779-lowerBack-back",
    "productId": "440779",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440779-leftSleeve-sleeveLeft",
    "productId": "440779",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440779-rightSleeve-sleeveRight",
    "productId": "440779",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440779-sizeTag-front",
    "productId": "440779",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440780-frontChest-front",
    "productId": "440780",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153+1) + in2px(-0.476-0.4)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "440780-upperFrontLeftChest-front",
    "productId": "440780",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.74, 3.88, 4.16, 4.28, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(-0.476-0.4+1)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440780-frontMiddleLeft-front",
    "productId": "440780",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.5, 4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.6-0.4+1)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440780-frontMiddleRight-front",
    "productId": "440780",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.5, -4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-0.476-0.6-0.4+1)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440780-jerseyRunbird-front",
    "productId": "440780",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.42, -3.62, -3.83, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(-0.476+1)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440780-upperBack-back",
    "productId": "440780",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 9.16, 9.68, 10.41, 11.14, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440780-middleBack-back",
    "productId": "440780",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440780-lowerBack-back",
    "productId": "440780",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440780-leftSleeve-sleeveLeft",
    "productId": "440780",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440780-rightSleeve-sleeveRight",
    "productId": "440780",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440780-sizeTag-front",
    "productId": "440780",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440781-frontChest-front",
    "productId": "440781",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([2.55, 2.55, 2.25, 1.91, 1.5, 1.2, 0.95][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440781-upperFrontLeftChest-front",
    "productId": "440781",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.78, 4.03, 4.28, 4.28, 4.55, 4.69, 4.83][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-3.1+0.25, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]+1.15) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440781-frontMiddle-front",
    "productId": "440781",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440781-frontMiddleLeft-front",
    "productId": "440781",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([3.9, 4.18, 4.18, 4.18, 4.32, 4.46, 4.75][sizeIndex])",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440781-frontMiddleRight-front",
    "productId": "440781",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.9, -4.18, -4.18, -4.18, -4.32, -4.46, -4.75][sizeIndex])",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440781-jerseyRunbird-front",
    "productId": "440781",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.83, -3.83, -3.97, -4.07, -4.19, -4.32, -4.44][sizeIndex])",
      "offsetY": "in2px([-1.82, -2.17, -2.47, -2.81, -3.22, -3.52, -3.77][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440781-upperBack-back",
    "productId": "440781",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([-5.335, -5.36, -5.71, -6.01, -6.35, -6.76, -7.06, -7.31][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px([10, 10, 11, 11, 12, 12, 12, 0][sizeIndex])"
    }
  },
  {
    "id": "440781-middleBack-back",
    "productId": "440781",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.165, 1.14, 0.79, 0.49, 0.15, -0.26, -0.56, -0.81][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440781-lowerBack-back",
    "productId": "440781",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([7.665, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440781-sizeTag-front",
    "productId": "440781",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440782-frontChest-front",
    "productId": "440782",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 0.7, 0.58, 0, 0, 0][sizeIndex]+0.2)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(10.5)"
    }
  },
  {
    "id": "440782-upperFrontLeftChest-front",
    "productId": "440782",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 0, 3.39, 3.53, 0, 0, 0][sizeIndex]+0.2) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, 0, -2.8, -2.92, 0, 0, 0][sizeIndex]+0.2) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440782-frontMiddle-front",
    "productId": "440782",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex]+0.2)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440782-frontMiddleLeft-front",
    "productId": "440782",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 3.62, 3.62, 3.76, 4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex]+0.2)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440782-frontMiddleRight-front",
    "productId": "440782",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.62, -3.62, -3.76, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 6.4, 6.2, 0, 0, 0][sizeIndex]+0.2)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440782-jerseyRunbird-front",
    "productId": "440782",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.17, -3.34, -3.5, -3.67, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, -2.25, -2.59, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440782-upperBack-back",
    "productId": "440782",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -5.51, -5.6, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.69, 9.19, 9.95, 10.69, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440782-middleBack-back",
    "productId": "440782",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -0.36, -0.45, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440782-lowerBack-back",
    "productId": "440782",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 4.78, 4.69, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440782-sizeTag-front",
    "productId": "440782",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440783-frontChest-front",
    "productId": "440783",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4+0.9) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "440783-upperFrontLeftChest-front",
    "productId": "440783",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([5.08, 5.08, 5.08, 5.12, 5.26, 5.4, 5.48][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4+0.9) - anchors.armLeftHPS.y + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440783-frontMiddleLeft-front",
    "productId": "440783",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([5.19, 5.32, 5.6, 6.02, 6.3, 6.85, 7][sizeIndex])",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4+0.9) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440783-frontMiddleRight-front",
    "productId": "440783",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-5.19, -5.32, -5.6, -6.02, -6.3, -6.85, -7][sizeIndex])",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4+0.9) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440783-jerseyRunbird-front",
    "productId": "440783",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-4.9, -5.01, -5.14, -5.32, -5.48, -5.62, -5.79][sizeIndex])",
      "offsetY": "in2px([-2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46+0.9) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440783-upperBack-back",
    "productId": "440783",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283+0.9)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440783-middleBack-back",
    "productId": "440783",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5+0.9)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440783-lowerBack-back",
    "productId": "440783",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72+0.9)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440783-leftSleeve-sleeveLeft",
    "productId": "440783",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440783-rightSleeve-sleeveRight",
    "productId": "440783",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440783-sizeTag-front",
    "productId": "440783",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440784-frontChest-front",
    "productId": "440784",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "440784-upperFrontLeftChest-front",
    "productId": "440784",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 4.26, 4.4, 4.54, 4.76, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440784-frontMiddleLeft-front",
    "productId": "440784",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.36, 4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281-0.6)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440784-frontMiddleRight-front",
    "productId": "440784",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.36, -4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281-0.6)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440784-jerseyRunbird-front",
    "productId": "440784",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -0, -3.45, -3.64, -3.82, -4.02, 0, 0][sizeIndex]) - in2px(0.5)",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440784-upperBack-back",
    "productId": "440784",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.9, 9.44, 10.21, 10.94, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440784-middleBack-back",
    "productId": "440784",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440784-lowerBack-back",
    "productId": "440784",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440784-leftSleeve-sleeveLeft",
    "productId": "440784",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440784-rightSleeve-sleeveRight",
    "productId": "440784",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-2.9-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440784-sizeTag-front",
    "productId": "440784",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440785-frontChest-front",
    "productId": "440785",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(14)"
    }
  },
  {
    "id": "440785-upperFrontLeftChest-front",
    "productId": "440785",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([5.08, 5.08, 5.08, 5.12, 5.26, 5.4, 5.48][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440785-frontMiddleLeft-front",
    "productId": "440785",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([5.19, 5.32, 5.6, 6.02, 6.3, 6.85, 7][sizeIndex])",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440785-frontMiddleRight-front",
    "productId": "440785",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-5.19, -5.32, -5.6, -6.02, -6.3, -6.85, -7][sizeIndex])",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440785-jerseyRunbird-front",
    "productId": "440785",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-4.9, -5.01, -5.14, -5.32, -5.48, -5.62, -5.79][sizeIndex])",
      "offsetY": "in2px([-2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(-2.75)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440785-upperBack-back",
    "productId": "440785",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440785-middleBack-back",
    "productId": "440785",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440785-lowerBack-back",
    "productId": "440785",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440785-sizeTag-front",
    "productId": "440785",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-2, -2.25, -2.25, -2.25, -2.5, -2.5, -2.75][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440786-frontChest-front",
    "productId": "440786",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 4-2.5, 3.7-2.5, 3.36-2.5, 2.95-2.5, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(11.5)"
    }
  },
  {
    "id": "440786-upperFrontLeftChest-front",
    "productId": "440786",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 4.26, 4.4, 4.54, 4.76, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, -3.59+1.153, -3.89+1.253, -4.23+1.153, -4.64+1.153, 0, 0][sizeIndex]) - anchors.armLeftHPS.y + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440786-frontMiddleLeft-front",
    "productId": "440786",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 4.36, 4.36, 4.36, 4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281-0.6)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440786-frontMiddleRight-front",
    "productId": "440786",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -4.36, -4.36, -4.36, -4.64, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 4+3, 3.7+3, 3.36+3, 2.95+3, 0, 0][sizeIndex]-1.49+1.153) + in2px(-1.281-0.6)"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440786-jerseyRunbird-front",
    "productId": "440786",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, -0, -3.45, -3.64, -3.82, -4.02, 0, 0][sizeIndex]) - in2px(0.5)",
      "offsetY": "in2px([0, 0, 4-6.13, 3.7-6.13, 3.36-6.13, 2.95-6.13, 0, 0][sizeIndex]-1.49) + in2px(-1.281)"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440786-upperBack-back",
    "productId": "440786",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.9, 9.44, 10.21, 10.94, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440786-middleBack-back",
    "productId": "440786",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440786-lowerBack-back",
    "productId": "440786",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440786-sizeTag-front",
    "productId": "440786",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.25, -1.7, -1.85, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440797-frontChest-front",
    "productId": "440797",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0.29, -0.06, -0.36, -0.7, -1.11, -1.41, -1.66][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "440797-upperFrontLeftChest-front",
    "productId": "440797",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.8, 3.85, 3.95, 4.02, 4.16, 4.44, 4.58][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-4.06, -4.41, -4.71, -5.05, -5.46, -5.76, -6.01][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440797-frontMiddleLeft-front",
    "productId": "440797",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([5.28, 5.28, 5.43, 5.57, 5.57, 5.89, 5.89][sizeIndex])",
      "offsetY": "in2px([6.79, 6.44, 6.14, 5.8, 5.39, 5.09, 4.84][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440797-frontMiddleRight-front",
    "productId": "440797",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-5.28, -5.28, -5.43, -5.57, -5.57, -5.89, -5.89][sizeIndex])",
      "offsetY": "in2px([6.79, 6.44, 6.14, 5.8, 5.39, 5.09, 4.84][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440797-jerseyRunbird-front",
    "productId": "440797",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-4.51, -4.59, -4.71, -4.9, -4.99, -5.15, -5.35][sizeIndex])",
      "offsetY": "in2px([-4.33, -4.68, -4.98, -5.32, -5.73, -6.03, -6.28][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440797-upperBack-back",
    "productId": "440797",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440797-middleBack-back",
    "productId": "440797",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440797-lowerBack-back",
    "productId": "440797",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440797-leftSleeve-sleeveLeft",
    "productId": "440797",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440797-rightSleeve-sleeveRight",
    "productId": "440797",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440797-sizeTag-front",
    "productId": "440797",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440798-frontChest-front",
    "productId": "440798",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([2.55, 2.55, 2.25, 1.91, 1.5, 1.2, 0.95][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440798-upperFrontLeftChest-front",
    "productId": "440798",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.78, 4.03, 4.28, 4.28, 4.55, 4.69, 4.83][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-1.7, -1.7, -2.0, -2.34, -2.75, -3.05, -3.3][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440798-frontMiddle-front",
    "productId": "440798",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440798-frontMiddleLeft-front",
    "productId": "440798",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([3.9, 4.18, 4.18, 4.18, 4.32, 4.46, 4.75][sizeIndex])",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440798-frontMiddleRight-front",
    "productId": "440798",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.9, -4.18, -4.18, -4.18, -4.32, -4.46, -4.75][sizeIndex])",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440798-jerseyRunbird-front",
    "productId": "440798",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.83, -3.83, -3.97, -4.07, -4.19, -4.32, -4.44][sizeIndex])",
      "offsetY": "in2px([-2.17, -2.17, -2.47, -2.81, -3.22, -3.52, -3.77][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440798-upperBack-back",
    "productId": "440798",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([-5.335, -5.36, -5.71, -6.01, -6.35, -6.76, -7.06, -7.31][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440798-middleBack-back",
    "productId": "440798",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.165, 1.14, 0.79, 0.49, 0.15, -0.26, -0.56, -0.81][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440798-lowerBack-back",
    "productId": "440798",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([7.665, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440798-leftSleeve-sleeveLeft",
    "productId": "440798",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440798-rightSleeve-sleeveRight",
    "productId": "440798",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440798-sizeTag-front",
    "productId": "440798",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440799-frontChest-front",
    "productId": "440799",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.85, 1.5, 1.2, 0.86, 0.45, 0.15, -0.1][sizeIndex] - 1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(13.5)"
    }
  },
  {
    "id": "440799-upperFrontLeftChest-front",
    "productId": "440799",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.8, 3.85, 3.95, 4.02, 4.16, 4.44, 4.58][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-2.5, -2.85, -3.15, -3.49, -3.9, -4.2, -4.45][sizeIndex]-1.46+0.4) - anchors.armLeftHPS.y + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440799-frontMiddleLeft-front",
    "productId": "440799",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([5.28, 5.28, 5.43, 5.57, 5.57, 5.89, 5.89][sizeIndex])",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440799-frontMiddleRight-front",
    "productId": "440799",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-5.28, -5.28, -5.43, -5.57, -5.57, -5.89, -5.89][sizeIndex])",
      "offsetY": "in2px([8.35, 8, 7.7, 7.36, 6.95, 6.65, 6.4][sizeIndex]-1.46+0.4) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440799-jerseyRunbird-front",
    "productId": "440799",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-4.51, -4.59, -4.71, -4.9, -4.99, -5.15, -5.35][sizeIndex])",
      "offsetY": "in2px([-2.37, -2.72, -3.02, -3.36, -3.77, -4.07, -4.32][sizeIndex]-1.46) + in2px(-0.5)"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440799-upperBack-back",
    "productId": "440799",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(0.283)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440799-middleBack-back",
    "productId": "440799",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(6.5)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440799-lowerBack-back",
    "productId": "440799",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "middleTop",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(2.5) + in2px(12.72)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440799-leftSleeve-sleeveLeft",
    "productId": "440799",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440799-rightSleeve-sleeveRight",
    "productId": "440799",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440799-sizeTag-front",
    "productId": "440799",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440800-frontChest-front",
    "productId": "440800",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([2.55, 2.55, 2.25, 1.91, 1.5, 1.2, 0.95][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440800-upperFrontLeftChest-front",
    "productId": "440800",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.8, 3.85, 3.95, 4.02, 4.16, 4.44, 4.58][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-1.7, -1.7, -2.0, -2.34, -2.75, -3.05, -3.3][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440800-frontMiddle-front",
    "productId": "440800",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440800-frontMiddleLeft-front",
    "productId": "440800",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([3.9, 4.18, 4.18, 4.18, 4.32, 4.46, 4.75][sizeIndex])",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440800-frontMiddleRight-front",
    "productId": "440800",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.9, -4.18, -4.18, -4.18, -4.32, -4.46, -4.75][sizeIndex])",
      "offsetY": "in2px([9.05, 9.05, 8.75, 8.41, 8.0, 7.7, 7.45][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440800-jerseyRunbird-front",
    "productId": "440800",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.83, -3.83, -3.97, -4.07, -4.19, -4.32, -4.44][sizeIndex])",
      "offsetY": "in2px([-2.17, -2.17, -2.47, -2.81, -3.22, -3.52, -3.77][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440800-upperBack-back",
    "productId": "440800",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([-5.335, -5.36, -5.71, -6.01, -6.35, -6.76, -7.06, -7.31][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440800-middleBack-back",
    "productId": "440800",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.165, 1.14, 0.79, 0.49, 0.15, -0.26, -0.56, -0.81][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440800-lowerBack-back",
    "productId": "440800",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([7.665, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440800-leftSleeve-sleeveLeft",
    "productId": "440800",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440800-rightSleeve-sleeveRight",
    "productId": "440800",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440800-sizeTag-front",
    "productId": "440800",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440801-frontChest-front",
    "productId": "440801",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([2.55+0.12, 2.55+0.1, 2.25+0.11, 1.91+0.12, 1.5+0.34-0.13, 1.2+0.34-0.27, 0.95+0.4-0.34][sizeIndex]-2.25)"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440801-upperFrontLeftChest-front",
    "productId": "440801",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.8, 3.85, 3.95, 4.02, 4.16, 4.44, 4.58][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-1.7+0.12, -1.7+0.1, -2.0+0.11, -2.34+0.12, -2.75+0.34-0.13, -3.05+0.34-0.27, -3.3+0.4-0.34][sizeIndex]-2.25) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440801-frontMiddle-front",
    "productId": "440801",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([9.05+0.12, 9.05+0.1, 8.75+0.11, 8.41+0.12, 8.0+0.34-0.13, 7.7+0.34-0.27, 7.45+0.4-0.34][sizeIndex]-2.25)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440801-frontMiddleLeft-front",
    "productId": "440801",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([3.9, 4.18, 4.18, 4.18, 4.32, 4.46, 4.75][sizeIndex])",
      "offsetY": "in2px([9.05+0.12, 9.05+0.1, 8.75+0.11, 8.41+0.12, 8.0+0.34-0.13, 7.7+0.34-0.27, 7.45+0.4-0.34][sizeIndex]-2.25)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440801-frontMiddleRight-front",
    "productId": "440801",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.9, -4.18, -4.18, -4.18, -4.32, -4.46, -4.75][sizeIndex])",
      "offsetY": "in2px([9.05+0.12, 9.05+0.1, 8.75+0.11, 8.41+0.12, 8.0+0.34-0.13, 7.7+0.34-0.27, 7.45+0.4-0.34][sizeIndex]-2.25)"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440801-jerseyRunbird-front",
    "productId": "440801",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.83, -3.83, -3.97, -4.07, -4.19, -4.32, -4.44][sizeIndex])",
      "offsetY": "in2px([-4.02, -4.37, -4.67, -5.01, -5.42, -5.72, -5.97][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440801-upperBack-back",
    "productId": "440801",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([-5.335, -5.36, -5.71, -6.01, -6.35, -6.76, -7.06, -7.31][sizeIndex]-1.25)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440801-middleBack-back",
    "productId": "440801",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.165, 1.14, 0.79, 0.49, 0.15, -0.26, -0.56, -0.81][sizeIndex]-1.25)"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440801-lowerBack-back",
    "productId": "440801",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([7.665, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex]-1.25)"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440801-leftSleeve-sleeveLeft",
    "productId": "440801",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440801-rightSleeve-sleeveRight",
    "productId": "440801",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.68)"
    },
    "boundingBox": {
      "height": "in2px(3.75)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440801-sizeTag-front",
    "productId": "440801",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440803-frontChest-front",
    "productId": "440803",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 0.5-1.07+0.25, 0.38-1.08+0.25, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(10.5)"
    }
  },
  {
    "id": "440803-upperFrontLeftChest-front",
    "productId": "440803",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 3.71, 3.84, 3.98, 4.11, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, 0, -2.83-1.07, -2.87-1.08, 0, 0, 0][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440803-frontMiddle-front",
    "productId": "440803",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 6.2-1.07+0.25, 6-1.08+0.25, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440803-frontMiddleLeft-front",
    "productId": "440803",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 3.62, 3.62, 3.76, 4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 6.2-1.07+0.25, 6-1.08+0.25, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440803-frontMiddleRight-front",
    "productId": "440803",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.62, -3.62, -3.76, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 6.2-1.07+0.25, 6-1.08+0.25, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440803-jerseyRunbird-front",
    "productId": "440803",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.17, -3.34, -3.5, -3.67, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, -3.38, -3.72, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440803-upperBack-back",
    "productId": "440803",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -5.51, -5.6, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.69, 9.19, 9.95, 10.69, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440803-middleBack-back",
    "productId": "440803",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -0.36, -0.45, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440803-lowerBack-back",
    "productId": "440803",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 4.78, 4.69, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440803-leftSleeve-sleeveLeft",
    "productId": "440803",
    "placementId": "leftSleeve",
    "pieceName": "sleeveLeft",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.4)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440803-rightSleeve-sleeveRight",
    "productId": "440803",
    "placementId": "rightSleeve",
    "pieceName": "sleeveRight",
    "anchor": {
      "from": "center-center",
      "to": "middleBottom",
      "offsetX": "in2px(0)",
      "offsetY": "in2px(-3.4)"
    },
    "boundingBox": {
      "height": "in2px(3.1)",
      "width": "in2px(3.1)"
    }
  },
  {
    "id": "440803-sizeTag-front",
    "productId": "440803",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440802-frontChest-front",
    "productId": "440802",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0.42, 0.42, 0.12, -0.22, -0.63, -0.93, -1.18][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(5)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440802-upperFrontLeftChest-front",
    "productId": "440802",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([3.78, 3.78, 3.78, 4.28, 4.55, 4.69, 4.83][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([-3.83, -3.83, -4.13, -4.47, -4.88, -5.18, -5.43][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3.5)",
      "width": "in2px(3.5)"
    }
  },
  {
    "id": "440802-frontMiddle-front",
    "productId": "440802",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([6.92, 6.92, 6.62, 6.28, 5.87, 5.57, 5.32][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440802-frontMiddleLeft-front",
    "productId": "440802",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([3.9, 4.18, 4.18, 4.18, 4.32, 4.46, 4.75][sizeIndex])",
      "offsetY": "in2px([6.92, 6.92, 6.62, 6.28, 5.87, 5.57, 5.32][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440802-frontMiddleRight-front",
    "productId": "440802",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.9, -4.18, -4.18, -4.18, -4.32, -4.46, -4.75][sizeIndex])",
      "offsetY": "in2px([6.92, 6.92, 6.62, 6.28, 5.87, 5.57, 5.32][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(8.3)"
    }
  },
  {
    "id": "440802-jerseyRunbird-front",
    "productId": "440802",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([-3.83, -3.83, -3.97, -4.07, -4.19, -4.32, -4.44][sizeIndex])",
      "offsetY": "in2px([-4.22, -4.57, -4.87, -5.21, -5.62, -5.92, -6.17][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.032)",
      "width": "in2px(2.52)"
    }
  },
  {
    "id": "440802-upperBack-back",
    "productId": "440802",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([-5.335, -5.36, -5.71, -6.01, -6.35, -6.76, -7.06, -7.31][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440802-middleBack-back",
    "productId": "440802",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([1.165, 1.14, 0.79, 0.49, 0.15, -0.26, -0.56, -0.81][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(8)",
      "width": "in2px(15.4)"
    }
  },
  {
    "id": "440802-lowerBack-back",
    "productId": "440802",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([7.665, 7.64, 7.29, 6.99, 6.65, 6.24, 5.94, 5.69][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(2)",
      "width": "in2px(12)"
    }
  },
  {
    "id": "440802-sizeTag-front",
    "productId": "440802",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([-1.8, -2, -2.25, -2.25, -2.25, -2.5, -2.5][sizeIndex])",
      "offsetY": "in2px(-1.5)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  },
  {
    "id": "440804-frontChest-front",
    "productId": "440804",
    "placementId": "frontChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -0.05, -0.15, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(4)",
      "width": "in2px(10.5)"
    }
  },
  {
    "id": "440804-upperFrontLeftChest-front",
    "productId": "440804",
    "placementId": "upperFrontLeftChest",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armLeftHPS",
      "offsetX": "anchors.armBottomMiddle.x + in2px([0, 0, 0, 3.39, 3.53, 0, 0, 0][sizeIndex]) - anchors.armLeftHPS.x",
      "offsetY": "anchors.armBottomMiddle.y + in2px([0, 0, 0, -3.63, -3.77, 0, 0, 0][sizeIndex]) - anchors.armLeftHPS.y"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(3)"
    }
  },
  {
    "id": "440804-frontMiddle-front",
    "productId": "440804",
    "placementId": "frontMiddle",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 5.65, 5.55, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440804-frontMiddleLeft-front",
    "productId": "440804",
    "placementId": "frontMiddleLeft",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, 3.62, 3.62, 3.76, 4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 5.65, 5.55, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440804-frontMiddleRight-front",
    "productId": "440804",
    "placementId": "frontMiddleRight",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.62, -3.62, -3.76, -4.04, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, 5.65, 5.55, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(3)",
      "width": "in2px(6.8)"
    }
  },
  {
    "id": "440804-jerseyRunbird-front",
    "productId": "440804",
    "placementId": "jerseyRunbird",
    "pieceName": "front",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px([0, 0, -3.17, -3.34, -3.5, -3.67, 0, 0][sizeIndex])",
      "offsetY": "in2px([0, 0, 0, -3.08, -3.42, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(0.86)",
      "width": "in2px(2.1)"
    }
  },
  {
    "id": "440804-upperBack-back",
    "productId": "440804",
    "placementId": "upperBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, -4.52, -4.69, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px([0, 0, 8.69, 9.19, 9.95, 10.69, 0, 0][sizeIndex])"
    }
  },
  {
    "id": "440804-middleBack-back",
    "productId": "440804",
    "placementId": "middleBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 0.65, 0.46, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(6)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440804-lowerBack-back",
    "productId": "440804",
    "placementId": "lowerBack",
    "pieceName": "back",
    "anchor": {
      "from": "center-center",
      "to": "armBottomMiddle",
      "offsetX": "in2px(0)",
      "offsetY": "in2px([0, 0, 0, 5.79, 5.6, 0, 0, 0][sizeIndex])"
    },
    "boundingBox": {
      "height": "in2px(1.75)",
      "width": "in2px(11.75)"
    }
  },
  {
    "id": "440804-sizeTag-front",
    "productId": "440804",
    "placementId": "sizeTag",
    "pieceName": "front",
    "anchor": {
      "from": "bottom-right",
      "to": "leftBottom",
      "offsetX": "in2px([0, 0, -1.25, -1.5, -1.7, -1.75, 0, 0][sizeIndex])",
      "offsetY": "in2px(-1.25)"
    },
    "boundingBox": {
      "height": "in2px(1.26)",
      "width": "in2px(3.75)"
    }
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<AnchorRow[]>> = data;

export type Anchor = typeof typed[number];

export const ANCHOR_INDEX_KEY = "id";
export type AnchorIndexKey = "id";
export type AnchorId = Anchor["id"];

let i = 0;
export const ANCHOR_DICT = {
  "350771-frontChest-frontLeft": typed[i++],
  "350771-frontChest-frontRight": typed[i++],
  "350771-upperFrontLeftChest-frontLeft": typed[i++],
  "350771-frontMiddleLeft-frontLeft": typed[i++],
  "350771-frontMiddleRight-frontRight": typed[i++],
  "350771-jerseyRunbird-frontRight": typed[i++],
  "350771-upperBack-back": typed[i++],
  "350771-middleBack-back": typed[i++],
  "350771-lowerBack-back": typed[i++],
  "350771-leftSleeve-sleeveLeft": typed[i++],
  "350771-rightSleeve-sleeveRight": typed[i++],
  "350771-sizeTag-frontLeft": typed[i++],
  "350772-frontChest-frontLeft": typed[i++],
  "350772-frontChest-frontRight": typed[i++],
  "350772-upperFrontLeftChest-frontLeft": typed[i++],
  "350772-frontMiddleLeft-frontLeft": typed[i++],
  "350772-frontMiddleRight-frontRight": typed[i++],
  "350772-jerseyRunbird-frontRight": typed[i++],
  "350772-upperBack-back": typed[i++],
  "350772-middleBack-back": typed[i++],
  "350772-lowerBack-back": typed[i++],
  "350772-leftSleeve-sleeveLeft": typed[i++],
  "350772-rightSleeve-sleeveRight": typed[i++],
  "350772-sizeTag-frontLeft": typed[i++],
  "350773-frontChest-front": typed[i++],
  "350773-upperFrontLeftChest-front": typed[i++],
  "350773-frontMiddleLeft-front": typed[i++],
  "350773-frontMiddleRight-front": typed[i++],
  "350773-jerseyRunbird-front": typed[i++],
  "350773-upperBack-back": typed[i++],
  "350773-middleBack-back": typed[i++],
  "350773-lowerBack-back": typed[i++],
  "350773-leftSleeve-sleeveLeft": typed[i++],
  "350773-rightSleeve-sleeveRight": typed[i++],
  "350773-sizeTag-front": typed[i++],
  "350774-frontChest-front": typed[i++],
  "350774-upperFrontLeftChest-front": typed[i++],
  "350774-frontMiddleLeft-front": typed[i++],
  "350774-frontMiddleRight-front": typed[i++],
  "350774-jerseyRunbird-front": typed[i++],
  "350774-upperBack-back": typed[i++],
  "350774-middleBack-back": typed[i++],
  "350774-lowerBack-back": typed[i++],
  "350774-leftSleeve-sleeveLeft": typed[i++],
  "350774-rightSleeve-sleeveRight": typed[i++],
  "350774-sizeTag-front": typed[i++],
  "350775-frontChest-front": typed[i++],
  "350775-upperFrontLeftChest-front": typed[i++],
  "350775-frontMiddleLeft-front": typed[i++],
  "350775-frontMiddleRight-front": typed[i++],
  "350775-jerseyRunbird-front": typed[i++],
  "350775-upperBack-back": typed[i++],
  "350775-middleBack-back": typed[i++],
  "350775-lowerBack-back": typed[i++],
  "350775-leftSleeve-sleeveLeft": typed[i++],
  "350775-rightSleeve-sleeveRight": typed[i++],
  "350775-sizeTag-front": typed[i++],
  "350776-frontChest-front": typed[i++],
  "350776-upperFrontLeftChest-front": typed[i++],
  "350776-frontMiddleLeft-front": typed[i++],
  "350776-frontMiddleRight-front": typed[i++],
  "350776-jerseyRunbird-front": typed[i++],
  "350776-upperBack-back": typed[i++],
  "350776-middleBack-back": typed[i++],
  "350776-lowerBack-back": typed[i++],
  "350776-leftSleeve-sleeveLeft": typed[i++],
  "350776-rightSleeve-sleeveRight": typed[i++],
  "350776-sizeTag-front": typed[i++],
  "350777-frontChest-front": typed[i++],
  "350777-upperFrontLeftChest-front": typed[i++],
  "350777-frontMiddleLeft-front": typed[i++],
  "350777-frontMiddleRight-front": typed[i++],
  "350777-jerseyRunbird-front": typed[i++],
  "350777-upperBack-back": typed[i++],
  "350777-middleBack-back": typed[i++],
  "350777-lowerBack-back": typed[i++],
  "350777-leftSleeve-sleeveLeft": typed[i++],
  "350777-rightSleeve-sleeveRight": typed[i++],
  "350777-sizeTag-front": typed[i++],
  "350778-frontChest-front": typed[i++],
  "350778-upperFrontLeftChest-front": typed[i++],
  "350778-frontMiddleLeft-front": typed[i++],
  "350778-frontMiddleRight-front": typed[i++],
  "350778-jerseyRunbird-front": typed[i++],
  "350778-upperBack-back": typed[i++],
  "350778-middleBack-back": typed[i++],
  "350778-lowerBack-back": typed[i++],
  "350778-leftSleeve-sleeveLeft": typed[i++],
  "350778-rightSleeve-sleeveRight": typed[i++],
  "350778-sizeTag-front": typed[i++],
  "350785-frontChest-frontLeft": typed[i++],
  "350785-frontChest-frontRight": typed[i++],
  "350785-upperFrontLeftChest-frontLeft": typed[i++],
  "350785-frontMiddleLeft-frontLeft": typed[i++],
  "350785-frontMiddleRight-frontRight": typed[i++],
  "350785-jerseyRunbird-frontRight": typed[i++],
  "350785-upperBack-back": typed[i++],
  "350785-middleBack-back": typed[i++],
  "350785-lowerBack-back": typed[i++],
  "350785-leftSleeve-sleeveLeft": typed[i++],
  "350785-rightSleeve-sleeveRight": typed[i++],
  "350785-sizeTag-frontLeft": typed[i++],
  "350793-frontChest-frontLeft": typed[i++],
  "350793-frontChest-frontRight": typed[i++],
  "350793-upperFrontLeftChest-frontLeft": typed[i++],
  "350793-frontMiddleLeft-frontLeft": typed[i++],
  "350793-frontMiddleRight-frontRight": typed[i++],
  "350793-jerseyRunbird-frontRight": typed[i++],
  "350793-upperBack-back": typed[i++],
  "350793-middleBack-back": typed[i++],
  "350793-lowerBack-back": typed[i++],
  "350793-leftSleeve-sleeveLeft": typed[i++],
  "350793-rightSleeve-sleeveRight": typed[i++],
  "350793-sizeTag-frontLeft": typed[i++],
  "350786-frontChest-frontLeft": typed[i++],
  "350786-frontChest-frontRight": typed[i++],
  "350786-upperFrontLeftChest-frontLeft": typed[i++],
  "350786-frontMiddleLeft-frontLeft": typed[i++],
  "350786-frontMiddleRight-frontRight": typed[i++],
  "350786-jerseyRunbird-frontRight": typed[i++],
  "350786-upperBack-back": typed[i++],
  "350786-middleBack-back": typed[i++],
  "350786-lowerBack-back": typed[i++],
  "350786-sizeTag-frontLeft": typed[i++],
  "350791-frontChest-frontLeft": typed[i++],
  "350791-frontChest-frontRight": typed[i++],
  "350791-upperFrontLeftChest-frontLeft": typed[i++],
  "350791-frontMiddleLeft-frontLeft": typed[i++],
  "350791-frontMiddleRight-frontRight": typed[i++],
  "350791-jerseyRunbird-frontRight": typed[i++],
  "350791-upperBack-back": typed[i++],
  "350791-middleBack-back": typed[i++],
  "350791-lowerBack-back": typed[i++],
  "350791-sizeTag-frontLeft": typed[i++],
  "350787-frontChest-front": typed[i++],
  "350787-upperFrontLeftChest-front": typed[i++],
  "350787-frontMiddle-front": typed[i++],
  "350787-frontMiddleLeft-front": typed[i++],
  "350787-frontMiddleRight-front": typed[i++],
  "350787-jerseyRunbird-front": typed[i++],
  "350787-upperBack-back": typed[i++],
  "350787-middleBack-back": typed[i++],
  "350787-lowerBack-back": typed[i++],
  "350787-sizeTag-front": typed[i++],
  "350792-frontChest-front": typed[i++],
  "350792-upperFrontLeftChest-front": typed[i++],
  "350792-frontMiddle-front": typed[i++],
  "350792-frontMiddleLeft-front": typed[i++],
  "350792-frontMiddleRight-front": typed[i++],
  "350792-jerseyRunbird-front": typed[i++],
  "350792-upperBack-back": typed[i++],
  "350792-middleBack-back": typed[i++],
  "350792-lowerBack-back": typed[i++],
  "350792-sizeTag-front": typed[i++],
  "350788-frontChest-front": typed[i++],
  "350788-upperFrontLeftChest-front": typed[i++],
  "350788-frontMiddleLeft-front": typed[i++],
  "350788-frontMiddleRight-front": typed[i++],
  "350788-jerseyRunbird-front": typed[i++],
  "350788-upperBack-back": typed[i++],
  "350788-middleBack-back": typed[i++],
  "350788-lowerBack-back": typed[i++],
  "350788-leftSleeve-sleeveLeft": typed[i++],
  "350788-rightSleeve-sleeveRight": typed[i++],
  "350788-sizeTag-front": typed[i++],
  "350794-frontChest-front": typed[i++],
  "350794-upperFrontLeftChest-front": typed[i++],
  "350794-frontMiddleLeft-front": typed[i++],
  "350794-frontMiddleRight-front": typed[i++],
  "350794-jerseyRunbird-front": typed[i++],
  "350794-upperBack-back": typed[i++],
  "350794-middleBack-back": typed[i++],
  "350794-lowerBack-back": typed[i++],
  "350794-leftSleeve-sleeveLeft": typed[i++],
  "350794-rightSleeve-sleeveRight": typed[i++],
  "350794-sizeTag-front": typed[i++],
  "350789-frontChest-front": typed[i++],
  "350789-upperFrontLeftChest-front": typed[i++],
  "350789-frontMiddleLeft-front": typed[i++],
  "350789-frontMiddleRight-front": typed[i++],
  "350789-jerseyRunbird-front": typed[i++],
  "350789-upperBack-back": typed[i++],
  "350789-middleBack-back": typed[i++],
  "350789-lowerBack-back": typed[i++],
  "350789-leftSleeve-sleeveLeft": typed[i++],
  "350789-rightSleeve-sleeveRight": typed[i++],
  "350789-sizeTag-front": typed[i++],
  "350795-frontChest-front": typed[i++],
  "350795-upperFrontLeftChest-front": typed[i++],
  "350795-frontMiddleLeft-front": typed[i++],
  "350795-frontMiddleRight-front": typed[i++],
  "350795-jerseyRunbird-front": typed[i++],
  "350795-upperBack-back": typed[i++],
  "350795-middleBack-back": typed[i++],
  "350795-lowerBack-back": typed[i++],
  "350795-leftSleeve-sleeveLeft": typed[i++],
  "350795-rightSleeve-sleeveRight": typed[i++],
  "350795-sizeTag-front": typed[i++],
  "350790-frontChest-front": typed[i++],
  "350790-upperFrontLeftChest-front": typed[i++],
  "350790-frontMiddleLeft-front": typed[i++],
  "350790-frontMiddleRight-front": typed[i++],
  "350790-jerseyRunbird-front": typed[i++],
  "350790-upperBack-back": typed[i++],
  "350790-middleBack-back": typed[i++],
  "350790-lowerBack-back": typed[i++],
  "350790-leftSleeve-sleeveLeft": typed[i++],
  "350790-rightSleeve-sleeveRight": typed[i++],
  "350790-sizeTag-front": typed[i++],
  "350796-frontChest-front": typed[i++],
  "350796-upperFrontLeftChest-front": typed[i++],
  "350796-frontMiddleLeft-front": typed[i++],
  "350796-frontMiddleRight-front": typed[i++],
  "350796-jerseyRunbird-front": typed[i++],
  "350796-upperBack-back": typed[i++],
  "350796-middleBack-back": typed[i++],
  "350796-lowerBack-back": typed[i++],
  "350796-leftSleeve-sleeveLeft": typed[i++],
  "350796-rightSleeve-sleeveRight": typed[i++],
  "350796-sizeTag-front": typed[i++],
  "350843-pantsRunbird-pLeftFront": typed[i++],
  "350843-leftLeg-pLeftFront": typed[i++],
  "350843-rightLeg-pRightFront": typed[i++],
  "350843-backBeltLoop-pBackLoop": typed[i++],
  "350844-pantsRunbird-pLeftFront": typed[i++],
  "350844-leftLeg-pLeftFront": typed[i++],
  "350844-rightLeg-pRightFront": typed[i++],
  "350844-backBeltLoop-pBackLoop": typed[i++],
  "350845-pantsRunbird-pLeftFront": typed[i++],
  "350845-leftLeg-pLeftFront": typed[i++],
  "350845-rightLeg-pRightFront": typed[i++],
  "350845-backBeltLoop-pBackLoop": typed[i++],
  "350846-pantsRunbird-pLeftFront": typed[i++],
  "350846-leftLeg-pLeftFront": typed[i++],
  "350846-rightLeg-pRightFront": typed[i++],
  "350846-backBeltLoop-pBackLoop": typed[i++],
  "350847-pantsRunbird-pLeftFront": typed[i++],
  "350847-leftLeg-pLeftFront": typed[i++],
  "350847-rightLeg-pRightFront": typed[i++],
  "350847-backBeltLoop-pBackLoop": typed[i++],
  "350848-pantsRunbird-pLeftFront": typed[i++],
  "350848-leftLeg-pLeftFront": typed[i++],
  "350848-rightLeg-pRightFront": typed[i++],
  "350848-backBeltLoop-pBackLoop": typed[i++],
  "351079-pantsRunbird-pLeftFront": typed[i++],
  "351079-leftLeg-pLeftFront": typed[i++],
  "351079-rightLeg-pRightFront": typed[i++],
  "351079-backBeltLoop-pBackLoop": typed[i++],
  "351083-pantsRunbird-pLeftFront": typed[i++],
  "351083-leftLeg-pLeftFront": typed[i++],
  "351083-rightLeg-pRightFront": typed[i++],
  "351083-backBeltLoop-pBackLoop": typed[i++],
  "350797-frontChest-frontLeft": typed[i++],
  "350797-frontChest-frontRight": typed[i++],
  "350797-upperFrontLeftChest-frontLeft": typed[i++],
  "350797-frontMiddleLeft-frontLeft": typed[i++],
  "350797-frontMiddleRight-frontRight": typed[i++],
  "350797-jerseyRunbird-frontRight": typed[i++],
  "350797-upperBack-back": typed[i++],
  "350797-middleBack-back": typed[i++],
  "350797-lowerBack-back": typed[i++],
  "350797-leftSleeve-sleeveLeft": typed[i++],
  "350797-rightSleeve-sleeveRight": typed[i++],
  "350797-sizeTag-frontLeft": typed[i++],
  "350803-frontChest-frontLeft": typed[i++],
  "350803-frontChest-frontRight": typed[i++],
  "350803-upperFrontLeftChest-frontLeft": typed[i++],
  "350803-frontMiddleLeft-frontLeft": typed[i++],
  "350803-frontMiddleRight-frontRight": typed[i++],
  "350803-jerseyRunbird-frontRight": typed[i++],
  "350803-upperBack-back": typed[i++],
  "350803-middleBack-back": typed[i++],
  "350803-lowerBack-back": typed[i++],
  "350803-leftSleeve-sleeveLeft": typed[i++],
  "350803-rightSleeve-sleeveRight": typed[i++],
  "350803-sizeTag-frontLeft": typed[i++],
  "350798-frontChest-front": typed[i++],
  "350798-upperFrontLeftChest-front": typed[i++],
  "350798-frontMiddle-front": typed[i++],
  "350798-frontMiddleLeft-front": typed[i++],
  "350798-frontMiddleRight-front": typed[i++],
  "350798-jerseyRunbird-front": typed[i++],
  "350798-upperBack-back": typed[i++],
  "350798-middleBack-back": typed[i++],
  "350798-lowerBack-back": typed[i++],
  "350798-leftSleeve-sleeveLeft": typed[i++],
  "350798-rightSleeve-sleeveRight": typed[i++],
  "350798-sizeTag-front": typed[i++],
  "350804-frontChest-front": typed[i++],
  "350804-upperFrontLeftChest-front": typed[i++],
  "350804-frontMiddle-front": typed[i++],
  "350804-frontMiddleLeft-front": typed[i++],
  "350804-frontMiddleRight-front": typed[i++],
  "350804-jerseyRunbird-front": typed[i++],
  "350804-upperBack-back": typed[i++],
  "350804-middleBack-back": typed[i++],
  "350804-lowerBack-back": typed[i++],
  "350804-leftSleeve-sleeveLeft": typed[i++],
  "350804-rightSleeve-sleeveRight": typed[i++],
  "350804-sizeTag-front": typed[i++],
  "350799-frontChest-front": typed[i++],
  "350799-upperFrontLeftChest-front": typed[i++],
  "350799-frontMiddle-front": typed[i++],
  "350799-frontMiddleLeft-front": typed[i++],
  "350799-frontMiddleRight-front": typed[i++],
  "350799-jerseyRunbird-front": typed[i++],
  "350799-upperBack-back": typed[i++],
  "350799-middleBack-back": typed[i++],
  "350799-lowerBack-back": typed[i++],
  "350799-leftSleeve-sleeveLeft": typed[i++],
  "350799-rightSleeve-sleeveRight": typed[i++],
  "350799-sizeTag-front": typed[i++],
  "350805-frontChest-front": typed[i++],
  "350805-upperFrontLeftChest-front": typed[i++],
  "350805-frontMiddle-front": typed[i++],
  "350805-frontMiddleLeft-front": typed[i++],
  "350805-frontMiddleRight-front": typed[i++],
  "350805-jerseyRunbird-front": typed[i++],
  "350805-upperBack-back": typed[i++],
  "350805-middleBack-back": typed[i++],
  "350805-lowerBack-back": typed[i++],
  "350805-leftSleeve-sleeveLeft": typed[i++],
  "350805-rightSleeve-sleeveRight": typed[i++],
  "350805-sizeTag-front": typed[i++],
  "350800-frontChest-frontLeft": typed[i++],
  "350800-frontChest-frontRight": typed[i++],
  "350800-frontMiddleLeft-frontLeft": typed[i++],
  "350800-frontMiddleRight-frontRight": typed[i++],
  "350800-jerseyRunbird-frontRight": typed[i++],
  "350800-upperBack-back": typed[i++],
  "350800-middleBack-back": typed[i++],
  "350800-lowerBack-back": typed[i++],
  "350800-sizeTag-frontLeft": typed[i++],
  "350806-frontChest-frontLeft": typed[i++],
  "350806-frontChest-frontRight": typed[i++],
  "350806-frontMiddleLeft-frontLeft": typed[i++],
  "350806-frontMiddleRight-frontRight": typed[i++],
  "350806-jerseyRunbird-frontRight": typed[i++],
  "350806-upperBack-back": typed[i++],
  "350806-middleBack-back": typed[i++],
  "350806-lowerBack-back": typed[i++],
  "350806-sizeTag-frontLeft": typed[i++],
  "350801-frontChest-front": typed[i++],
  "350801-upperFrontLeftChest-front": typed[i++],
  "350801-frontMiddle-front": typed[i++],
  "350801-frontMiddleLeft-front": typed[i++],
  "350801-frontMiddleRight-front": typed[i++],
  "350801-jerseyRunbird-front": typed[i++],
  "350801-upperBack-back": typed[i++],
  "350801-middleBack-back": typed[i++],
  "350801-lowerBack-back": typed[i++],
  "350801-sizeTag-front": typed[i++],
  "350807-frontChest-front": typed[i++],
  "350807-upperFrontLeftChest-front": typed[i++],
  "350807-frontMiddle-front": typed[i++],
  "350807-frontMiddleLeft-front": typed[i++],
  "350807-frontMiddleRight-front": typed[i++],
  "350807-jerseyRunbird-front": typed[i++],
  "350807-upperBack-back": typed[i++],
  "350807-middleBack-back": typed[i++],
  "350807-lowerBack-back": typed[i++],
  "350807-sizeTag-front": typed[i++],
  "350802-frontChest-front": typed[i++],
  "350802-upperFrontLeftChest-front": typed[i++],
  "350802-frontMiddle-front": typed[i++],
  "350802-frontMiddleLeft-front": typed[i++],
  "350802-frontMiddleRight-front": typed[i++],
  "350802-jerseyRunbird-front": typed[i++],
  "350802-upperBack-back": typed[i++],
  "350802-middleBack-back": typed[i++],
  "350802-lowerBack-back": typed[i++],
  "350802-sizeTag-front": typed[i++],
  "350808-frontChest-front": typed[i++],
  "350808-upperFrontLeftChest-front": typed[i++],
  "350808-frontMiddle-front": typed[i++],
  "350808-frontMiddleLeft-front": typed[i++],
  "350808-frontMiddleRight-front": typed[i++],
  "350808-jerseyRunbird-front": typed[i++],
  "350808-upperBack-back": typed[i++],
  "350808-middleBack-back": typed[i++],
  "350808-lowerBack-back": typed[i++],
  "350808-sizeTag-front": typed[i++],
  "440773-frontMiddle-front": typed[i++],
  "440773-upperFrontLeftChest-front": typed[i++],
  "440773-jerseyRunbird-front": typed[i++],
  "440773-upperBack-back": typed[i++],
  "440773-middleBack-back": typed[i++],
  "440773-lowerBack-back": typed[i++],
  "440773-leftSleeve-sleeveLeft": typed[i++],
  "440773-rightSleeve-sleeveRight": typed[i++],
  "440773-sizeTag-front": typed[i++],
  "440774-frontChest-front": typed[i++],
  "440774-upperFrontLeftChest-front": typed[i++],
  "440774-frontMiddle-front": typed[i++],
  "440774-frontMiddleLeft-front": typed[i++],
  "440774-frontMiddleRight-front": typed[i++],
  "440774-jerseyRunbird-front": typed[i++],
  "440774-upperBack-back": typed[i++],
  "440774-middleBack-back": typed[i++],
  "440774-lowerBack-back": typed[i++],
  "440774-leftSleeve-sleeveLeft": typed[i++],
  "440774-rightSleeve-sleeveRight": typed[i++],
  "440774-sizeTag-front": typed[i++],
  "440775-frontChest-front": typed[i++],
  "440775-upperFrontLeftChest-front": typed[i++],
  "440775-frontMiddleLeft-front": typed[i++],
  "440775-frontMiddleRight-front": typed[i++],
  "440775-jerseyRunbird-front": typed[i++],
  "440775-upperBack-back": typed[i++],
  "440775-middleBack-back": typed[i++],
  "440775-lowerBack-back": typed[i++],
  "440775-leftSleeve-sleeveLeft": typed[i++],
  "440775-rightSleeve-sleeveRight": typed[i++],
  "440775-sizeTag-front": typed[i++],
  "440776-frontChest-front": typed[i++],
  "440776-upperFrontLeftChest-front": typed[i++],
  "440776-frontMiddleLeft-front": typed[i++],
  "440776-frontMiddleRight-front": typed[i++],
  "440776-jerseyRunbird-front": typed[i++],
  "440776-upperBack-back": typed[i++],
  "440776-middleBack-back": typed[i++],
  "440776-lowerBack-back": typed[i++],
  "440776-leftSleeve-sleeveLeft": typed[i++],
  "440776-rightSleeve-sleeveRight": typed[i++],
  "440776-sizeTag-front": typed[i++],
  "440777-frontChest-front": typed[i++],
  "440777-upperFrontLeftChest-front": typed[i++],
  "440777-frontMiddle-front": typed[i++],
  "440777-frontMiddleLeft-front": typed[i++],
  "440777-frontMiddleRight-front": typed[i++],
  "440777-jerseyRunbird-front": typed[i++],
  "440777-upperBack-back": typed[i++],
  "440777-middleBack-back": typed[i++],
  "440777-lowerBack-back": typed[i++],
  "440777-leftSleeve-sleeveLeft": typed[i++],
  "440777-rightSleeve-sleeveRight": typed[i++],
  "440777-sizeTag-front": typed[i++],
  "440778-frontChest-front": typed[i++],
  "440778-upperFrontLeftChest-front": typed[i++],
  "440778-frontMiddle-front": typed[i++],
  "440778-frontMiddleLeft-front": typed[i++],
  "440778-frontMiddleRight-front": typed[i++],
  "440778-jerseyRunbird-front": typed[i++],
  "440778-upperBack-back": typed[i++],
  "440778-middleBack-back": typed[i++],
  "440778-lowerBack-back": typed[i++],
  "440778-leftSleeve-sleeveLeft": typed[i++],
  "440778-rightSleeve-sleeveRight": typed[i++],
  "440778-sizeTag-front": typed[i++],
  "440779-frontChest-front": typed[i++],
  "440779-upperFrontLeftChest-front": typed[i++],
  "440779-frontMiddleLeft-front": typed[i++],
  "440779-frontMiddleRight-front": typed[i++],
  "440779-jerseyRunbird-front": typed[i++],
  "440779-upperBack-back": typed[i++],
  "440779-middleBack-back": typed[i++],
  "440779-lowerBack-back": typed[i++],
  "440779-leftSleeve-sleeveLeft": typed[i++],
  "440779-rightSleeve-sleeveRight": typed[i++],
  "440779-sizeTag-front": typed[i++],
  "440780-frontChest-front": typed[i++],
  "440780-upperFrontLeftChest-front": typed[i++],
  "440780-frontMiddleLeft-front": typed[i++],
  "440780-frontMiddleRight-front": typed[i++],
  "440780-jerseyRunbird-front": typed[i++],
  "440780-upperBack-back": typed[i++],
  "440780-middleBack-back": typed[i++],
  "440780-lowerBack-back": typed[i++],
  "440780-leftSleeve-sleeveLeft": typed[i++],
  "440780-rightSleeve-sleeveRight": typed[i++],
  "440780-sizeTag-front": typed[i++],
  "440781-frontChest-front": typed[i++],
  "440781-upperFrontLeftChest-front": typed[i++],
  "440781-frontMiddle-front": typed[i++],
  "440781-frontMiddleLeft-front": typed[i++],
  "440781-frontMiddleRight-front": typed[i++],
  "440781-jerseyRunbird-front": typed[i++],
  "440781-upperBack-back": typed[i++],
  "440781-middleBack-back": typed[i++],
  "440781-lowerBack-back": typed[i++],
  "440781-sizeTag-front": typed[i++],
  "440782-frontChest-front": typed[i++],
  "440782-upperFrontLeftChest-front": typed[i++],
  "440782-frontMiddle-front": typed[i++],
  "440782-frontMiddleLeft-front": typed[i++],
  "440782-frontMiddleRight-front": typed[i++],
  "440782-jerseyRunbird-front": typed[i++],
  "440782-upperBack-back": typed[i++],
  "440782-middleBack-back": typed[i++],
  "440782-lowerBack-back": typed[i++],
  "440782-sizeTag-front": typed[i++],
  "440783-frontChest-front": typed[i++],
  "440783-upperFrontLeftChest-front": typed[i++],
  "440783-frontMiddleLeft-front": typed[i++],
  "440783-frontMiddleRight-front": typed[i++],
  "440783-jerseyRunbird-front": typed[i++],
  "440783-upperBack-back": typed[i++],
  "440783-middleBack-back": typed[i++],
  "440783-lowerBack-back": typed[i++],
  "440783-leftSleeve-sleeveLeft": typed[i++],
  "440783-rightSleeve-sleeveRight": typed[i++],
  "440783-sizeTag-front": typed[i++],
  "440784-frontChest-front": typed[i++],
  "440784-upperFrontLeftChest-front": typed[i++],
  "440784-frontMiddleLeft-front": typed[i++],
  "440784-frontMiddleRight-front": typed[i++],
  "440784-jerseyRunbird-front": typed[i++],
  "440784-upperBack-back": typed[i++],
  "440784-middleBack-back": typed[i++],
  "440784-lowerBack-back": typed[i++],
  "440784-leftSleeve-sleeveLeft": typed[i++],
  "440784-rightSleeve-sleeveRight": typed[i++],
  "440784-sizeTag-front": typed[i++],
  "440785-frontChest-front": typed[i++],
  "440785-upperFrontLeftChest-front": typed[i++],
  "440785-frontMiddleLeft-front": typed[i++],
  "440785-frontMiddleRight-front": typed[i++],
  "440785-jerseyRunbird-front": typed[i++],
  "440785-upperBack-back": typed[i++],
  "440785-middleBack-back": typed[i++],
  "440785-lowerBack-back": typed[i++],
  "440785-sizeTag-front": typed[i++],
  "440786-frontChest-front": typed[i++],
  "440786-upperFrontLeftChest-front": typed[i++],
  "440786-frontMiddleLeft-front": typed[i++],
  "440786-frontMiddleRight-front": typed[i++],
  "440786-jerseyRunbird-front": typed[i++],
  "440786-upperBack-back": typed[i++],
  "440786-middleBack-back": typed[i++],
  "440786-lowerBack-back": typed[i++],
  "440786-sizeTag-front": typed[i++],
  "440797-frontChest-front": typed[i++],
  "440797-upperFrontLeftChest-front": typed[i++],
  "440797-frontMiddleLeft-front": typed[i++],
  "440797-frontMiddleRight-front": typed[i++],
  "440797-jerseyRunbird-front": typed[i++],
  "440797-upperBack-back": typed[i++],
  "440797-middleBack-back": typed[i++],
  "440797-lowerBack-back": typed[i++],
  "440797-leftSleeve-sleeveLeft": typed[i++],
  "440797-rightSleeve-sleeveRight": typed[i++],
  "440797-sizeTag-front": typed[i++],
  "440798-frontChest-front": typed[i++],
  "440798-upperFrontLeftChest-front": typed[i++],
  "440798-frontMiddle-front": typed[i++],
  "440798-frontMiddleLeft-front": typed[i++],
  "440798-frontMiddleRight-front": typed[i++],
  "440798-jerseyRunbird-front": typed[i++],
  "440798-upperBack-back": typed[i++],
  "440798-middleBack-back": typed[i++],
  "440798-lowerBack-back": typed[i++],
  "440798-leftSleeve-sleeveLeft": typed[i++],
  "440798-rightSleeve-sleeveRight": typed[i++],
  "440798-sizeTag-front": typed[i++],
  "440799-frontChest-front": typed[i++],
  "440799-upperFrontLeftChest-front": typed[i++],
  "440799-frontMiddleLeft-front": typed[i++],
  "440799-frontMiddleRight-front": typed[i++],
  "440799-jerseyRunbird-front": typed[i++],
  "440799-upperBack-back": typed[i++],
  "440799-middleBack-back": typed[i++],
  "440799-lowerBack-back": typed[i++],
  "440799-leftSleeve-sleeveLeft": typed[i++],
  "440799-rightSleeve-sleeveRight": typed[i++],
  "440799-sizeTag-front": typed[i++],
  "440800-frontChest-front": typed[i++],
  "440800-upperFrontLeftChest-front": typed[i++],
  "440800-frontMiddle-front": typed[i++],
  "440800-frontMiddleLeft-front": typed[i++],
  "440800-frontMiddleRight-front": typed[i++],
  "440800-jerseyRunbird-front": typed[i++],
  "440800-upperBack-back": typed[i++],
  "440800-middleBack-back": typed[i++],
  "440800-lowerBack-back": typed[i++],
  "440800-leftSleeve-sleeveLeft": typed[i++],
  "440800-rightSleeve-sleeveRight": typed[i++],
  "440800-sizeTag-front": typed[i++],
  "440801-frontChest-front": typed[i++],
  "440801-upperFrontLeftChest-front": typed[i++],
  "440801-frontMiddle-front": typed[i++],
  "440801-frontMiddleLeft-front": typed[i++],
  "440801-frontMiddleRight-front": typed[i++],
  "440801-jerseyRunbird-front": typed[i++],
  "440801-upperBack-back": typed[i++],
  "440801-middleBack-back": typed[i++],
  "440801-lowerBack-back": typed[i++],
  "440801-leftSleeve-sleeveLeft": typed[i++],
  "440801-rightSleeve-sleeveRight": typed[i++],
  "440801-sizeTag-front": typed[i++],
  "440803-frontChest-front": typed[i++],
  "440803-upperFrontLeftChest-front": typed[i++],
  "440803-frontMiddle-front": typed[i++],
  "440803-frontMiddleLeft-front": typed[i++],
  "440803-frontMiddleRight-front": typed[i++],
  "440803-jerseyRunbird-front": typed[i++],
  "440803-upperBack-back": typed[i++],
  "440803-middleBack-back": typed[i++],
  "440803-lowerBack-back": typed[i++],
  "440803-leftSleeve-sleeveLeft": typed[i++],
  "440803-rightSleeve-sleeveRight": typed[i++],
  "440803-sizeTag-front": typed[i++],
  "440802-frontChest-front": typed[i++],
  "440802-upperFrontLeftChest-front": typed[i++],
  "440802-frontMiddle-front": typed[i++],
  "440802-frontMiddleLeft-front": typed[i++],
  "440802-frontMiddleRight-front": typed[i++],
  "440802-jerseyRunbird-front": typed[i++],
  "440802-upperBack-back": typed[i++],
  "440802-middleBack-back": typed[i++],
  "440802-lowerBack-back": typed[i++],
  "440802-sizeTag-front": typed[i++],
  "440804-frontChest-front": typed[i++],
  "440804-upperFrontLeftChest-front": typed[i++],
  "440804-frontMiddle-front": typed[i++],
  "440804-frontMiddleLeft-front": typed[i++],
  "440804-frontMiddleRight-front": typed[i++],
  "440804-jerseyRunbird-front": typed[i++],
  "440804-upperBack-back": typed[i++],
  "440804-middleBack-back": typed[i++],
  "440804-lowerBack-back": typed[i++],
  "440804-sizeTag-front": typed[i++]
} as const;

export { typed as ANCHORS };
