import { Recipe } from '../common/typings'
import { SizeId } from '../common/sheets'

export type Player = {
  size: string
  quantity: number
  number: string
  name: string
  extras?: Record<string, string>
}

const getPlayers = (
  recipe: Recipe,
): {
  jerseySize?: SizeId
  pantsSize?: SizeId
  quantity: number
  number: string
  name: string
  extras: Record<string, string>
}[] => {
  const rosterIds = Object.keys(recipe)
    .filter(
      (key) => key.startsWith('details.roster.') && key.endsWith('.quantity'),
    )
    .map((key) => {
      const index = key
        .replace(/^details\.roster\./, '')
        .replace(/\.quantity/, '')

      return parseInt(index, 10)
    })

  return rosterIds.map((rosterId: number) => {
    let quantity = parseInt(recipe[`details.roster.${rosterId}.quantity`], 10)

    return {
      jerseySize: recipe[`details.roster.${rosterId}.jerseySize`],
      pantsSize: recipe[`details.roster.${rosterId}.pantsSize`],
      quantity: Number.isNaN(quantity) ? 0 : quantity,
      number: recipe[`details.roster.${rosterId}.number`],
      name: recipe[`details.roster.${rosterId}.name`],
      extras: {
        year: recipe[`details.roster.${rosterId}.extras.year`] ?? undefined,
      },
    }
  })
}

const getJerseyPlayers = (recipe: Recipe): Player[] =>
  getPlayers(recipe).map(
    ({ jerseySize: size, quantity, number, name, extras }) => ({
      size: size!,
      quantity,
      number,
      name,
      extras,
    }),
  )

const getPantsPlayers = (recipe: Recipe): Player[] =>
  getPlayers(recipe).map(
    ({ pantsSize: size, quantity, number, name, extras }) => ({
      size: size!,
      quantity,
      number,
      name,
      extras,
    }),
  )

export { getPlayers, getJerseyPlayers, getPantsPlayers }
