// DO NOT EDIT, GENERATED BY "yarn sheets"

import type { CornerRow } from '../typings';

const data = [
  {
    "id": "350771-back",
    "productId": "350771",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350773-back",
    "productId": "350773",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350775-back",
    "productId": "350775",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350777-back",
    "productId": "350777",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350785-back",
    "productId": "350785",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "leftNeck"
    ]
  },
  {
    "id": "350786-back",
    "productId": "350786",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350787-back",
    "productId": "350787",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350788-back",
    "productId": "350788",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "leftNeck"
    ]
  },
  {
    "id": "350789-back",
    "productId": "350789",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "leftNeck"
    ]
  },
  {
    "id": "350790-back",
    "productId": "350790",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "leftNeck"
    ]
  },
  {
    "id": "350797-back",
    "productId": "350797",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350798-back",
    "productId": "350798",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350799-back",
    "productId": "350799",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350800-back",
    "productId": "350800",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350801-back",
    "productId": "350801",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350802-back",
    "productId": "350802",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440773-back",
    "productId": "440773",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440775-back",
    "productId": "440775",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440777-back",
    "productId": "440777",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440779-back",
    "productId": "440779",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440781-back",
    "productId": "440781",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440783-back",
    "productId": "440783",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440785-back",
    "productId": "440785",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440797-back",
    "productId": "440797",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440798-back",
    "productId": "440798",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440799-back",
    "productId": "440799",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440800-back",
    "productId": "440800",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440801-back",
    "productId": "440801",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "440802-back",
    "productId": "440802",
    "pieceName": "back",
    "names": [
      "rightNeck",
      "armRightTop",
      "armRightBottom",
      "rightBottom",
      "leftBottom",
      "armLeftBottom",
      "armLeftTop",
      "leftNeck"
    ]
  },
  {
    "id": "350773-front",
    "productId": "350773",
    "pieceName": "front",
    "names": [
      "buttonsLeft",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight",
      "buttonsRight"
    ]
  },
  {
    "id": "350775-front",
    "productId": "350775",
    "pieceName": "front",
    "names": [
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "350777-front",
    "productId": "350777",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "350787-front",
    "productId": "350787",
    "pieceName": "front",
    "names": [
      "buttonsLeft",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight",
      "buttonsRight"
    ]
  },
  {
    "id": "350788-front",
    "productId": "350788",
    "pieceName": "front",
    "names": [
      "buttonsLeft",
      "neckLeft",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "neckRight",
      "buttonsRight"
    ]
  },
  {
    "id": "350789-front",
    "productId": "350789",
    "pieceName": "front",
    "names": [
      "neckLeft",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "neckRight"
    ]
  },
  {
    "id": "350790-front",
    "productId": "350790",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "neckRight"
    ]
  },
  {
    "id": "350798-front",
    "productId": "350798",
    "pieceName": "front",
    "names": [
      "buttonsLeft",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight",
      "buttonsRight"
    ]
  },
  {
    "id": "350799-front",
    "productId": "350799",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "350801-front",
    "productId": "350801",
    "pieceName": "front",
    "names": [
      "buttonsLeft",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight",
      "buttonsRight"
    ]
  },
  {
    "id": "350802-front",
    "productId": "350802",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440773-front",
    "productId": "440773",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440775-front",
    "productId": "440775",
    "pieceName": "front",
    "names": [
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440777-front",
    "productId": "440777",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440779-front",
    "productId": "440779",
    "pieceName": "front",
    "names": [
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440781-front",
    "productId": "440781",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440783-front",
    "productId": "440783",
    "pieceName": "front",
    "names": [
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440785-front",
    "productId": "440785",
    "pieceName": "front",
    "names": [
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440797-front",
    "productId": "440797",
    "pieceName": "front",
    "names": [
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440798-front",
    "productId": "440798",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440799-front",
    "productId": "440799",
    "pieceName": "front",
    "names": [
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440800-front",
    "productId": "440800",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440801-front",
    "productId": "440801",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "440802-front",
    "productId": "440802",
    "pieceName": "front",
    "names": [
      "middleTop",
      "neckLeft",
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop",
      "neckRight"
    ]
  },
  {
    "id": "350771-frontLeft",
    "productId": "350771",
    "pieceName": "frontLeft",
    "names": [
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "neck"
    ]
  },
  {
    "id": "350785-frontLeft",
    "productId": "350785",
    "pieceName": "frontLeft",
    "names": [
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "neck"
    ]
  },
  {
    "id": "350786-frontLeft",
    "productId": "350786",
    "pieceName": "frontLeft",
    "names": [
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "neck"
    ]
  },
  {
    "id": "350797-frontLeft",
    "productId": "350797",
    "pieceName": "frontLeft",
    "names": [
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "neck"
    ]
  },
  {
    "id": "350800-frontLeft",
    "productId": "350800",
    "pieceName": "frontLeft",
    "names": [
      "armLeftTop",
      "armLeftBottom",
      "leftBottom",
      "rightBottom",
      "neck"
    ]
  },
  {
    "id": "350771-frontRight",
    "productId": "350771",
    "pieceName": "frontRight",
    "names": [
      "neck",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop"
    ]
  },
  {
    "id": "350785-frontRight",
    "productId": "350785",
    "pieceName": "frontRight",
    "names": [
      "neck",
      "leftBottom",
      "rightBottom",
      "armRightBottom"
    ]
  },
  {
    "id": "350786-frontRight",
    "productId": "350786",
    "pieceName": "frontRight",
    "names": [
      "neck",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop"
    ]
  },
  {
    "id": "350797-frontRight",
    "productId": "350797",
    "pieceName": "frontRight",
    "names": [
      "neck",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop"
    ]
  },
  {
    "id": "350800-frontRight",
    "productId": "350800",
    "pieceName": "frontRight",
    "names": [
      "neck",
      "leftBottom",
      "rightBottom",
      "armRightBottom",
      "armRightTop"
    ]
  },
  {
    "id": "350771-sleeveLeft",
    "productId": "350771",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350773-sleeveLeft",
    "productId": "350773",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350775-sleeveLeft",
    "productId": "350775",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350777-sleeveLeft",
    "productId": "350777",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350785-sleeveLeft",
    "productId": "350785",
    "pieceName": "sleeveLeft",
    "names": [
      "neckRight",
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      "neckLeft"
    ]
  },
  {
    "id": "350788-sleeveLeft",
    "productId": "350788",
    "pieceName": "sleeveLeft",
    "names": [
      "neckRight",
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      "neckLeft"
    ]
  },
  {
    "id": "350789-sleeveLeft",
    "productId": "350789",
    "pieceName": "sleeveLeft",
    "names": [
      "neckRight",
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      "neckLeft"
    ]
  },
  {
    "id": "350790-sleeveLeft",
    "productId": "350790",
    "pieceName": "sleeveLeft",
    "names": [
      "neckRight",
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      "neckLeft"
    ]
  },
  {
    "id": "350797-sleeveLeft",
    "productId": "350797",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350798-sleeveLeft",
    "productId": "350798",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350799-sleeveLeft",
    "productId": "350799",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440773-sleeveLeft",
    "productId": "440773",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440775-sleeveLeft",
    "productId": "440775",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440777-sleeveLeft",
    "productId": "440777",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440779-sleeveLeft",
    "productId": "440779",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440783-sleeveLeft",
    "productId": "440783",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440797-sleeveLeft",
    "productId": "440797",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440798-sleeveLeft",
    "productId": "440798",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440799-sleeveLeft",
    "productId": "440799",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440800-sleeveLeft",
    "productId": "440800",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440801-sleeveLeft",
    "productId": "440801",
    "pieceName": "sleeveLeft",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350771-sleeveRight",
    "productId": "350771",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350773-sleeveRight",
    "productId": "350773",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350775-sleeveRight",
    "productId": "350775",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350777-sleeveRight",
    "productId": "350777",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350785-sleeveRight",
    "productId": "350785",
    "pieceName": "sleeveRight",
    "names": [
      "neckRight",
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      "neckLeft"
    ]
  },
  {
    "id": "350788-sleeveRight",
    "productId": "350788",
    "pieceName": "sleeveRight",
    "names": [
      "neckRight",
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      "neckLeft"
    ]
  },
  {
    "id": "350789-sleeveRight",
    "productId": "350789",
    "pieceName": "sleeveRight",
    "names": [
      "neckRight",
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      "neckLeft"
    ]
  },
  {
    "id": "350790-sleeveRight",
    "productId": "350790",
    "pieceName": "sleeveRight",
    "names": [
      "neckRight",
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      "neckLeft"
    ]
  },
  {
    "id": "350797-sleeveRight",
    "productId": "350797",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350798-sleeveRight",
    "productId": "350798",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350799-sleeveRight",
    "productId": "350799",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440773-sleeveRight",
    "productId": "440773",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440775-sleeveRight",
    "productId": "440775",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440777-sleeveRight",
    "productId": "440777",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440779-sleeveRight",
    "productId": "440779",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440783-sleeveRight",
    "productId": "440783",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440797-sleeveRight",
    "productId": "440797",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440798-sleeveRight",
    "productId": "440798",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440799-sleeveRight",
    "productId": "440799",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440800-sleeveRight",
    "productId": "440800",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440801-sleeveRight",
    "productId": "440801",
    "pieceName": "sleeveRight",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350775-backCollar",
    "productId": "350775",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350777-backCollar",
    "productId": "350777",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350789-backCollar",
    "productId": "350789",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350790-backCollar",
    "productId": "350790",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350799-backCollar",
    "productId": "350799",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350802-backCollar",
    "productId": "350802",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440773-backCollar",
    "productId": "440773",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      null,
      null
    ]
  },
  {
    "id": "440775-backCollar",
    "productId": "440775",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440777-backCollar",
    "productId": "440777",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      null,
      null
    ]
  },
  {
    "id": "440779-backCollar",
    "productId": "440779",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440781-backCollar",
    "productId": "440781",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      null,
      null
    ]
  },
  {
    "id": "440783-backCollar",
    "productId": "440783",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440785-backCollar",
    "productId": "440785",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440797-backCollar",
    "productId": "440797",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440798-backCollar",
    "productId": "440798",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      null,
      null
    ]
  },
  {
    "id": "440799-backCollar",
    "productId": "440799",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "440800-backCollar",
    "productId": "440800",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      null,
      null
    ]
  },
  {
    "id": "440801-backCollar",
    "productId": "440801",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      null,
      null
    ]
  },
  {
    "id": "440802-backCollar",
    "productId": "440802",
    "pieceName": "backCollar",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "leftTop",
      null,
      null
    ]
  },
  {
    "id": "350843-pLeftBack",
    "productId": "350843",
    "pieceName": "pLeftBack",
    "names": [
      "riseTop",
      "riseBottom",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350845-pLeftBack",
    "productId": "350845",
    "pieceName": "pLeftBack",
    "names": [
      "riseTop",
      "riseBottom",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350847-pLeftBack",
    "productId": "350847",
    "pieceName": "pLeftBack",
    "names": [
      "riseTop",
      "riseBottom",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "351079-pLeftBack",
    "productId": "351079",
    "pieceName": "pLeftBack",
    "names": [
      "riseTop",
      "riseBottom",
      "rightBottom",
      "leftBottom",
      "leftTop"
    ]
  },
  {
    "id": "350843-pRightBack",
    "productId": "350843",
    "pieceName": "pRightBack",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "riseBottom",
      "riseTop"
    ]
  },
  {
    "id": "350845-pRightBack",
    "productId": "350845",
    "pieceName": "pRightBack",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "riseBottom",
      "riseTop"
    ]
  },
  {
    "id": "350847-pRightBack",
    "productId": "350847",
    "pieceName": "pRightBack",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "riseBottom",
      "riseTop"
    ]
  },
  {
    "id": "351079-pRightBack",
    "productId": "351079",
    "pieceName": "pRightBack",
    "names": [
      "rightTop",
      "rightBottom",
      "leftBottom",
      "riseBottom",
      "riseTop"
    ]
  },
  {
    "id": "350843-pLeftFront",
    "productId": "350843",
    "pieceName": "pLeftFront",
    "names": [
      "leftTop",
      "leftBottom",
      "rightBottom",
      "riseBottom",
      "riseTop"
    ]
  },
  {
    "id": "350845-pLeftFront",
    "productId": "350845",
    "pieceName": "pLeftFront",
    "names": [
      "leftTop",
      "leftBottom",
      "rightBottom",
      "riseBottom",
      "riseTop"
    ]
  },
  {
    "id": "350847-pLeftFront",
    "productId": "350847",
    "pieceName": "pLeftFront",
    "names": [
      "leftTop",
      "leftBottom",
      "rightBottom",
      "riseBottom",
      "riseTop"
    ]
  },
  {
    "id": "351079-pLeftFront",
    "productId": "351079",
    "pieceName": "pLeftFront",
    "names": [
      "leftTop",
      "leftBottom",
      "rightBottom",
      "riseBottom",
      "riseTop"
    ]
  },
  {
    "id": "350843-pRightFront",
    "productId": "350843",
    "pieceName": "pRightFront",
    "names": [
      "riseTop",
      null,
      null,
      "riseBottom",
      "leftBottom",
      "rightBottom",
      "rightTop"
    ]
  },
  {
    "id": "350845-pRightFront",
    "productId": "350845",
    "pieceName": "pRightFront",
    "names": [
      "riseTop",
      null,
      null,
      "riseBottom",
      "leftBottom",
      "rightBottom",
      "rightTop"
    ]
  },
  {
    "id": "350847-pRightFront",
    "productId": "350847",
    "pieceName": "pRightFront",
    "names": [
      "riseTop",
      null,
      null,
      "riseBottom",
      "leftBottom",
      "rightBottom",
      "rightTop"
    ]
  },
  {
    "id": "351079-pRightFront",
    "productId": "351079",
    "pieceName": "pRightFront",
    "names": [
      "riseTop",
      "riseBottom",
      "leftBottom",
      "rightBottom",
      "rightTop"
    ]
  }
] as const;

type Primitive = string | number | boolean | null | undefined;
type Immutable<T> = T extends Primitive
  ? T
  : { readonly [K in keyof T]: Immutable<T[K]> };

type Combine<T extends U, U> = T extends Readonly<Primitive | Primitive[]>
  ? T
  : T extends ReadonlyArray<infer I>
  ? Readonly<Combine<I, Extract<U, Readonly<any[]>>[number]>[]>
  : U extends Readonly<Primitive | any[]>
  ? never
  : string extends keyof U
  ? { [K in keyof T & string]: Combine<T[K], U[K]> }
  : number extends keyof U
  ? { [K in keyof T & number]: Combine<T[K], U[K]> }
  : symbol extends keyof U
  ? { [K in keyof T & symbol]: Combine<T[K], U[K]> }
  : { [K in keyof U]: Combine<T[K], U[K]> };

const typed: Combine<typeof data, Immutable<CornerRow[]>> = data;

export type Corner = typeof typed[number];

export const CORNER_INDEX_KEY = "id";
export type CornerIndexKey = "id";
export type CornerId = Corner["id"];

let i = 0;
export const CORNER_DICT = {
  "350771-back": typed[i++],
  "350773-back": typed[i++],
  "350775-back": typed[i++],
  "350777-back": typed[i++],
  "350785-back": typed[i++],
  "350786-back": typed[i++],
  "350787-back": typed[i++],
  "350788-back": typed[i++],
  "350789-back": typed[i++],
  "350790-back": typed[i++],
  "350797-back": typed[i++],
  "350798-back": typed[i++],
  "350799-back": typed[i++],
  "350800-back": typed[i++],
  "350801-back": typed[i++],
  "350802-back": typed[i++],
  "440773-back": typed[i++],
  "440775-back": typed[i++],
  "440777-back": typed[i++],
  "440779-back": typed[i++],
  "440781-back": typed[i++],
  "440783-back": typed[i++],
  "440785-back": typed[i++],
  "440797-back": typed[i++],
  "440798-back": typed[i++],
  "440799-back": typed[i++],
  "440800-back": typed[i++],
  "440801-back": typed[i++],
  "440802-back": typed[i++],
  "350773-front": typed[i++],
  "350775-front": typed[i++],
  "350777-front": typed[i++],
  "350787-front": typed[i++],
  "350788-front": typed[i++],
  "350789-front": typed[i++],
  "350790-front": typed[i++],
  "350798-front": typed[i++],
  "350799-front": typed[i++],
  "350801-front": typed[i++],
  "350802-front": typed[i++],
  "440773-front": typed[i++],
  "440775-front": typed[i++],
  "440777-front": typed[i++],
  "440779-front": typed[i++],
  "440781-front": typed[i++],
  "440783-front": typed[i++],
  "440785-front": typed[i++],
  "440797-front": typed[i++],
  "440798-front": typed[i++],
  "440799-front": typed[i++],
  "440800-front": typed[i++],
  "440801-front": typed[i++],
  "440802-front": typed[i++],
  "350771-frontLeft": typed[i++],
  "350785-frontLeft": typed[i++],
  "350786-frontLeft": typed[i++],
  "350797-frontLeft": typed[i++],
  "350800-frontLeft": typed[i++],
  "350771-frontRight": typed[i++],
  "350785-frontRight": typed[i++],
  "350786-frontRight": typed[i++],
  "350797-frontRight": typed[i++],
  "350800-frontRight": typed[i++],
  "350771-sleeveLeft": typed[i++],
  "350773-sleeveLeft": typed[i++],
  "350775-sleeveLeft": typed[i++],
  "350777-sleeveLeft": typed[i++],
  "350785-sleeveLeft": typed[i++],
  "350788-sleeveLeft": typed[i++],
  "350789-sleeveLeft": typed[i++],
  "350790-sleeveLeft": typed[i++],
  "350797-sleeveLeft": typed[i++],
  "350798-sleeveLeft": typed[i++],
  "350799-sleeveLeft": typed[i++],
  "440773-sleeveLeft": typed[i++],
  "440775-sleeveLeft": typed[i++],
  "440777-sleeveLeft": typed[i++],
  "440779-sleeveLeft": typed[i++],
  "440783-sleeveLeft": typed[i++],
  "440797-sleeveLeft": typed[i++],
  "440798-sleeveLeft": typed[i++],
  "440799-sleeveLeft": typed[i++],
  "440800-sleeveLeft": typed[i++],
  "440801-sleeveLeft": typed[i++],
  "350771-sleeveRight": typed[i++],
  "350773-sleeveRight": typed[i++],
  "350775-sleeveRight": typed[i++],
  "350777-sleeveRight": typed[i++],
  "350785-sleeveRight": typed[i++],
  "350788-sleeveRight": typed[i++],
  "350789-sleeveRight": typed[i++],
  "350790-sleeveRight": typed[i++],
  "350797-sleeveRight": typed[i++],
  "350798-sleeveRight": typed[i++],
  "350799-sleeveRight": typed[i++],
  "440773-sleeveRight": typed[i++],
  "440775-sleeveRight": typed[i++],
  "440777-sleeveRight": typed[i++],
  "440779-sleeveRight": typed[i++],
  "440783-sleeveRight": typed[i++],
  "440797-sleeveRight": typed[i++],
  "440798-sleeveRight": typed[i++],
  "440799-sleeveRight": typed[i++],
  "440800-sleeveRight": typed[i++],
  "440801-sleeveRight": typed[i++],
  "350775-backCollar": typed[i++],
  "350777-backCollar": typed[i++],
  "350789-backCollar": typed[i++],
  "350790-backCollar": typed[i++],
  "350799-backCollar": typed[i++],
  "350802-backCollar": typed[i++],
  "440773-backCollar": typed[i++],
  "440775-backCollar": typed[i++],
  "440777-backCollar": typed[i++],
  "440779-backCollar": typed[i++],
  "440781-backCollar": typed[i++],
  "440783-backCollar": typed[i++],
  "440785-backCollar": typed[i++],
  "440797-backCollar": typed[i++],
  "440798-backCollar": typed[i++],
  "440799-backCollar": typed[i++],
  "440800-backCollar": typed[i++],
  "440801-backCollar": typed[i++],
  "440802-backCollar": typed[i++],
  "350843-pLeftBack": typed[i++],
  "350845-pLeftBack": typed[i++],
  "350847-pLeftBack": typed[i++],
  "351079-pLeftBack": typed[i++],
  "350843-pRightBack": typed[i++],
  "350845-pRightBack": typed[i++],
  "350847-pRightBack": typed[i++],
  "351079-pRightBack": typed[i++],
  "350843-pLeftFront": typed[i++],
  "350845-pLeftFront": typed[i++],
  "350847-pLeftFront": typed[i++],
  "351079-pLeftFront": typed[i++],
  "350843-pRightFront": typed[i++],
  "350845-pRightFront": typed[i++],
  "350847-pRightFront": typed[i++],
  "351079-pRightFront": typed[i++]
} as const;

export { typed as CORNERS };
