const DEFAULT_FABRIC = 'P-2027' as const
const DEFAULT_SIZE = 'M' as const

const getDefaultFabric = (product, recipe) => {
  const prefix = product.props.type
  return product.props.sport === 'volleyball' ?
      recipe[`${prefix}.fabric`]
    : DEFAULT_FABRIC
}

export { DEFAULT_FABRIC, DEFAULT_SIZE, getDefaultFabric }
