import * as _ from '@technically/lodash'
import FileSaver from 'file-saver'
import JSZip from 'jszip'
import { s } from 'hastscript'

import { getPatternName } from '@orangelv/mizuno-utils'
import { SvgData, svgToString, svgTranslate } from '@orangelv/utils-svg'
import { Vector2 } from '@orangelv/utils-geometry'

import assert from '../../../platform/assert'

import { getDefaultFabric } from './defaults'
import store from '../client/store'
import {
  PRODUCTS,
  SIZES,
  SIZE_DICT,
  FULL_CUSTOM_PIECE_DATA_DICT,
  Size,
} from '../common/sheets'
import { PATTERN_PACKAGE } from '../consts'
import { loadPattern } from '../../../platform/client/svg-renderer-utils'
import { getSize } from '../client/svg-renderer-utils'
import controlTree from '../client/controlTree'

const PADDING_X = 200
const PADDING_Y = 200
const INFO_HEIGHT = 292 + PADDING_Y
const FONT_SIZE = 24
const ILLUSTRATOR_MAX_CANVAS_SIZE = { width: 16_383, height: 16_383 }

const outputType: unknown | 'zip' | 'browser' = 'zip'

type PieceInfoByProduct = Record<
  string,
  Record<string, Record<string, Awaited<ReturnType<typeof getSize>>>>
>

type PieceOffsets = Record<string, Record<string, Vector2>>

type Output = {
  offsets: PieceOffsets
  svgs: Record<string, string>
}

const allPieceNamesSorted: string[] = [
  'front',
  'frontRight',
  'frontLeft',
  'back',
  'sleeveRight',
  'sleeveLeft',
  'backCollar',
  'backCollar1',
  'backCollar2',
  'frontCollarRight',
  'frontCollarLeft',
  'frontCollarRight1',
  'frontCollarLeft1',
  'frontCollarRight2',
  'frontCollarLeft2',
  'pRightFront',
  'pLeftFront',
  'pRightBack',
  'pLeftBack',
  'pRightPocket',
  'pLeftPocket',
  'pFrontLoop1',
  'pFrontLoop2',
  'pBackLoop',
]

const allPieceNameRows: string[][] = [
  ['front', 'back'],
  ['frontRight', 'frontLeft'],
  ['sleeveRight', 'sleeveLeft'],
  ['backCollar1', 'backCollar2'],
  ['frontCollarRight', 'frontCollarLeft'],
  ['frontCollarRight1', 'frontCollarLeft1'],
  ['frontCollarRight2', 'frontCollarLeft2'],
  ['pRightFront', 'pLeftFront'],
  ['pRightBack', 'pLeftBack'],
  ['pRightPocket', 'pLeftPocket'],
  ['pFrontLoop1', 'pFrontLoop2'],
]

const pointsToPathD = (points: Vector2[]) =>
  'M ' + points.map(({ x, y }) => `${x} ${y}`).join(' ') + ' Z'

const outputZip = async (output: Output) => {
  const zip = new JSZip()

  for (const productId of Object.keys(output.svgs)) {
    zip.file(`${productId}-template.svg`, output.svgs[productId])
  }

  zip.file('offsets.json', JSON.stringify(output.offsets, null, 2))

  const content = await zip.generateAsync({ type: 'blob' })
  FileSaver.saveAs(content, 'full-custom-templates.zip')
}

const outputBrowser = (output: Output) => {
  for (const productId of Object.keys(output.svgs)) {
    const debugWindow = window.open()

    if (!debugWindow) {
      console.log(
        'ERROR: Cannot open a new tab! Make sure you have allowed pop-up windows in browser settings.',
      )
      return
    }

    const debugDocument = debugWindow.document

    debugDocument.write(`<title>${productId} Full Custom Template</title>`)
    debugDocument.write(output.svgs[productId])
  }
}

const strokeAttrs = {
  stroke: 'black',
  strokeWidth: '4',
  strokeLinecap: 'round',
  strokeDasharray: '5,5',
}

const getStrokeAttrs = (size: Size, largestSize: Size) => {
  const isLargest = size.id === largestSize.id

  const attrs = { ...strokeAttrs }

  if (isLargest) {
    attrs.stroke = 'red'
  }

  if (size.id === 'M') {
    attrs.stroke = 'green'
  }

  return attrs
}

const getHiddenId = (() => {
  let index = 0
  return () => {
    const id = `hidden${index}`
    index += 1
    return id
  }
})()

const generateFullCustomTemplates = async () => {
  console.time('generateFullCustomTemplates')

  const availableProducts = PRODUCTS.filter(
    (x) =>
      x.props.isEnabled &&
      FULL_CUSTOM_PIECE_DATA_DICT[x.id] &&
      !FULL_CUSTOM_PIECE_DATA_DICT[x.id].isDisabled,
  )

  const output: Output = {
    svgs: {},
    offsets: {},
  }

  const pieceInfoByProduct: PieceInfoByProduct = {}
  const pieceOffsets: PieceOffsets = {}

  for (const product of availableProducts) {
    let pieceNames: string[] | undefined

    const largestSize = _.last(
      _.filter(SIZES, (x) => !x.isYouth && x.availableFor.product[product.id]),
    )!

    const sizes = _.filter(
      SIZES,
      (size) =>
        size.availableFor.product[product.id] &&
        _.includes([largestSize.id, 'M'], size.id),
    )

    const currentProductPieceInfo = {}
    pieceInfoByProduct[product.id] = currentProductPieceInfo

    const state = store.getState()
    const recipe = controlTree.getPublicRecipe(state)
    const fabricId = getDefaultFabric(product, recipe)

    for (const size of sizes) {
      const sizePieceInfo = {}
      currentProductPieceInfo[size.id] = sizePieceInfo
      const patternFamilyName = size.isYouth ? product.youthSku : product.id
      if (patternFamilyName === undefined) throw new Error('You SKU missing')
      const patternName = getPatternName(patternFamilyName, fabricId)
      const pattern = await loadPattern(PATTERN_PACKAGE, patternName)
      if (!pieceNames) {
        pieceNames = _.filter(allPieceNamesSorted, (pieceName) =>
          pattern.pieceMapping.some((x) => x.name === pieceName),
        )
      }

      for (const pieceName of pieceNames) {
        const pieceMappingItem = pattern.pieceMapping.find(
          (x) => x.name === pieceName,
        )
        if (!pieceMappingItem) continue
        if (!pattern.sizeNames.includes(size.id)) continue
        sizePieceInfo[pieceName] = await getSize(
          PATTERN_PACKAGE,
          pattern,
          patternName,
          pieceMappingItem,
          size.id,
        )
      }
    }

    assert(pieceNames, 'Piece names not defined!')

    const pieceInfo = pieceInfoByProduct[product.id]?.[largestSize.id]

    assert(pieceInfo, 'Piece info not found!')

    pieceOffsets[product.id] = {}
    let documentWidth = 0
    let documentHeight = 0
    let workingPoint = { x: 0, y: INFO_HEIGHT }
    let rowHeight = 0
    let i = 0
    for (const pieceName of pieceNames) {
      const sizeWithMetrics = pieceInfo[pieceName]

      assert(sizeWithMetrics, 'Piece size not found!')

      const isRowContinuing = _.some(allPieceNameRows, (pieceNamesInRow) => {
        const index = pieceNamesInRow.indexOf(pieceName)
        return index !== -1 && index !== pieceNamesInRow.length - 1
      })

      workingPoint.x += PADDING_X
      workingPoint.y += PADDING_Y

      documentWidth = Math.max(
        documentWidth,
        workingPoint.x + sizeWithMetrics.cutLineBox.width + PADDING_X,
      )

      rowHeight = Math.max(rowHeight, sizeWithMetrics.cutLineBox.height)

      pieceOffsets[product.id]![pieceName] = {
        x: workingPoint.x + sizeWithMetrics.cutLineBox.width / 2,
        y: workingPoint.y + sizeWithMetrics.cutLineBox.height / 2,
      }

      if (isRowContinuing) {
        workingPoint.x += sizeWithMetrics.cutLineBox.width
        workingPoint.y -= PADDING_Y
      } else {
        workingPoint.x = 0
        workingPoint.y += rowHeight
        rowHeight = 0
      }

      const isLast = i + 1 === pieceNames.length

      if (isLast) {
        workingPoint.y += PADDING_Y
        documentHeight = workingPoint.y
      }

      i += 1
    }

    documentWidth = Math.round(documentWidth)
    documentHeight = Math.round(documentHeight)

    if (
      documentWidth > ILLUSTRATOR_MAX_CANVAS_SIZE.width ||
      documentHeight > ILLUSTRATOR_MAX_CANVAS_SIZE.height
    ) {
      console.log(
        `WARN: Document size (${documentWidth}x${documentHeight}) is larger than Illustrator max canvas size!`,
      )
    }

    for (const pieceOffset of Object.values(pieceOffsets[product.id]!)) {
      pieceOffset.x = Math.round(pieceOffset.x - documentWidth / 2)
      pieceOffset.y = Math.round(pieceOffset.y - documentHeight / 2)
    }

    output.offsets[product.id] = pieceOffsets[product.id]

    const children: ReturnType<typeof s>[] = []

    const textAttrs = {
      fill: 'black',
      style: `font: bold ${FONT_SIZE}px sans-serif; text-shadow: white 0 0 5px;`,
    }

    children.push(
      s(
        'g',
        {
          transform: svgTranslate(PADDING_X, PADDING_Y),
          id: getHiddenId(),
        },
        s('text', textAttrs, [
          s('tspan', { x: 0, dy: '0.8em' }, [
            s('tspan', {}, 'This is '),
            s(
              'a',
              {
                fontWeight: 'bolder',
                href: `https://uniforms.mizunocustom.com/sku/${product.id}`,
                target: '_blank',
              },
              product.id,
            ),
            s('tspan', {}, ' full custom template.'),
          ]),
          s('tspan', { x: 0, dy: '1.6em' }, ' '),
          s(
            'tspan',
            { x: 0, dy: '1.6em' },
            'Place your design files or draw anything onto the piece boxes.',
          ),
          s('tspan', { x: 0, dy: '1.6em' }, [
            s('tspan', {}, 'Colored lines indicate approximate shape of '),
            s('tspan', { fill: 'red' }, largestSize.name),
            s('tspan', {}, ' and '),
            s('tspan', { fill: 'green' }, 'M'),
            s('tspan', {}, ' size apparel.'),
          ]),
          s(
            'tspan',
            { x: 0, dy: '1.6em' },
            'Export as SVG file and upload it back to the customizer to see the design in 3D.',
          ),
          s('tspan', { x: 0, dy: '1.6em' }, ' '),
          s('tspan', { x: 0, dy: '1.6em' }, `Pieces: ${pieceNames.join(', ')}`),
        ]),
      ),
    )

    for (const pieceName of pieceNames) {
      const pieceChildren: ReturnType<typeof s>[] = []

      for (const sizeId of Object.keys(pieceInfoByProduct[product.id])) {
        const sizeWithMetrics =
          pieceInfoByProduct[product.id]![sizeId][pieceName]

        const offsetX = documentWidth / 2 - sizeWithMetrics.cutLineBox.width / 2
        const offsetY =
          documentHeight / 2 - sizeWithMetrics.cutLineBox.height / 2

        if (sizeId === largestSize.id) {
          pieceChildren.push(
            s('rect', {
              width: sizeWithMetrics.cutLineBox.width,
              height: sizeWithMetrics.cutLineBox.height,
              transform: svgTranslate(offsetX, offsetY),
              id: getHiddenId(),
              fill: 'none',
              ...strokeAttrs,
              strokeWidth: '4',
            }),
          )
        }

        pieceChildren.push(
          s('path', {
            d: pointsToPathD(sizeWithMetrics.cutLine),
            transform: svgTranslate(
              offsetX - sizeWithMetrics.cutLineBox.x,
              offsetY - sizeWithMetrics.cutLineBox.y,
            ),
            id: getHiddenId(),
            fill: 'none',
            ...getStrokeAttrs(SIZE_DICT[sizeId], largestSize),
          }),
        )
      }

      const largestPiece = pieceInfo[pieceName]

      assert(largestPiece, 'Largest piece not found!')

      pieceChildren.push(
        s(
          'g',
          {
            transform: svgTranslate(
              documentWidth / 2 - largestPiece.cutLineBox.width / 2,
              documentHeight / 2 -
                largestPiece.cutLineBox.height / 2 -
                FONT_SIZE,
            ),
            id: getHiddenId(),
          },
          s('text', textAttrs, [
            s('tspan', {}, 'Piece: '),
            s(
              'tspan',
              {
                fontWeight: 'bolder',
              },
              pieceName,
            ),
          ]),
        ),
      )

      const pieceOffset = pieceOffsets[product.id]?.[pieceName]

      assert(pieceOffset, 'Piece offset not found!')

      children.push(
        s(
          'g',
          {
            transform: svgTranslate(pieceOffset.x, pieceOffset.y),
          },
          pieceChildren,
        ),
      )
    }

    const svgData: SvgData = {
      name: product.id,
      width: documentWidth,
      height: documentHeight,
      root: {
        type: 'root',
        children: [
          s(
            'svg',
            {
              xmlns: 'http://www.w3.org/2000/svg',
              'xmlns:xlink': 'http://www.w3.org/1999/xlink',
              width: `${documentWidth}px`,
              height: `${documentHeight}px`,
            },
            [s('g', { id: 'full-custom-template' }, children)],
          ),
        ],
      },
    }

    const svgString = svgToString(svgData)

    output.svgs[product.id] = svgString
  }

  if (outputType === 'zip') {
    outputZip(output)
  } else if (outputType === 'browser') {
    outputBrowser(output)
  }

  console.timeEnd('generateFullCustomTemplates')
}

export default generateFullCustomTemplates
