import { fabric } from 'fabric'

import {
  renderText,
  Element,
  getBoundingBoxNumbers,
  anchorThing,
} from '@orangelv/svg-renderer'
import { SvgData, colorizeSvg, svgToString } from '@orangelv/utils-svg'
import { clamp, createExprEval } from '@orangelv/utils'
import { round } from '@orangelv/utils-arithmetic'
import { in2px, isNegligible, px2in, Vector2 } from '@orangelv/utils-geometry'
import { getSizeIndex } from '@orangelv/mizuno-utils'
import { Pattern, PieceMappingItem } from '@orangelv/utils-olvpf'
import { Player } from '@orangelv/contracts'
import { svgToCanvas } from '@orangelv/utils-dom'

import { DEFAULT_SIZE } from './defaults'
import { Recipe } from '../common/typings'
import {
  PlacementId,
  FreeFormPieceData,
  PLACEMENT_DICT,
  Product,
} from '../common/sheets'
import createCanvasTexture from './createCanvasTexture'
import store from '../client/store'
import controlTree from '../client/controlTree'
import { loadFont, loadSvg } from '../../../platform/client/svg-renderer-utils'
import { PATTERN_PACKAGE } from '../consts'
import { Placement } from './getPlacements'
import { getCornerNames } from '../common/svg-renderer-utils'
import { GetFabric, MaterialPropertyFabric } from '@orangelv/bjs-renderer'
import { getSize } from '../client/svg-renderer-utils'

const LABEL_PADDING = 15

type Position = {
  x: number
  y: number
  w: number
  h: number
}

type Meta = {
  placementId?: PlacementId
  placementIdForLabel?: PlacementId
  isBoundingBox?: true
  pieceName?: string
}

type FabricCanvas = fabric.Canvas & {
  meta?: Meta
}

type FabricObject = fabric.Object & {
  width: number
  height: number
  left: number
  top: number
  scaleX: number
  scaleY: number
  meta?: Meta
}

type FabricText = fabric.Text & FabricObject

let activeSelectionPlacementId: string | undefined

const svgDataToFabricObject = (svgData: SvgData, asImage = false) =>
  new Promise<FabricObject>(async (resolve) => {
    if (asImage) {
      const imageCanvas = await svgToCanvas(svgData)

      const image = new fabric.Image(imageCanvas, {}) as FabricObject

      resolve(image)
    } else {
      fabric.loadSVGFromString(svgToString(svgData), (objects, options) => {
        const group = fabric.util.groupSVGElements(
          objects,
          options,
        ) as FabricObject
        resolve(group)
      })
    }
  })

/**
 * Gets left, top, width & height in pixels for text or image element with respect to element anchor position and bounding box.
 * @param pieceName - Piece name
 * @param anchors - Anchor data for specific piece, should be memoized
 * @param size - Apparel size
 * @param element - Element
 * @param svgData - SVG data
 * @alpha
 */
function getElementPosition(
  pieceName: string,
  anchors: Record<string, Vector2>,
  size: string,
  element: Element,
  svgData: SvgData,
): { left: number; top: number; width: number; height: number } {
  if (svgData.width === 0 && svgData.height === 0) {
    return {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    }
  }

  const exprEval = createExprEval({
    in2px,
    anchors,
    size,
    sizeIndex: getSizeIndex(size),
    piece: pieceName,
  })

  const boundingBox =
    element.boundingBox ?
      getBoundingBoxNumbers(exprEval, element.boundingBox)
    : svgData
  const scaleFactor = element.scaleFactor ?? 1

  const scalingRatio =
    Math.min(
      boundingBox.width / svgData.width,
      boundingBox.height / svgData.height,
    ) * scaleFactor

  const scalingOffset = {
    x: (boundingBox.width - svgData.width * scalingRatio) / 2,
    y: (boundingBox.height - svgData.height * scalingRatio) / 2,
  }

  const offset = anchorThing(anchors, boundingBox, element.anchor)

  const elementOffsetX = exprEval(String(element.anchor.offset.x))
  const elementOffsetY = exprEval(String(element.anchor.offset.y))
  if (
    typeof elementOffsetX !== 'number' ||
    typeof elementOffsetY !== 'number'
  ) {
    throw new TypeError('Bad anchor offset expression')
  }

  // TODO: Take into account rotation.
  const left = offset.x + elementOffsetX + scalingOffset.x
  const top = offset.y + elementOffsetY + scalingOffset.y
  const width = boundingBox.width - scalingOffset.x * 2
  const height = boundingBox.height - scalingOffset.y * 2

  return {
    left,
    top,
    width,
    height,
  }
}

async function placementToSvgData(
  { type, element }: Placement,
  exprEval: ReturnType<typeof createExprEval>,
) {
  if (type === 'image') {
    return {
      asImage: false,
      svgData: colorizeSvg(
        await loadSvg(String(exprEval(element.svg.name))),
        element.svg.colors,
      ),
    }
  }

  const { color, tail } = element
  const isColorFill = typeof color === 'string'
  const hasTail = tail !== undefined
  const fill =
    isColorFill ? color : colorizeSvg(await loadSvg(color.name), color.colors)

  return {
    // Fabric doesn't support SVG patterns nor transform for nested paths so we
    // convert SVG to a PNG and use that.
    asImage: !isColorFill || hasTail,
    svgData: renderText(
      await loadFont(element.font),
      String(exprEval(element.text)),
      {
        size: element.size,
        fill,
        outlineColor1: element.outlineColor1,
        outlineColor2: element.outlineColor2,
        letterSpacing: element.letterSpacing,
        layout: element.layout,
        boxSizing: 'border-box',
        tail: element.tail && {
          svg: await loadSvg(element.tail.name),
          isConnected: element.tail.isConnected,
          ...(element.tail.text ?
            ({
              text: element.tail.text,
              textFont: await loadFont(element.tail.textFont),
              textColor: element.tail.textColor,
            } as any) // TODO: TypeScript not strict enough?
          : undefined),
        },
      },
    ),
  }
}

const createFabricTexture = async ({
  pieceMappingItem,
  player,
  placements,
  recipe,
  freeFormPieceDatum,
  pattern,
  patternName,
  product,
}: {
  pieceMappingItem: PieceMappingItem
  player: Player
  placements: Placement[]
  recipe: Recipe
  freeFormPieceDatum: FreeFormPieceData
  pattern: Pattern
  patternName: string
  product: Product
}): Promise<MaterialPropertyFabric> => {
  const pieceName = pieceMappingItem.name

  const canvasTexture = await createCanvasTexture({
    product,
    pattern,
    patternName,
    pieceMappingItem,
    recipe,
    player,
    // Omit placements as they are handled by Fabric
  })

  const getFabric: GetFabric = async (
    fabricCanvas: FabricCanvas,
    isInit,
    getFabrics,
  ) => {
    const boundingBox = freeFormPieceDatum.adult
    const offsetX =
      (freeFormPieceDatum.offsetX &&
        freeFormPieceDatum.offsetX[DEFAULT_SIZE]) ??
      0

    const { cutLineBox } = await getSize(
      PATTERN_PACKAGE,
      pattern,
      patternName,
      pieceMappingItem,
      DEFAULT_SIZE,
      getCornerNames(product.id, pieceName),
    )

    const dispatchToControlTree = (
      basePath: string,
      position: Position,
      isTouched = true,
    ) => {
      const nextPosition = { ...position }

      if (pieceName === 'frontRight') {
        nextPosition.x -= offsetX
      }

      store.dispatch(
        controlTree.change(`${basePath}.position`, {
          x: round(nextPosition.x, 2),
          y: round(nextPosition.y, 2),
          w: round(nextPosition.w, 2),
          h: round(nextPosition.h, 2),
        }),
      )
      if (isTouched) {
        store.dispatch(controlTree.change(`${basePath}.isTouched`, isTouched))
      }
    }

    fabricCanvas.meta = { pieceName }

    fabricCanvas.centeredScaling = true

    fabricCanvas.preserveObjectStacking = true

    if (isInit) {
      fabricCanvas.on('selection:created', (ev) => {
        const fabricObject = ev.target as FabricObject

        if (!fabricObject) {
          return
        }

        activeSelectionPlacementId = fabricObject.meta?.placementId
      })

      fabricCanvas.on('selection:updated', (ev) => {
        const fabricObject = ev.target as FabricObject

        if (!fabricObject) {
          return
        }

        activeSelectionPlacementId = fabricObject.meta?.placementId
      })

      fabricCanvas.on('selection:cleared', (ev) => {
        const isInitiatedByUser = 'deselected' in ev
        if (isInitiatedByUser) {
          activeSelectionPlacementId = undefined
        }
      })
    }

    const backgroundCanvas = document.createElement('canvas')

    await canvasTexture.getCanvas(backgroundCanvas)

    const { width, height } = backgroundCanvas

    const getPositionFromFabricObject = (fabricObject: FabricObject) => {
      const isWidthAndHeightSame = isNegligible(
        fabricObject.width - fabricObject.height,
        1,
      )

      const widthPx =
        ((fabricObject.width * fabricObject.scaleX) / width) * cutLineBox.width
      const heightPx =
        isWidthAndHeightSame ? widthPx : (
          ((fabricObject.height * fabricObject.scaleY) / height) *
          cutLineBox.height
        )

      return {
        x:
          ((fabricObject.left -
            width / 2 +
            (fabricObject.width * fabricObject.scaleX) / 2) /
            width) *
          cutLineBox.width,
        y:
          ((fabricObject.top -
            height / 2 +
            (fabricObject.height * fabricObject.scaleY) / 2) /
            height) *
          cutLineBox.height,
        w: widthPx,
        h: heightPx,
      }
    }

    const getFabricPositionFromPosition = (position: Position) => {
      const { x, y, w, h } = position

      const objectWidth = (w / cutLineBox.width) * width
      const objectHeight = (h / cutLineBox.height) * height

      const scaleX = (width * (position.w / cutLineBox.width)) / objectWidth
      const scaleY = (height * (position.h / cutLineBox.height)) / objectHeight
      const scale = Math.max(scaleX, scaleY)

      return {
        left:
          width * (x / cutLineBox.width) +
          width / 2 -
          ((w / cutLineBox.width) * width) / 2,
        top:
          height * (y / cutLineBox.height) +
          height / 2 -
          ((h / cutLineBox.height) * height) / 2,
        width: objectWidth,
        height: objectHeight,
        scaleX,
        scaleY,
        scale,
      }
    }

    fabricCanvas.setDimensions({ width, height })

    // TODO: Use getFabricPositionFromPosition instead of these.
    const getFabricLeft = (x: number, w: number) =>
      width * (x / cutLineBox.width) +
      width / 2 -
      ((w / cutLineBox.width) * width) / 2

    const getFabricTop = (y: number, h: number) =>
      height * (y / cutLineBox.height) +
      height / 2 -
      ((h / cutLineBox.height) * height) / 2

    const getFabricWidth = (w: number) => (w / cutLineBox.width) * width

    const getFabricHeight = (h: number) => (h / cutLineBox.height) * height

    const backgroundImage = new fabric.Image(backgroundCanvas, {
      selectable: false,
    })

    fabricCanvas.setBackgroundImage(backgroundImage, () => {})

    const boundingBoxRect = new fabric.Rect({
      top: getFabricTop(boundingBox.y, boundingBox.height),
      left: getFabricLeft(
        pieceName === 'frontRight' ?
          boundingBox.x + offsetX
        : boundingBox.x - offsetX,
        boundingBox.width,
      ),
      width: getFabricWidth(boundingBox.width),
      height: getFabricHeight(boundingBox.height),
      visible: false,
      selectable: false,
      // This allows clicking through transparent fill.
      perPixelTargetFind: true,
      fill: 'transparent',
      stroke: 'rgba(0, 0, 0, 0.5)',
      strokeWidth: 5,
      strokeDashArray: [10, 5],
    }) as FabricObject

    boundingBoxRect.meta = { isBoundingBox: true }

    fabricCanvas.add(boundingBoxRect)

    const exprEval = createExprEval({
      size: DEFAULT_SIZE,
      playerNumber: player.number,
      playerName: player.name,
      playerYear: player.extras?.year,
      ageGroup: 'adult',
    })

    const getLabelText = ({
      widthInches,
      heightInches,
    }: {
      widthInches: number
      heightInches: number
    }) => `${widthInches}″ W x ${heightInches}″ H`

    const setLabelPositon = (label: FabricText, fabricObject: FabricObject) => {
      label.left =
        fabricObject.left +
        (fabricObject.width * fabricObject.scaleX) / 2 -
        label.width / 2

      const isLabelAnchoredBelow = fabricObject.top < height / 2

      if (isLabelAnchoredBelow) {
        label.top =
          fabricObject.top +
          fabricObject.height * fabricObject.scaleY +
          LABEL_PADDING
      } else {
        label.top = fabricObject.top - label.height - LABEL_PADDING
      }
    }

    for (const placement of placements) {
      const { asImage, svgData } = await placementToSvgData(placement, exprEval)
      const { content, placementId, element } = placement
      const { isDynamic } = PLACEMENT_DICT[placementId]

      const prefix = `jersey.${placementId}`
      let position =
        recipe[`${prefix}.position`] ?
          { ...recipe[`${prefix}.position`] }
        : undefined

      const isUsingDefaultPosition = !position

      if (isUsingDefaultPosition) {
        const defaultPositionPieceName =
          pieceName === 'frontRight' && isDynamic ? 'frontLeft' : pieceName

        const placementPieceMappingItem = pattern.pieceMapping.find(
          (x) => x.name === defaultPositionPieceName,
        )
        if (!placementPieceMappingItem) {
          throw new Error('Bad defaultPositionPieceName')
        }

        const { anchors } = await getSize(
          PATTERN_PACKAGE,
          pattern,
          patternName,
          placementPieceMappingItem,
          DEFAULT_SIZE,
          getCornerNames(product.id, defaultPositionPieceName),
        )

        const elementPosition = getElementPosition(
          defaultPositionPieceName,
          anchors,
          DEFAULT_SIZE,
          element,
          svgData,
        )

        position = {
          x:
            elementPosition.left -
            cutLineBox.width / 2 +
            elementPosition.width / 2,
          y:
            elementPosition.top -
            cutLineBox.height / 2 +
            elementPosition.height / 2,
          w: elementPosition.width,
          h: elementPosition.height,
        }

        // TODO: Figure out why we need special handling for frontMiddleRight.
        if (isDynamic && placementId === 'frontMiddleRight') {
          position.x -= offsetX
        }
      } else {
        position.x += cutLineBox.x
        position.y += cutLineBox.y
      }

      if (isDynamic && pieceName === 'frontRight') {
        position.x += offsetX
      }

      if (
        isUsingDefaultPosition &&
        isDynamic &&
        position.w !== 0 &&
        position.h !== 0 &&
        pieceName !== 'frontRight'
      ) {
        dispatchToControlTree(
          prefix,
          {
            ...position,
            x: position.x - cutLineBox.x,
            y: position.y - cutLineBox.y,
          },
          false,
        )
      }

      const getWidthAndHeightInches = (fabricObject: FabricObject) => {
        const position = getPositionFromFabricObject(fabricObject)

        const widthInches = round(px2in(position.w), 2)
        const heightInches = round(px2in(position.h), 2)

        return { widthInches, heightInches }
      }

      const fabricObject = await svgDataToFabricObject(svgData, asImage)

      fabricObject.meta = {
        placementId,
      }

      const getOther = () => {
        const fabrics = getFabrics()

        const otherFabric =
          fabrics[
            `${freeFormPieceDatum.productId}_${
              pieceName === 'frontRight' ? 'frontLeft' : 'frontRight'
            }Mat`
          ]

        const objects = (otherFabric?.getObjects() ?? []) as FabricObject[]

        const otherObject = objects.find(
          (o) => o.meta && o.meta.placementId === placementId,
        )

        const otherLabel = objects.find(
          (o) => o.meta && o.meta.placementIdForLabel === placementId,
        ) as FabricText

        return { otherFabric, otherObject, otherLabel }
      }

      const keepInsideBoundingBox = (fabricObject: FabricObject) => {
        const { otherObject } = getOther()

        // Get the relative offset between pieces
        const relativeOffset = pieceName === 'frontRight' ? offsetX : -offsetX

        // Calculate limits for current object
        const leftLimit = boundingBoxRect.left
        const rightLimit =
          boundingBoxRect.left +
          boundingBoxRect.width -
          fabricObject.width * fabricObject.scaleX
        const topLimit = boundingBoxRect.top
        const bottomLimit =
          boundingBoxRect.top +
          boundingBoxRect.height -
          fabricObject.height * fabricObject.scaleY

        // If we have a related object, check if moving current object would push other object out of bounds
        if (otherObject) {
          const otherLeftLimit = boundingBoxRect.left
          const otherRightLimit =
            boundingBoxRect.left +
            boundingBoxRect.width -
            otherObject.width * otherObject.scaleX

          // Calculate proposed positions
          const proposedLeft = fabricObject.left
          const proposedOtherLeft = proposedLeft + relativeOffset

          // Check if other object would go out of bounds
          if (proposedOtherLeft < otherLeftLimit) {
            // Adjust current object's position to keep other object in bounds
            fabricObject.left = otherLeftLimit - relativeOffset
          } else if (proposedOtherLeft > otherRightLimit) {
            fabricObject.left = otherRightLimit - relativeOffset
          }
        }

        // Keep current object within its bounds
        fabricObject.left = clamp(fabricObject.left, leftLimit, rightLimit)
        fabricObject.top = clamp(fabricObject.top, topLimit, bottomLimit)

        // Now update other object position
        if (otherObject) {
          otherObject.left = fabricObject.left + Math.abs(relativeOffset)
          otherObject.top = fabricObject.top
          otherObject.scaleX = fabricObject.scaleX
          otherObject.scaleY = fabricObject.scaleY
          otherObject.setCoords()
        }

        fabricObject.setCoords()
      }

      const isSelectable =
        'svg' in element ?
          String(exprEval(element.svg.name)).startsWith('/api/images')
        : true

      fabricObject.selectable = isSelectable

      fabricObject.left = getFabricLeft(position.x - cutLineBox.x, position.w)
      fabricObject.top = getFabricTop(position.y - cutLineBox.y, position.h)

      const scaleX =
        (width * (position.w / cutLineBox.width)) / fabricObject.width
      const scaleY =
        (height * (position.h / cutLineBox.height)) / fabricObject.height
      const scale = Math.max(scaleX, scaleY)

      if (content === 'playerNumber' || content === 'playerYear') {
        // Player number is different for every player.
        // Keep the height of original number, but adjust width and left.
        const leftAdjustment =
          (fabricObject.width * scale - fabricObject.width * scaleY) / 2

        fabricObject.scale(scaleY)
        fabricObject.left += leftAdjustment
      } else {
        fabricObject.scale(scale)
      }

      fabricCanvas.add(fabricObject)

      fabricObject.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        mtr: false,
      })

      fabricObject.set({
        borderColor: 'rgba(0, 0, 0, 0.75)',
        cornerColor: 'rgba(0, 0, 0, 0.5)',
        cornerSize: 20,
        transparentCorners: false,
      })

      if (isSelectable) {
        const label = new fabric.Text(
          getLabelText(getWidthAndHeightInches(fabricObject)),
          {
            selectable: false,
            visible: false,
            fontFamily: 'Roboto',
            fontSize: 18,
            fill: '#ffffff',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        ) as FabricText

        label.meta = {
          placementIdForLabel: placementId,
        }

        setLabelPositon(label, fabricObject)

        fabricCanvas.add(label)

        fabricObject.on('selected', () => {
          boundingBoxRect.visible = true
          label.visible = true

          const { otherFabric, otherObject } = getOther()

          if (otherFabric && otherObject) {
            otherFabric.setActiveObject(otherObject)

            otherFabric.renderAll()
          }
        })

        fabricObject.on('deselected', () => {
          boundingBoxRect.visible = false
          label.visible = false

          const { otherFabric, otherObject } = getOther()

          if (otherFabric && otherObject) {
            otherFabric.discardActiveObject()

            otherFabric.renderAll()
          }
        })

        const updatePosition = () => {
          const position = getPositionFromFabricObject(fabricObject)
          const { x, y, w, h } = position

          if (
            Object.values({ x, y, w, h }).every((val) => Number.isFinite(val))
          ) {
            dispatchToControlTree(prefix, position)
          }

          return position
        }

        const updateOtherFabricCanvases = (nextPosition: Position) => {
          const { otherFabric, otherObject, otherLabel } = getOther()

          if (otherFabric && otherObject && otherLabel) {
            const x =
              pieceName === 'frontRight' ?
                nextPosition.x - offsetX
              : nextPosition.x + offsetX

            const fabricPosition = getFabricPositionFromPosition({
              ...nextPosition,
              x,
            })

            otherObject.scale(fabricObject.scaleX)
            otherObject.left = fabricPosition.left
            otherObject.top = fabricPosition.top
            otherObject.setCoords()

            otherLabel.text = getLabelText(getWidthAndHeightInches(otherObject))
            setLabelPositon(otherLabel, otherObject)
            otherLabel.setCoords()

            otherFabric.renderAll()
          }
        }

        fabricObject.on('moving', () => {
          keepInsideBoundingBox(fabricObject)
          setLabelPositon(label, fabricObject)

          const nextPosition = getPositionFromFabricObject(fabricObject)
          updateOtherFabricCanvases(nextPosition)
        })

        fabricObject.on('modified', () => {
          updatePosition()
        })

        fabricObject.on('scaling', (ev) => {
          const corner = ev.transform?.corner
          const { otherObject } = getOther()

          // When any bottom corner is used, we snap to width.
          // When any top corner is used, we snap to height.
          const isSnappingWidth = corner === 'bl' || corner === 'br'

          const { widthInches, heightInches } =
            getWidthAndHeightInches(fabricObject)

          const widthSnappedInches = round(
            Math.round(widthInches / 0.1) * 0.1,
            1,
          )
          const widthSnappedPx = in2px(widthSnappedInches)
          const heightSnappedInches = round(
            Math.round(heightInches / 0.1) * 0.1,
            1,
          )
          const heightSnappedPx = in2px(heightSnappedInches)

          // Calculate max allowed width considering both objects and offset
          const maxAllowedWidth =
            otherObject ?
              boundingBoxRect.width - Math.abs(offsetX)
            : boundingBoxRect.width

          let scale =
            isSnappingWidth ?
              ((Math.min(widthSnappedPx, maxAllowedWidth) / cutLineBox.width) *
                width) /
              fabricObject.width
            : ((heightSnappedPx / cutLineBox.height) * height) /
              fabricObject.height

          const ratioToBb = Math.min(
            ((maxAllowedWidth / cutLineBox.width) * width) / fabricObject.width,
            boundingBoxRect.height / fabricObject.height,
          )
          // Limit scale to 10% and 100% relative to bounding box.
          scale = clamp(scale, ratioToBb * 0.1, ratioToBb)

          const left =
            fabricObject.left +
            ((fabricObject.width / 2) * fabricObject.scaleX -
              (fabricObject.width / 2) * scale)
          const top =
            fabricObject.top +
            ((fabricObject.height / 2) * fabricObject.scaleY -
              (fabricObject.height / 2) * scale)

          fabricObject.scale(scale)
          fabricObject.left = left
          fabricObject.top = top
          fabricObject.flipX = false
          fabricObject.flipY = false

          keepInsideBoundingBox(fabricObject)

          label.text = getLabelText(getWidthAndHeightInches(fabricObject))
          setLabelPositon(label, fabricObject)

          const nextPosition = getPositionFromFabricObject(fabricObject)
          updateOtherFabricCanvases(nextPosition)
        })

        if (placementId === activeSelectionPlacementId) {
          fabricCanvas.setActiveObject(fabricObject)
        }

        // After initial scaling and position setup
        const position = getPositionFromFabricObject(fabricObject)
        const { otherObject, otherFabric } = getOther()

        // Calculate max allowed width considering both objects and offset
        const maxAllowedWidth =
          otherObject ?
            Math.min(
              boundingBoxRect.width,
              boundingBoxRect.width - Math.abs(offsetX),
            )
          : boundingBoxRect.width

        if (position.w > maxAllowedWidth) {
          const scale =
            maxAllowedWidth / (fabricObject.width * fabricObject.scaleX)
          fabricObject.scale(fabricObject.scaleX * scale)
        }
        if (position.h > boundingBoxRect.height) {
          fabricObject.scaleToHeight(boundingBoxRect.height)
        }

        // Apply the same scale to other object if it exists and trigger initial render
        if (otherObject && otherFabric) {
          otherObject.scaleX = fabricObject.scaleX
          otherObject.scaleY = fabricObject.scaleY
          otherObject.setCoords()

          // Force initial update of other object's position
          const initialPosition = getPositionFromFabricObject(fabricObject)
          updateOtherFabricCanvases(initialPosition)
        }

        keepInsideBoundingBox(fabricObject)
        updatePosition()
      }
    }
    // Bring Mizuno specific placements to the front.
    fabricCanvas
      .getObjects()
      .filter(
        (o: FabricObject) =>
          o.meta?.placementId === 'sizeTag' ||
          o.meta?.placementId === 'jerseyRunbird' ||
          o.meta?.placementId === 'pantsRunbird',
      )
      .forEach((o) => {
        fabricCanvas.bringToFront(o)
      })
  }

  return {
    key: {
      canvasKey: canvasTexture.key,
      placements: placements.map((x) => ({
        ...x,
        // These are handled by Fabric directly
        element: { ...x.element, anchor: undefined, boundingBox: undefined },
      })),
      // These are templates, so user input won't change placements
      playerName:
        placements.some((x) => x.content === 'playerName') && player.name,
      playerNumber:
        placements.some((x) => x.content === 'playerNumber') && player.number,
      playerYear:
        placements.some((x) => x.content === 'playerYear') &&
        player.extras?.year,
    },
    getFabric,
  }
}

export default createFabricTexture
