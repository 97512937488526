import * as _ from '@technically/lodash'

import { createControlTree } from '../../../platform/client/control-tree'
import Text from '../../../platform/client/control-tree/text'
import Select from '../../../platform/client/control-tree/select'
import FileUpload from '../../../platform/client/control-tree/fileUpload'
import Repeater from '../../../platform/client/control-tree/repeater'
import getAsset from '../../../platform/getAsset'

import { getSkuValues } from '../common/meta'
import {
  CurrencyId,
  ProductId,
  BraidPositionId,
  PlacementId,
  ProductTypeId,
  PropDef,
  ProductType,
  Sport,
  Design,
  Color,
  Fill,
  Decoration,
  Font,
  PropDefId,
  PlacementMode,
  COLORS,
  PROP_DEF_DICT,
  CURRENCY_DICT,
  PLACEMENT_DECORATIONS,
  PLACEMENT_GROUP_DICT,
  PLACEMENT_MODES,
  DECORATION_DICT,
  FILLS,
  PRODUCT_DICT,
  DESIGNS,
  PLACEMENT_DICT,
  DECORATIONS,
  FONTS,
  LAYOUTS,
  BRAID_POSITIONS,
  FABRICS,
  SIZES,
  PRODUCTS,
  PRODUCT_TYPES,
  SPORTS,
  SPORT_DICT,
  FREE_FORM_PIECE_DATA,
  FULL_CUSTOM_PIECE_DATA_DICT,
  TAILS,
  Tail,
  Layout,
  DECORATION_SIZES,
  DecorationSizeId,
} from '../common/sheets'
import hexToRgb from '../common/hexToRgb'
import { DecorationSizeRow } from '../common/typings'

type AreaId = 'body' | 'sleeve' | 'collar' | 'beltLoop'

const w = (typeof window !== 'undefined' ? window : {}) as any

const allColorsWithUpdatedNames = _.map(COLORS, (color) => {
  const { r, g, b } = hexToRgb(color.props.hex)
  return {
    ...color,
    name: `${color.name} (RGB: ${r}, ${g}, ${b})`,
  }
})

const baseColorsWithUpdatedNames = allColorsWithUpdatedNames.filter(
  (x) => !x.parentId,
)

const withPropDef = (propDef: PropDef) => ({
  label: propDef.name,
  subline: propDef.description || undefined,
  description: propDef.description,
  defaultValue: propDef.defaultValueId ?? null,
  isRequired: !propDef.isOptional,
  showLabel: true,
})

const getPropDefId = (nodeId: string) => nodeId.replace(/\./g, '_')

const getDefaultValue = (
  propId: string,
  productId?: string,
  fallbackDefault?: string | null,
) => {
  if (fallbackDefault) {
    return fallbackDefault
  }

  const propDef = PROP_DEF_DICT[propId as PropDefId]

  return propDef.defaultValueId ?? null
}

const enabledCurrencies = _.pickBy(
  CURRENCY_DICT,
  (currency) => currency.props.isEnabled,
)

const isTextDeco = (decorationId: Decoration['id']) =>
  _.includes(['teamName', 'playerName'], decorationId)

const isNumberDeco = (decorationId: Decoration['id']) =>
  _.includes(['playerNumber', 'playerYear'], decorationId)

const getFillNodes = (
  productType: ProductTypeId,
  areaId: AreaId | PlacementId,
) => {
  const baseId = `${productType}.${areaId}`
  const basePropDefId = getPropDefId(baseId)

  const fontPropDefId = `${basePropDefId}_font`

  const isPlacementFill = !!PROP_DEF_DICT[fontPropDefId]

  const colorField = isPlacementFill ? 'fillColor' : 'color'

  const fillPropDefId = `${basePropDefId}_fill`
  const color1PropDefId = `${basePropDefId}_${colorField}1`
  const color2PropDefId = `${basePropDefId}_${colorField}2`
  const color3PropDefId = `${basePropDefId}_${colorField}3`
  const color4PropDefId = `${basePropDefId}_${colorField}4`

  return {
    fill: Select({
      ...withPropDef(PROP_DEF_DICT[fillPropDefId]),
      showLabel: isPlacementFill,
      dependencies: [
        `${productType}.sku`,
        ...(isPlacementFill ? [`${baseId}.font`] : []),
      ],
      defaultValue: (productId?: string) =>
        getDefaultValue(fillPropDefId, productId),
      options: FILLS,
      isAvailable: (productId?: ProductId, font?: Font) =>
        !!productId &&
        // Fill is available only for text which requires font.
        // Fills are not available for customLogo.
        (isPlacementFill ? !!font : true) &&
        // Sleeves are obviously not fillable on sleeve-less products.
        (PRODUCT_DICT[productId].props.isSleeveLess ?
          areaId !== 'sleeve'
        : true),
      tileType: 'IMAGE',
      classMods: ['fillIcon', 'noLabel'],
      getUrl:
        () =>
        ({ id }) =>
          getAsset(`icons/fills/${id}.png`),
    }),

    [`${colorField}1`]: Select({
      ...withPropDef(PROP_DEF_DICT[color1PropDefId]),
      dependencies: [
        `${baseId}.fill`,
        `${productType}.sku`,
        ...(isPlacementFill ? [`${baseId}.font`] : []),
      ],
      defaultValue: (fill: Fill, productId?: ProductId, font?: Font) =>
        getDefaultValue(
          color1PropDefId,
          productId,
          isPlacementFill && font && fill.id === 'solid' ?
            font.isAvailable.outline ?
              'white'
            : 'black'
          : fill.props.defaults.color1,
        ),
      options: allColorsWithUpdatedNames,
      visibleOptions: (fill?: Fill) =>
        !!fill && fill.id === 'solid' && !isPlacementFill ?
          baseColorsWithUpdatedNames
        : allColorsWithUpdatedNames,
      isAvailable: (fill?: Fill) =>
        !!fill && (fill.id === 'solid' || !!fill.props.color1.length),
    }),

    [`${colorField}2`]: Select({
      ...withPropDef(PROP_DEF_DICT[color2PropDefId]),
      dependencies: [`${baseId}.fill`, `${productType}.sku`],
      defaultValue: (fill: Fill, productId?: ProductId) =>
        getDefaultValue(color2PropDefId, productId, fill.props.defaults.color2),
      options: allColorsWithUpdatedNames,
      isAvailable: (fill?: Fill) => !!fill && !!fill.props.color2?.length,
    }),

    [`${colorField}3`]: Select({
      ...withPropDef(PROP_DEF_DICT[color3PropDefId]),
      dependencies: [`${baseId}.fill`, `${productType}.sku`],
      defaultValue: (fill: Fill, productId?: ProductId) =>
        getDefaultValue(color3PropDefId, productId, fill.props.defaults.color3),
      options: allColorsWithUpdatedNames,
      isAvailable: (fill?: Fill) => !!fill && !!fill.props.color3?.length,
    }),

    [`${colorField}4`]: Select({
      ...withPropDef(PROP_DEF_DICT[color4PropDefId]),
      dependencies: [`${baseId}.fill`, `${productType}.sku`],
      defaultValue: (fill: Fill, productId?: ProductId) =>
        getDefaultValue(color4PropDefId, productId, fill.props.defaults.color4),
      options: allColorsWithUpdatedNames,
      isAvailable: (fill?: Fill) => !!fill && !!fill.props.color4?.length,
    }),
  }
}

const getPlacementDecorationSizes = (
  productId: ProductId,
  placementId: PlacementId,
): DecorationSizeRow[] | undefined => {
  const placementDecoration = PLACEMENT_DECORATIONS.find(
    (x) => x.productId === productId && x.placementId === placementId,
  )

  // Why the assertion? Because TS infers the types as tuples, and we want to make sure it's an array.
  const decorationSizeIds = placementDecoration?.decorationSizeIds as
    | DecorationSizeId[]
    | undefined

  if (!decorationSizeIds) {
    return
  }

  return DECORATION_SIZES.filter(({ id }) => decorationSizeIds.includes(id))
}

const getRunbirdNodes = (productType: ProductTypeId) => {
  const baseId = `${productType}.runbird`
  const basePropDefId = getPropDefId(baseId)

  const innerColorPropDefId = `${basePropDefId}_innerColor`
  const outerColorPropDefId = `${basePropDefId}_outerColor`

  return {
    innerColor: Select({
      ...withPropDef(PROP_DEF_DICT[innerColorPropDefId]),
      dependencies: [`${productType}.sku`],
      options: allColorsWithUpdatedNames,
      isAvailable: (productId?: ProductId) => !!productId,
      defaultValue: (productId?: string) =>
        getDefaultValue(innerColorPropDefId, productId),
    }),

    outerColor: Select({
      ...withPropDef(PROP_DEF_DICT[outerColorPropDefId]),
      dependencies: [`${baseId}.innerColor`, `${productType}.sku`],
      options: allColorsWithUpdatedNames,
      visibleOptions: (innerColor: Color) =>
        _.filter(
          allColorsWithUpdatedNames,
          // Tonals count as the same color, as far as contrast is concerned
          (color) =>
            (color.parentId ?? color.id) !==
            (innerColor.parentId ?? innerColor.id),
        ),
      isAvailable: (innerColor?: Color) => !!innerColor,
      defaultValue: (_innerColor?: Color, productId?: string) =>
        getDefaultValue(outerColorPropDefId, productId),
    }),
  }
}

const IMAGE_UPLOAD_INFORMATION = dedent`If you want your image to match the
design colors, please make sure the RGB color values of your image match the
Mizuno RGB color values used in your design. The RGB values can be seen as you
hover over the specific color on the palette. This can be done with graphic
design software like Illustrator or Inkscape (available online for free).`

const getDesignNodes = (productType: ProductTypeId) => {
  const baseId = `${productType}.design`
  const basePropDefId = getPropDefId(baseId)

  const designPropDefId = `${basePropDefId}_design`
  const designColor1PropDefId = `${basePropDefId}_color1`
  const designColor2PropDefId = `${basePropDefId}_color2`
  const designColor3PropDefId = `${basePropDefId}_color3`
  const designFilePropDefId = `${basePropDefId}_file`

  return {
    design: Select({
      ...withPropDef(PROP_DEF_DICT[designPropDefId]),
      showLabel: false,
      dependencies: [`${productType}.sku`],
      options: DESIGNS,
      visibleOptions: (productId: ProductId) => {
        const product = PRODUCT_DICT[productId]

        const isFullCustomEnabled =
          FULL_CUSTOM_PIECE_DATA_DICT[productId] &&
          !FULL_CUSTOM_PIECE_DATA_DICT[productId].isDisabled

        return _.filter(
          DESIGNS,
          (design) =>
            !!(
              design.props.isAvailable.sport[product.props.sport] &&
              design.props.isAvailable.productType[product.props.type] &&
              (product.props.isSleeveLess ?
                !design.props.isAvailable.isSleevesOnly
              : true) &&
              (design.id === 'fullCustom' ? isFullCustomEnabled : true)
            ),
        )
      },
      isAvailable: (productId?: ProductId) => !!productId,
      defaultValue: (productId?: string) =>
        getDefaultValue(designPropDefId, productId),
      tileType: 'IMAGE',
      classMods: ['designIcon', 'noLabel'],
      getUrl:
        (productId: ProductId) =>
        ({ id }) =>
          getAsset(`icons/designs/${productId}/${id}.png`),
    }),

    color1: Select({
      ...withPropDef(PROP_DEF_DICT[designColor1PropDefId]),
      dependencies: [`${baseId}.design`, `${productType}.sku`],
      defaultValue: (design: Design, productId?: string) =>
        getDefaultValue(
          designColor1PropDefId,
          productId,
          design.props.defaults?.color1,
        ),
      options: allColorsWithUpdatedNames,
      isAvailable: (design?: Design) =>
        !!design && !!design.props.color1?.length,
    }),

    color2: Select({
      ...withPropDef(PROP_DEF_DICT[designColor2PropDefId]),
      dependencies: [`${baseId}.design`, `${productType}.sku`],
      defaultValue: (design: Design, productId?: string) =>
        getDefaultValue(
          designColor2PropDefId,
          productId,
          design.props.defaults?.color2,
        ),
      options: allColorsWithUpdatedNames,
      isAvailable: (design?: Design) =>
        !!design && !!design.props.color2?.length,
    }),

    color3: Select({
      ...withPropDef(PROP_DEF_DICT[designColor3PropDefId]),
      dependencies: [`${baseId}.design`, `${productType}.sku`],
      defaultValue: (design: Design, productId?: string) =>
        getDefaultValue(
          designColor3PropDefId,
          productId,
          design.props.defaults?.color3,
        ),
      options: allColorsWithUpdatedNames,
      isAvailable: (design?: Design) =>
        !!design && !!design.props.color3?.length,
    }),

    file: FileUpload({
      ...withPropDef(PROP_DEF_DICT[designFilePropDefId]),
      dependencies: [`${baseId}.design`, `${productType}.sku`],
      isAvailable: (design?: Design) => design?.id === 'fullCustom',
      subline: 'Template file with your full custom design in SVG format',
      information: IMAGE_UPLOAD_INFORMATION,
      accept: { 'image/svg+xml': ['.svg'] },
    }),
  }
}

const getPlacementNodes = (productType: ProductTypeId) => {
  const baseId = productType
  const basePropDefId = getPropDefId(baseId)

  const productPlacements = _.pickBy(
    PLACEMENT_DICT,
    (placement) =>
      placement.isDynamic && placement.isAvailable.productType[productType],
  )

  const placementNodes = _.mapValues(
    productPlacements,
    (_placement, placementId: PlacementId) => {
      const contentPropDefId = `${basePropDefId}_${placementId}_content`
      const fontPropDefId = `${basePropDefId}_${placementId}_font`
      const layoutPropDefId = `${basePropDefId}_${placementId}_layout`
      const tailPropDefId = `${basePropDefId}_${placementId}_tail`
      const tailTextPropDefId = `${basePropDefId}_${placementId}_tailText`
      const tailTextFontPropDefId = `${basePropDefId}_${placementId}_tailTextFont`
      const tailTextColorPropDefId = `${basePropDefId}_${placementId}_tailTextColor`
      const outlineColor1PropDefId = `${basePropDefId}_${placementId}_outlineColor1`
      const outlineColor2PropDefId = `${basePropDefId}_${placementId}_outlineColor2`

      const { groupId } = PLACEMENT_DICT[placementId]

      return {
        content: Select({
          ...withPropDef(PROP_DEF_DICT[contentPropDefId]),
          dependencies: [`${baseId}.sku`, `${baseId}.${groupId}.mode`],
          isAvailable: (productId?: ProductId) => !!productId,
          autoUnavailable: true,
          options: DECORATIONS,
          visibleOptions: (productId: ProductId, mode?: PlacementMode) => {
            const placementDecoration = PLACEMENT_DECORATIONS.find(
              (x) => x.productId === productId && x.placementId === placementId,
            )

            if (!placementDecoration) {
              return []
            }

            const decorationIds = _.filter(
              placementDecoration.decorationIds,
              (decorationId) =>
                // Player name is disabled when in free form placement mode.
                mode?.id === 'freeForm' ?
                  !_.includes(['playerName'], decorationId)
                : true,
            )

            return _.map(
              decorationIds,
              (decorationId) => DECORATION_DICT[decorationId],
            )
          },
          showLabel: false,
          hideNone: true,
          defaultValue: (productId?: string) =>
            getDefaultValue(contentPropDefId, productId),
        }),

        font: Select({
          ...withPropDef(PROP_DEF_DICT[fontPropDefId]),
          dependencies: [
            `${baseId}.${placementId}.content`,
            'details.teamName.text',
            `${baseId}.sku`,
          ],
          isAvailable: (decoration?: Decoration) =>
            !!decoration &&
            (isTextDeco(decoration.id) || isNumberDeco(decoration.id)),
          options: FONTS,
          visibleOptions: (decoration: Decoration, teamName: string) => {
            const isAvailableForText = isTextDeco(decoration.id)
            const isAvailableForNumber = isNumberDeco(decoration.id)
            const hasNumberInTeamName = teamName.match(/\d/) !== null

            return FONTS.filter(
              (font) =>
                font.isEnabled &&
                (isAvailableForText ?
                  font.isAvailable.uppercase || font.isAvailable.lowercase
                : true) &&
                (isAvailableForNumber ? font.isAvailable.numbers : true) &&
                (decoration.id === 'teamName' && hasNumberInTeamName ?
                  font.isAvailable.numbers
                : true),
            )
          },
          defaultValue: (
            _decoration: Decoration,
            _teamName: string,
            productId?: string,
          ) => getDefaultValue(fontPropDefId, productId),
          tileType: 'IMAGE',
          classMods: ['fontIcon', 'noLabel'],
          getUrl:
            () =>
            ({ id }) =>
              getAsset(`icons/fonts/${id}.png`),
        }),

        fontSize: Select({
          label: 'Font Size',
          showLabel: true,
          dependencies: [
            `${baseId}.sku`,
            `${baseId}.${placementId}.content`,
            `${baseId}.${groupId}.mode`,
            'filters.sport',
          ],
          options: DECORATION_SIZES,
          visibleOptions: (productId) =>
            getPlacementDecorationSizes(productId, placementId),
          isAvailable: (
            _,
            decoration?: Decoration,
            mode?: PlacementMode,
            sport?: Sport,
          ) =>
            decoration?.id === 'playerNumber' &&
            mode?.id !== 'freeForm' &&
            sport?.id === 'volleyball',
          defaultValue: (productId) =>
            getPlacementDecorationSizes(productId, placementId)?.[0],
        }),

        layout: Select({
          ...withPropDef(PROP_DEF_DICT[layoutPropDefId]),
          dependencies: [
            `${baseId}.${placementId}.content`,
            `${baseId}.sku`,
            `${baseId}.${placementId}.font`,
          ],
          options: LAYOUTS,
          visibleOptions: (_, __, font: Font) =>
            LAYOUTS.filter(
              (x) => x.isEnabled && (!font.isScript || x.isScriptCompatible),
            ),
          // Layouts are enabled for text decos, but disabled for player numbers & custom logos.
          isAvailable: (decoration?: Decoration) =>
            !!decoration && isTextDeco(decoration.id),

          defaultValue: (_decoration: Decoration, productId) =>
            getDefaultValue(layoutPropDefId, productId) ?? 'straight',
          tileType: 'IMAGE',
          classMods: ['fontIcon', 'noLabel'],
          getUrl:
            () =>
            ({ id }) =>
              getAsset(`icons/layouts/${id}.png`),
        }),

        tail:
          PROP_DEF_DICT[tailPropDefId] &&
          Select({
            ...withPropDef(PROP_DEF_DICT[tailPropDefId]),
            dependencies: [
              `${baseId}.${placementId}.content`,
              `${baseId}.${placementId}.font`,
              `${baseId}.${placementId}.layout`,
              'filters.sport',
            ],
            options: TAILS,
            visibleOptions: (_, font: Font) =>
              TAILS.filter(
                (x) => x.isEnabled && (!x.isConnected || font.isScript),
              ),
            isAvailable: (
              decoration: Decoration | undefined,
              _,
              layout: Layout,
              sport,
            ) =>
              decoration?.id === 'teamName' &&
              placementId === 'frontChest' &&
              layout.isTailCompatible &&
              sport?.id !== 'volleyball',
            defaultValue: getDefaultValue(tailPropDefId),
            tileType: 'IMAGE',
            autoUnavailable: true,
            hideNone: true,
            getUrl:
              () =>
              ({ id }) =>
                getAsset(`icons/tails/${id}.png`),
          }),

        tailText:
          PROP_DEF_DICT[tailTextPropDefId] &&
          Text({
            ...withPropDef(PROP_DEF_DICT[tailTextPropDefId]),
            maxLength: 30,
            dependencies: [`${baseId}.${placementId}.tail`],
            isAvailable: (tail: Tail | undefined) => !!tail,
          }),

        tailTextFont:
          PROP_DEF_DICT[tailTextFontPropDefId] &&
          Select({
            ...withPropDef(PROP_DEF_DICT[tailTextFontPropDefId]),
            dependencies: [
              `${baseId}.${placementId}.tail`,
              `${baseId}.${placementId}.tailText`,
            ],
            isAvailable: (tail: Tail | undefined) => !!tail,
            options: FONTS,
            visibleOptions: (_, text: string) => {
              const hasNumber = text.match(/\d/) !== null
              return FONTS.filter(
                (x) => x.isEnabled && (!hasNumber || x.isAvailable.numbers),
              )
            },
            defaultValue: getDefaultValue(tailTextFontPropDefId),
            tileType: 'IMAGE',
            classMods: ['fontIcon', 'noLabel'],
            getUrl:
              () =>
              ({ id }) =>
                getAsset(`icons/fonts/${id}.png`),
          }),

        ...getFillNodes(productType, placementId),

        outlineColor1: Select({
          ...withPropDef(PROP_DEF_DICT[outlineColor1PropDefId]),
          dependencies: [`${baseId}.${placementId}.font`, `${baseId}.sku`],
          options: allColorsWithUpdatedNames,
          isAvailable: (font?: Font) => !!font && font.isAvailable.outline,
          defaultValue: (_font: Font, productId?: string) =>
            getDefaultValue(outlineColor1PropDefId, productId),
        }),

        outlineColor2: Select({
          ...withPropDef(PROP_DEF_DICT[outlineColor2PropDefId]),
          dependencies: [`${baseId}.${placementId}.font`, `${baseId}.sku`],
          options: allColorsWithUpdatedNames,
          isAvailable: (font?: Font) => !!font && font.isAvailable.outline,
          defaultValue: (_font: Font, productId?: string) =>
            getDefaultValue(outlineColor2PropDefId, productId),
        }),

        tailTextColor:
          PROP_DEF_DICT[tailTextColorPropDefId] &&
          Select({
            ...withPropDef(PROP_DEF_DICT[tailTextColorPropDefId]),
            dependencies: [`${baseId}.${placementId}.tail`],
            isAvailable: (tail: Tail | undefined) => !!tail,
            options: allColorsWithUpdatedNames,
            defaultValue: getDefaultValue(tailTextColorPropDefId),
          }),

        file: FileUpload({
          ...withPropDef(PROP_DEF_DICT[`${basePropDefId}_${placementId}_file`]),
          dependencies: [`${baseId}.${placementId}.content`],
          isAvailable: (decoration?: Decoration) =>
            decoration?.id === 'customLogo',
          subline: 'Vector file in SVG format',
          information: IMAGE_UPLOAD_INFORMATION,
          accept: { 'image/svg+xml': ['.svg'] },
        }),

        scaleFactor: Text({
          dependencies: [
            `${baseId}.${placementId}.content`,
            `${baseId}.${groupId}.mode`,
            'filters.sport',
          ],
          isAvailable: (
            decoration?: Decoration,
            mode?: PlacementMode,
            sport?: Sport,
          ) => {
            if (
              decoration?.id === 'playerNumber' &&
              sport?.id === 'volleyball'
            ) {
              return false
            }

            return !!decoration && mode?.id !== 'freeForm'
          },
          isRequired: false,
          classMods: (decoration?: Decoration) =>
            decoration?.id === 'customLogo' ? [0.1, 5] : [0.1, 1],
          defaultValue: '1',
          subline: 'Scale Factor',
        }),

        position: Text({
          dependencies: [
            `${baseId}.${placementId}.content`,
            `${baseId}.${groupId}.mode`,
          ],
          isAvailable: (decoration?: Decoration, mode?: PlacementMode) =>
            !!decoration && mode?.id === 'freeForm',
          isRequired: false,
        }),

        isTouched: Text({
          dependencies: [
            `${baseId}.${placementId}.content`,
            `${baseId}.${groupId}.mode`,
          ],
          isAvailable: (decoration?: Decoration, mode?: PlacementMode) =>
            !!decoration && mode?.id === 'freeForm',
          defaultValue: false,
        }),
      }
    },
  )

  const placementGroupNodes = _.mapValues(
    PLACEMENT_GROUP_DICT,
    (placementGroup) => ({
      mode: Select({
        dependencies: [`${baseId}.sku`],
        options: PLACEMENT_MODES,
        isAvailable: (productId?: ProductId) =>
          !!productId &&
          !!_.find(FREE_FORM_PIECE_DATA, {
            productId,
            groupId: placementGroup.id,
            isDisabled: false,
          }),
        defaultValue: 'standard',
      }),
    }),
  )

  return { ...placementNodes, ...placementGroupNodes }
}

const getBraidNodes = (productType: ProductTypeId) => ({
  position: Select({
    ...withPropDef(PROP_DEF_DICT[`${productType}_braid_position`]),
    showLabel: false,
    hideNone: true,
    dependencies: [`${productType}.sku`],
    isAvailable: (sku?: ProductId) => !!sku,
    options: BRAID_POSITIONS,
    visibleOptions: (productId: ProductId) =>
      BRAID_POSITIONS.filter(
        (braidPosition) =>
          PRODUCT_DICT[productId].isAvailable.braidPosition[braidPosition.id],
      ),
    autoUnavailable: true,
    multiple: true,
    defaultValue: (productId?: string) =>
      getDefaultValue(`${productType}_braid_position`, productId),
  }),

  color1: Select({
    ...withPropDef(PROP_DEF_DICT[`${productType}_braid_color1`]),
    dependencies: [`${productType}.braid.position`, `${productType}.sku`],
    isAvailable: (braidPosition?: BraidPositionId[]) =>
      !!braidPosition && braidPosition.length !== 0,
    options: allColorsWithUpdatedNames,
    defaultValue: (_fill: Fill, productId?: string) =>
      getDefaultValue(`${productType}_braid_color1`, productId),
  }),

  color2: Select({
    ...withPropDef(PROP_DEF_DICT[`${productType}_braid_color2`]),
    dependencies: [`${productType}.braid.position`, `${productType}.sku`],
    isAvailable: (braidPosition?: BraidPositionId[]) =>
      !!braidPosition && braidPosition.length !== 0,
    options: allColorsWithUpdatedNames,
    defaultValue: (_fill: Fill, productId?: string) =>
      getDefaultValue(`${productType}_braid_color2`, productId),
  }),

  color3: Select({
    ...withPropDef(PROP_DEF_DICT[`${productType}_braid_color3`]),
    dependencies: [`${productType}.braid.position`, `${productType}.sku`],
    isAvailable: (braidPosition?: BraidPositionId[]) =>
      !!braidPosition && braidPosition.length !== 0,
    options: allColorsWithUpdatedNames,
    defaultValue: (_fill: Fill, productId?: string) =>
      getDefaultValue(`${productType}_braid_color3`, productId),
  }),
})

const controls = {
  env: {
    currency: Text({
      ...withPropDef(PROP_DEF_DICT.env_currency),
      isPrivate: true,
      value: () => () => w?.serverConfig?.currency ?? 'USD',
    }),
  },

  product: {
    sku: Text({
      ...withPropDef(PROP_DEF_DICT.product_sku),
    }),
  },

  jersey: {
    sku: Text({
      ...withPropDef(PROP_DEF_DICT.jersey_sku),
      dependencies: ['product.sku'],
      value: (sku: string) => () => {
        if (!sku) {
          return undefined
        }
        const { jersey } = getSkuValues(sku)
        return jersey?.id ?? null
      },
      valueLabel: (sku: string) => {
        if (!sku) {
          return ''
        }
        const { jersey } = getSkuValues(sku)
        if (!jersey) {
          return ''
        }
        return `${jersey.id}${jersey.youthSku ? ` (${jersey.youthSku})` : ''}`
      },
    }),

    fabric: Select({
      ...withPropDef(PROP_DEF_DICT.jersey_fabric),
      showLabel: false,
      dependencies: ['jersey.sku'],
      options: FABRICS,
      visibleOptions: (productId: ProductId) => {
        const product = PRODUCT_DICT[productId]

        return FABRICS.filter(
          (fabric) => !!fabric.props.isAvailable.product[product.id],
        )
      },
      isAvailable: (jerseySku?: ProductId) => !!jerseySku,
      defaultValue: (productId?: string) =>
        getDefaultValue('jersey_fabric', productId),
    }),

    body: getFillNodes('jersey', 'body'),

    sleeve: getFillNodes('jersey', 'sleeve'),

    collar: getFillNodes('jersey', 'collar'),

    runbird: getRunbirdNodes('jersey'),

    design: getDesignNodes('jersey'),

    ...getPlacementNodes('jersey'),

    braid: getBraidNodes('jersey'),
  },

  pants: {
    sku: Text({
      ...withPropDef(PROP_DEF_DICT.pants_sku),
      dependencies: ['product.sku'],
      value: (sku: string) => () => {
        if (!sku) {
          return undefined
        }
        const { pants } = getSkuValues(sku)
        return pants?.id ?? null
      },
      valueLabel: (sku: string) => {
        if (!sku) {
          return ''
        }
        const { pants } = getSkuValues(sku)
        if (!pants) {
          return ''
        }
        return `${pants.id}${pants.youthSku ? ` (${pants.youthSku})` : ''}`
      },
    }),

    fabric: Select({
      ...withPropDef(PROP_DEF_DICT.pants_fabric),
      showLabel: false,
      dependencies: ['pants.sku'],
      options: FABRICS,
      visibleOptions: (productId: ProductId) => {
        const product = PRODUCT_DICT[productId]

        return FABRICS.filter(
          (fabric) => !!fabric.props.isAvailable.product[product.id],
        )
      },
      isAvailable: (pantsSku?: ProductId) => !!pantsSku,
      defaultValue: (productId?: string) =>
        getDefaultValue('pants_fabric', productId),
    }),

    body: getFillNodes('pants', 'body'),

    beltLoop: getFillNodes('pants', 'beltLoop'),

    runbird: getRunbirdNodes('pants'),

    design: getDesignNodes('pants'),

    ...getPlacementNodes('pants'),

    braid: getBraidNodes('pants'),
  },

  details: {
    recipeName: {
      text: Text({
        ...withPropDef(PROP_DEF_DICT.details_recipeName_text),
        maxLength: 30,
      }),
    },

    teamName: {
      text: Text({
        ...withPropDef(PROP_DEF_DICT.details_teamName_text),
        maxLength: 30,
      }),
    },

    roster: Repeater({
      defaultRepeats: 1,
      controls: {
        jerseySize: Select({
          ...withPropDef(PROP_DEF_DICT.details_roster_jerseySize),
          dependencies: ['jersey.sku'],
          visibleOptions: (jersey) =>
            SIZES.filter((size) => !!size.availableFor.product[jersey]),
          options: SIZES,
        }),

        pantsSize: Select({
          ...withPropDef(PROP_DEF_DICT.details_roster_pantsSize),
          dependencies: ['pants.sku'],
          visibleOptions: (pants) =>
            SIZES.filter((size) => !!size.availableFor.product[pants]),
          options: SIZES,
        }),

        quantity: Text({
          ...withPropDef(PROP_DEF_DICT.details_roster_quantity),
          maxLength: 3,
          pattern: /^[0-9]+$/,
          inputType: 'tel',
        }),

        number: Text({
          ...withPropDef(PROP_DEF_DICT.details_roster_number),
          maxLength: 2,
          pattern: /^[0-9]+$/,
          inputType: 'tel',
        }),

        extras: {
          year: Text({
            ...withPropDef(PROP_DEF_DICT.details_roster_extras_year),
            maxLength: 4,
            pattern: /^[0-9]+$/,
            inputType: 'tel',
          }),
        },

        name: Text({
          ...withPropDef(PROP_DEF_DICT.details_roster_name),
          maxLength: 18,
        }),
      },
    }),
  },

  filters: {
    products: Select({
      isPrivate: true,
      ...withPropDef(PROP_DEF_DICT.filters_products),
      dependencies: ['filters.productType', 'filters.sport'],
      visibleOptions: (productType?: ProductType, sport?: Sport) => {
        if (!productType) {
          return PRODUCTS
        }

        return _.filter(
          PRODUCTS,
          (product) =>
            product.props.isEnabled &&
            product.props.type === productType.id &&
            product.props.sport === sport?.id,
        )
      },
      options: PRODUCTS,
    }),

    productType: Select({
      ...withPropDef(PROP_DEF_DICT.filters_productType),
      options: PRODUCT_TYPES,
      showLabel: false,
      ignorePreferred: true,
    }),

    sport: Select({
      ...withPropDef(PROP_DEF_DICT.filters_sport),
      dependencies: ['filters.productType'],
      options: SPORTS,
      visibleOptions: (productType) => {
        const visibleSports = SPORTS.filter((sport) => !sport.props.isHidden)

        if (!productType) {
          return visibleSports
        }

        return visibleSports.filter((sport) =>
          PRODUCTS.some(
            (product) =>
              product.props.sport === sport.id &&
              product.props.type === productType.id,
          ),
        )
      },
      showLabel: false,
      ignorePreferred: true,
    }),
  },

  calc: {
    price: Text({
      dependencies: ['jersey.sku', 'pants.sku'],
      isPrivate: true,
      value: (jerseySku?: ProductId, pantsSku?: ProductId) => () => {
        const jersey = jerseySku ? PRODUCT_DICT[jerseySku] : undefined
        const pants = pantsSku ? PRODUCT_DICT[pantsSku] : undefined

        return _.mapValues(enabledCurrencies, (currency) =>
          _.reduce(
            [jersey, pants],
            (price, product) =>
              price + (product?.props.price[currency.id] ?? 0),
            0,
          ),
        )
      },
    }),

    priceTotal: Text({
      dependencies: ['calc.price', 'details.roster'],
      isPrivate: true,
      value:
        (
          price: { [key in CurrencyId]: number },
          roster: Record<string, Record<string, any>>,
        ) =>
        () => {
          const quantityTotal = _.reduce(
            roster,
            (r, x) => {
              const quantityNode = x.quantity
              return r + (_.parseInt(quantityNode.value) || 0)
            },
            0,
          )
          return _.mapValues(price, (v) => v * quantityTotal)
        },
    }),

    priceFormatted: Text({
      dependencies: ['calc.priceTotal'],
      isPrivate: true,
      value: (priceTotal: { [key in CurrencyId]: number }) => () =>
        _.map(
          enabledCurrencies,
          (currency) => `${currency.id}: ${priceTotal[currency.id]}`,
        ).join(' | '),
    }),

    jerseySport: Text({
      ...withPropDef(PROP_DEF_DICT.calc_jerseySport),
      isPrivate: true,
      dependencies: ['jersey.sku'],
      value: (jerseySku?: ProductId) => () =>
        jerseySku ? SPORT_DICT[PRODUCT_DICT[jerseySku].props.sport] : null,
    }),

    pantsSport: Text({
      ...withPropDef(PROP_DEF_DICT.calc_pantsSport),
      isPrivate: true,
      dependencies: ['pants.sku'],
      isAvailable: (pantsSku?) => !!pantsSku,
      value: (pantsSku?: ProductId) => () =>
        pantsSku ? SPORT_DICT[PRODUCT_DICT[pantsSku].props.sport] : null,
    }),

    isRosterValid: Text({
      dependencies: ['details.roster'],
      isPrivate: true,
      value: (roster) => () => {
        const isValid = _.every(
          roster,
          (item) =>
            (item.jerseySize.visibleOptions.length === 0 ||
              item.jerseySize.value !== null) &&
            (item.pantsSize.visibleOptions.length === 0 ||
              item.pantsSize.value !== null),
        )
        return isValid
      },
    }),
  },
}

const controlTree = createControlTree(controls)

export default controlTree
