import Papa from 'papaparse'

import getAsset from '../../../../platform/getAsset'

type Props = {
  setRosterValues: (values: Record<string, string>) => void
}

const EXAMPLE_FILENAME = 'team-roster.xlsx'

const RosterUpload = (props: Props) => (
  <div className="rosterUpload">
    <div>
      <a
        href="#"
        onClick={(ev) => {
          ev.preventDefault()

          const input = document.createElement('input')
          input.accept = '.csv'
          input.type = 'file'

          input.addEventListener('change', (ev) => {
            const target = ev.target as HTMLInputElement
            const file = target.files?.item(0)

            if (!file) {
              return
            }

            Papa.parse(file, {
              complete: ({ data }) => {
                const rosterItems = data.slice(1).map((row: string[]) => ({
                  jerseySize: row[0],
                  pantsSize: row[1],
                  quantity: row[2],
                  number: row[3],
                  name: row[4],
                  extras: {
                    year: row[5],
                  },
                }))

                const values = rosterItems.reduce((acc, item, i) => {
                  const prefix = `details.roster.${i + 1}`
                  return {
                    ...acc,
                    [`${prefix}.jerseySize`]: item.jerseySize,
                    [`${prefix}.pantsSize`]: item.pantsSize,
                    [`${prefix}.quantity`]: item.quantity,
                    [`${prefix}.number`]: item.number,
                    [`${prefix}.name`]: item.name,
                    [`${prefix}.extras.year`]: item.extras.year,
                  }
                }, {})

                props.setRosterValues(values)
              },
            })
          })

          input.click()
        }}
      >
        Upload CSV
      </a>{' '}
      with team roster to autofill the form.
      <br /> Download example Excel spreadsheet (
      <a
        href={getAsset(EXAMPLE_FILENAME)}
        download={EXAMPLE_FILENAME}
        target="_blank"
        rel="noreferrer"
      >
        {EXAMPLE_FILENAME}
      </a>
      ) that you can edit and export as CSV.
    </div>
  </div>
)

export default RosterUpload
