import { RendererConfig } from '@orangelv/bjs-renderer'

import getAsset from '../../../platform/getAsset'
import { Nodes, Recipe } from '../common/typings'
import getMaterials from './getMaterials'

const getRendererConfig = async (
  nodes: Nodes,
  recipe: Recipe,
  includeJersey: boolean,
  includePants: boolean,
  rosterPreviewId?: string,
): Promise<RendererConfig> => {
  const jerseyId = recipe['jersey.sku']
  const pantsId = recipe['pants.sku']

  const isJerseyVisible = includeJersey && jerseyId
  const arePantsVisible = includePants && pantsId
  const areBothModelsVisible = isJerseyVisible && arePantsVisible

  const player = {
    name: nodes[`details.roster.${rosterPreviewId}.name`]?.value ?? '',
    number: nodes[`details.roster.${rosterPreviewId}.number`]?.value ?? '',
    extras: {
      year: nodes[`details.roster.${rosterPreviewId}.extras.year`]?.value ?? '',
    },
  }

  return {
    scene: {
      backgroundColor: '#e5e5e5',
      environmentTexture: getAsset('studio.env'),
    },
    camera: {
      lowerRadius: areBothModelsVisible ? 0.75 : 0.5,
      upperRadius: areBothModelsVisible ? 3 : 1.5,
      lowerBeta: Math.PI / 2 - Math.PI / 4,
      upperBeta: Math.PI / 2 + Math.PI / 4,
      peek: { yFactor: areBothModelsVisible ? 1 : 1 / 3 },
    },
    models: {
      jersey:
        isJerseyVisible ?
          {
            url: getAsset(`models/${jerseyId}.gltf`),
            materials: await getMaterials(jerseyId, recipe, player),
          }
        : undefined,
      pants:
        arePantsVisible ?
          {
            url: getAsset(`models/${pantsId}.gltf`),
            materials: await getMaterials(pantsId, recipe, player),
            defaultPosition:
              isJerseyVisible ? { below: 'jersey', offset: 0.01 } : undefined,
          }
        : undefined,
    },
  }
}

export default getRendererConfig
