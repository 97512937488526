import { Player } from '@orangelv/contracts'
import {
  ANNOTATION,
  PATTERN_PACKAGE,
  getFillType,
  getMarkings,
  getSizeIndex,
} from '@orangelv/mizuno-utils'
import {
  DesignPart,
  LoadFont,
  LoadSvg,
  SvgElement,
  renderPiece,
} from '@orangelv/svg-renderer'
import { Tuple, byType, mapTuple } from '@orangelv/utils'
import { in2px } from '@orangelv/utils-geometry'
import { GetSize, Pattern, PieceMappingItem } from '@orangelv/utils-olvpf'

import {
  BRAID_PARTS,
  COLOR_DICT,
  CORNERS,
  ColorId,
  DESIGN_DICT,
  DESIGN_PARTS,
  Product,
  ProductId,
} from './sheets'
import getFill from '../renderer/getFill'
import { Placement, lookUpColor } from '../renderer/getPlacements'
import getFullCustomImage from '../renderer/getFullCustomImage'
import { Recipe } from './typings'
import { filterDesignParts } from '../../../platform/common/svg-renderer-utils'

export const getCornerNames = (productId: ProductId, pieceName: string) =>
  CORNERS.find((x) => x.productId === productId && x.pieceName === pieceName)
    ?.names

const BRAID_THICKNESS = in2px(0.43) / 3

export async function renderPieceFromRecipe({
  product,
  pattern,
  patternName,
  pieceMappingItem,
  sizeName,
  recipe,
  player,
  getSize,
  loadSvg,
  loadFont,
  salesId = 'NO_SALES_ID',
  placements = [],
  extraImageElements = [],
  debug = false,
  isFactoryMode = false,
}: {
  product: Product
  pattern: Pattern
  patternName: string
  pieceMappingItem: PieceMappingItem
  sizeName: string
  recipe: Recipe
  player: Player
  getSize: GetSize
  loadSvg: LoadSvg
  loadFont: LoadFont
  salesId?: string
  placements?: Placement[]
  extraImageElements?: SvgElement[]
  debug?: boolean
  isFactoryMode?: boolean
}) {
  const pieceName = pieceMappingItem.name

  // Run early, await late
  const sizePromise = getSize(
    PATTERN_PACKAGE,
    pattern,
    patternName,
    pieceMappingItem,
    sizeName,
    getCornerNames(product.id, pieceName),
  )

  const prefix = product.props.type

  const braidColors = mapTuple(
    // TypeScript doesn't infer it as a tuple, ColorId union is too big maybe?
    [
      recipe[`${prefix}.braid.color1`],
      recipe[`${prefix}.braid.color2`],
      recipe[`${prefix}.braid.color3`],
    ] as Tuple<ColorId | null, 3>,
    (x) => lookUpColor(x)?.props.hex ?? '',
  )

  const braidPositionIds = recipe[`${prefix}.braid.position`]
  const braids =
    braidPositionIds && braidPositionIds.length > 0 ?
      {
        parts: BRAID_PARTS.filter(
          (x) =>
            x.productId === product.id &&
            x.pieceName === pieceName &&
            braidPositionIds.includes(x.position),
        ),
        colors: braidColors,
        thickness: BRAID_THICKNESS,
      }
    : undefined

  const design = DESIGN_DICT[recipe[`${prefix}.design.design`]]

  const designColors = Object.fromEntries(
    (
      [
        [design.props.color1, recipe[`${prefix}.design.color1`]],
        [design.props.color2, recipe[`${prefix}.design.color2`]],
        [design.props.color3, recipe[`${prefix}.design.color3`]],
      ] as const
    ).flatMap(([keys, color]) =>
      keys ?
        keys.map(
          (x) =>
            [
              `#${x}`,
              color ? COLOR_DICT[color].props.hex : 'transparent',
            ] as const,
        )
      : [],
    ),
  )

  const isYouth = sizeName.startsWith('Y')

  const designParts = filterDesignParts(
    DESIGN_PARTS.filter((x) => x.youth === isYouth),
    design.id,
    pieceName,
    product.id,
  ) as DesignPart[] // As renderPiece wants it to be mutable

  const exprEvalValues = {
    ageGroup: isYouth ? 'youth' : 'adult',
    isAdult: !isYouth,
    patternName,
    pieceName,
    playerName: player.name,
    playerNumber: player.number,
    playerYear: player.extras?.year,
    salesId,
    size: sizeName,
    sizeIndex: getSizeIndex(sizeName),
  }

  const fillPrefix = `${prefix}.${getFillType(pieceMappingItem.name)}`
  const fill = getFill(
    recipe[`${fillPrefix}.fill`],
    recipe[`${fillPrefix}.color1`],
    recipe[`${fillPrefix}.color2`],
    recipe[`${fillPrefix}.color3`],
    recipe[`${fillPrefix}.color4`],
  )

  const fullCustomImage = getFullCustomImage(
    product.id,
    pieceName,
    isYouth,
    design,
    recipe[`${prefix}.design.file`],
  )

  const imageElements = [
    ...(fullCustomImage ? [fullCustomImage] : []),
    ...placements.filter(byType('image')).map((x) => x.element),
    ...extraImageElements,
  ]

  const textElements = placements.filter(byType('text')).map((x) => x.element)

  const sizeWithMetrics = await sizePromise

  const markings = getMarkings(product.id, pieceName, sizeWithMetrics)

  return renderPiece(sizeWithMetrics, loadSvg, loadFont, {
    braids,
    factory:
      isFactoryMode ?
        {
          annotation: ANNOTATION,
          markingOuterColor: '#f2f3fa',
          markingInnerColor: '#000000',
          notchOuterColor: '#f2f3fa',
          notchInnerColor: '#000000',
          cutLineOuterColor: '#000000',
          cutLineInnerColor: '#dbdcd9',
        }
      : undefined,
    designColors,
    designParts,
    exprEvalValues,
    fill,
    imageElements,
    textElements,
    markings,
    debug,
  })
}
